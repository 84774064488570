import Modal from "react-modal";
import { createTheme, responsiveFontSizes } from "@mui/material";

interface Input {
    minHeight?: string | number;
    maxHeight?: string | number;
    width?: string | number;
    maxWidth?: string | number;
    top?: string | number;
}

/**
 * Custom styles for react-modal
 */
export const modalStyles = ({ minHeight, maxHeight = "80vh", top = 100, maxWidth = 600, width = "75%" }: Input = {}): Modal.Styles => ({
    content: {
        borderRadius: 3,
        padding: undefined,
        boxShadow: "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 5px 10px, rgba(15, 15, 15, 0.2) 0px 15px 40px",
        top,
        bottom: "auto",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth,
        maxHeight,
        minHeight,
        width,
    },
    overlay: { background: "rgba(15, 15, 15, 0.6)", zIndex: 100 },
});

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        grey: true;
    }
}

declare module "@mui/material/styles" {
    interface Theme {
        moduleColors: {
            blue: string;
            pink: string;
            green: string;
            yellow: string;
            grey: string;
        };
        colorPalette: {
            blue: PaletteColor;
            yellow: PaletteColor;
            green: PaletteColor;
            pink: PaletteColor;
            white: PaletteColor;
            black: PaletteColor;
            background: PaletteColor;
            grey: PaletteColor;
            grey2: PaletteColor;
        };
    }

    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        moduleColors?: {
            blue: string;
            pink: string;
            green: string;
            yellow: string;
            grey: string;
        };
        colorPalette?: {
            blue: PaletteColor;
            yellow: PaletteColor;
            green: PaletteColor;
            pink: PaletteColor;
            white: PaletteColor;
            background: PaletteColor;
            black: PaletteColor;
            grey: PaletteColor;
            grey2: PaletteColor;
        };
    }

    interface PaletteColor {
        light: string;
        main: string;
        dark: string;
        contrastText: string;
    }
}

/**
 * BLUE THEME
 */
export const materialTheme = responsiveFontSizes(
    createTheme(
        // adaptV4Theme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                    xxl: 2500,
                },
            },
            palette: {
                primary: { main: "#173357", light: "#465c84", dark: "#000b2e" },
                secondary: { main: "#e03660" },
                error: { main: "#c0392b" },
            },

            colorPalette: {
                blue: {
                    main: "#173357",
                    light: "#465c84",
                    dark: "#000b2e",
                    contrastText: "#ffffff",
                },
                yellow: {
                    main: "#F57117",
                    light: "#ffa24b",
                    dark: "#bb4100",
                    contrastText: "#ffffff",
                },
                green: {
                    main: "#225E4D",
                    light: "#508c79",
                    dark: "#003425",
                    contrastText: "#ffffff",
                },
                pink: {
                    main: "#E03660",
                    light: "#ff6d8d",
                    dark: "#a80037",
                    contrastText: "#ffffff",
                },
                white: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#b8b8b8",
                    contrastText: "#000000",
                },
                black: {
                    main: "#000000",
                    light: "#818181",
                    dark: "#000000",
                    contrastText: "#ffffff",
                },
                background: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#cccccc",
                    contrastText: "#000000",
                },
                grey: {
                    main: "#707070",
                    light: "#9e9e9e",
                    dark: "#454545",
                    contrastText: "#000000",
                },
                grey2: {
                    main: "#b3b3b3",
                    light: "#e0e0e0",
                    dark: "#808080",
                    contrastText: "#000000",
                },
            },

            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },

            components: {
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiRadio: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    variants: [
                        {
                            props: { variant: "contained", color: "grey" },
                            style: {
                                color: "#000",
                                backgroundColor: "#e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "outlined", color: "grey" },
                            style: {
                                color: "#000",
                                // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                "&.Mui-disabled": {
                                    border: `1px solid "#e0e0e0"`,
                                },
                                "&:hover": {
                                    // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "text", color: "grey" },
                            style: {
                                color: "#000",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                    ],
                },
            },
            moduleColors: {
                blue: "#173357",
                pink: "#E03660",
                green: "#225E4D",
                yellow: "#F57117",
                grey: "#707070",
            },
        }
    )
    // )
);

/**
 * GREY THEME
 */
export const greyTheme = responsiveFontSizes(
    createTheme(
        // adaptV4Theme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                    xxl: 2500,
                },
            },
            palette: {
                primary: { main: "#707070", light: "#e0e0e0", dark: "#454545" },
                secondary: { main: "#454545" },
                error: { main: "#c0392b" },
            },

            colorPalette: {
                blue: {
                    main: "#173357",
                    light: "#465c84",
                    dark: "#000b2e",
                    contrastText: "#ffffff",
                },
                yellow: {
                    main: "#F57117",
                    light: "#ffa24b",
                    dark: "#bb4100",
                    contrastText: "#ffffff",
                },
                green: {
                    main: "#225E4D",
                    light: "#508c79",
                    dark: "#003425",
                    contrastText: "#ffffff",
                },
                pink: {
                    main: "#E03660",
                    light: "#ff6d8d",
                    dark: "#a80037",
                    contrastText: "#ffffff",
                },
                white: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#b8b8b8",
                    contrastText: "#000000",
                },
                black: {
                    main: "#000000",
                    light: "#818181",
                    dark: "#000000",
                    contrastText: "#ffffff",
                },
                background: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#cccccc",
                    contrastText: "#000000",
                },
                grey: {
                    main: "#707070",
                    light: "#9e9e9e",
                    dark: "#454545",
                    contrastText: "#000000",
                },
                grey2: {
                    main: "#b3b3b3",
                    light: "#e0e0e0",
                    dark: "#808080",
                    contrastText: "#000000",
                },
            },

            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },

            components: {
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiRadio: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    variants: [
                        {
                            props: { variant: "contained", color: "grey" },
                            style: {
                                color: "#000",
                                backgroundColor: "#e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "outlined", color: "grey" },
                            style: {
                                color: "#000",
                                // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                "&.Mui-disabled": {
                                    border: `1px solid "#e0e0e0"`,
                                },
                                "&:hover": {
                                    // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "text", color: "grey" },
                            style: {
                                color: "#000",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                    ],
                },
            },
            moduleColors: {
                blue: "#173357",
                pink: "#E03660",
                green: "#225E4D",
                yellow: "#F57117",
                grey: "#707070",
            },
        }
    )
    // )
);

/**
 * GREEN THEME
 */
export const greenTheme = responsiveFontSizes(
    createTheme(
        // adaptV4Theme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                    xxl: 2500,
                },
            },
            palette: {
                primary: { main: "#225E4D", light: "#508c79", dark: "#003425" },
                secondary: { main: "#003425" },
                error: { main: "#c0392b" },
            },

            colorPalette: {
                blue: {
                    main: "#173357",
                    light: "#465c84",
                    dark: "#000b2e",
                    contrastText: "#ffffff",
                },
                yellow: {
                    main: "#F57117",
                    light: "#ffa24b",
                    dark: "#bb4100",
                    contrastText: "#ffffff",
                },
                green: {
                    main: "#225E4D",
                    light: "#508c79",
                    dark: "#003425",
                    contrastText: "#ffffff",
                },
                pink: {
                    main: "#E03660",
                    light: "#ff6d8d",
                    dark: "#a80037",
                    contrastText: "#ffffff",
                },
                white: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#b8b8b8",
                    contrastText: "#000000",
                },
                black: {
                    main: "#000000",
                    light: "#818181",
                    dark: "#000000",
                    contrastText: "#ffffff",
                },
                background: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#cccccc",
                    contrastText: "#000000",
                },
                grey: {
                    main: "#707070",
                    light: "#9e9e9e",
                    dark: "#454545",
                    contrastText: "#000000",
                },
                grey2: {
                    main: "#b3b3b3",
                    light: "#e0e0e0",
                    dark: "#808080",
                    contrastText: "#000000",
                },
            },

            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },

            components: {
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiRadio: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    variants: [
                        {
                            props: { variant: "contained", color: "grey" },
                            style: {
                                color: "#000",
                                backgroundColor: "#e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "outlined", color: "grey" },
                            style: {
                                color: "#000",
                                // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                "&.Mui-disabled": {
                                    border: `1px solid "#e0e0e0"`,
                                },
                                "&:hover": {
                                    // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "text", color: "grey" },
                            style: {
                                color: "#000",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                    ],
                },
            },
            moduleColors: {
                blue: "#173357",
                pink: "#E03660",
                green: "#225E4D",
                yellow: "#F57117",
                grey: "#707070",
            },
        }
    )
    // )
);

/**
 * YELLOW THEME
 */
export const yellowTheme = responsiveFontSizes(
    createTheme(
        // adaptV4Theme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                    xxl: 2500,
                },
            },
            palette: {
                primary: { main: "#F57117", light: "#ffa24b", dark: "#bb4100" },
                secondary: { main: "#454545" },
                error: { main: "#c0392b" },
            },

            colorPalette: {
                blue: {
                    main: "#173357",
                    light: "#465c84",
                    dark: "#000b2e",
                    contrastText: "#ffffff",
                },
                yellow: {
                    main: "#F57117",
                    light: "#ffa24b",
                    dark: "#bb4100",
                    contrastText: "#ffffff",
                },
                green: {
                    main: "#225E4D",
                    light: "#508c79",
                    dark: "#003425",
                    contrastText: "#ffffff",
                },
                pink: {
                    main: "#E03660",
                    light: "#ff6d8d",
                    dark: "#a80037",
                    contrastText: "#ffffff",
                },
                white: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#b8b8b8",
                    contrastText: "#000000",
                },
                black: {
                    main: "#000000",
                    light: "#818181",
                    dark: "#000000",
                    contrastText: "#ffffff",
                },
                background: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#cccccc",
                    contrastText: "#000000",
                },
                grey: {
                    main: "#707070",
                    light: "#9e9e9e",
                    dark: "#454545",
                    contrastText: "#000000",
                },
                grey2: {
                    main: "#b3b3b3",
                    light: "#e0e0e0",
                    dark: "#808080",
                    contrastText: "#000000",
                },
            },

            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },

            components: {
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiRadio: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    variants: [
                        {
                            props: { variant: "contained", color: "grey" },
                            style: {
                                color: "#000",
                                backgroundColor: "#e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "outlined", color: "grey" },
                            style: {
                                color: "#000",
                                // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                "&.Mui-disabled": {
                                    border: `1px solid "#e0e0e0"`,
                                },
                                "&:hover": {
                                    // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "text", color: "grey" },
                            style: {
                                color: "#000",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                    ],
                },
            },
            moduleColors: {
                blue: "#173357",
                pink: "#E03660",
                green: "#225E4D",
                yellow: "#F57117",
                grey: "#707070",
            },
        }
    )
    // )
);

/**
 * PINK THEME
 */
export const pinkTheme = responsiveFontSizes(
    createTheme(
        // adaptV4Theme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                    xxl: 2500,
                },
            },
            palette: {
                primary: { main: "#E03660", light: "#ff6d8d", dark: "#a80037" },
                secondary: { main: "#454545" },
                error: { main: "#c0392b" },
            },

            colorPalette: {
                blue: {
                    main: "#173357",
                    light: "#465c84",
                    dark: "#000b2e",
                    contrastText: "#ffffff",
                },
                yellow: {
                    main: "#F57117",
                    light: "#ffa24b",
                    dark: "#bb4100",
                    contrastText: "#ffffff",
                },
                green: {
                    main: "#225E4D",
                    light: "#508c79",
                    dark: "#003425",
                    contrastText: "#ffffff",
                },
                pink: {
                    main: "#E03660",
                    light: "#ff6d8d",
                    dark: "#a80037",
                    contrastText: "#ffffff",
                },
                white: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#b8b8b8",
                    contrastText: "#000000",
                },
                black: {
                    main: "#000000",
                    light: "#818181",
                    dark: "#000000",
                    contrastText: "#ffffff",
                },
                background: {
                    main: "#ffffff",
                    light: "#f4f4f4",
                    dark: "#cccccc",
                    contrastText: "#000000",
                },
                grey: {
                    main: "#707070",
                    light: "#9e9e9e",
                    dark: "#454545",
                    contrastText: "#000000",
                },
                grey2: {
                    main: "#b3b3b3",
                    light: "#e0e0e0",
                    dark: "#808080",
                    contrastText: "#000000",
                },
            },

            typography: {
                fontSize: 14,
                fontFamily: "Maven Pro, system-ui, sans-serif, Material Icons",
                h1: {
                    fontSize: "1.8rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.4rem",
                    },
                },
                h2: {
                    fontSize: "1.6rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.2rem",
                    },
                },
                h3: {
                    fontSize: "1.4rem",
                    "@media (max-width: 800px)": {
                        fontSize: "1.0rem",
                    },
                },
                h4: {
                    fontSize: "0.8rem",
                    spacing: "0.06em",
                    "@media (max-width: 800px)": {
                        fontSize: "0.7rem",
                    },
                },
                h5: {
                    fontSize: "1.2rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.9rem",
                    },
                },
                h6: {
                    fontSize: "0.95rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
                body1: {
                    fontSize: "0.875rem",
                    "@media (max-width: 800px)": {
                        fontSize: "0.8rem",
                    },
                },
            },

            components: {
                MuiCheckbox: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiRadio: {
                    defaultProps: {
                        color: "primary",
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    variants: [
                        {
                            props: { variant: "contained", color: "grey" },
                            style: {
                                color: "#000",
                                backgroundColor: "#e0e0e0",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "outlined", color: "grey" },
                            style: {
                                color: "#000",
                                // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                "&.Mui-disabled": {
                                    border: `1px solid "#e0e0e0"`,
                                },
                                "&:hover": {
                                    // borderColor: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                        {
                            props: { variant: "text", color: "grey" },
                            style: {
                                color: "#000",
                                "&:hover": {
                                    backgroundColor: "#d5d5d5",
                                },
                            },
                        },
                    ],
                },
            },
            moduleColors: {
                blue: "#173357",
                pink: "#E03660",
                green: "#225E4D",
                yellow: "#F57117",
                grey: "#707070",
            },
        }
    )
    // )
);
