import React from "react";
import { Theme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { taskExamples } from "./HardCodedTasks";

interface Props {
    myTasksLength: number;
    setShowExamples: React.Dispatch<React.SetStateAction<boolean>>;
    showExamples: boolean;
    loading?: boolean;
}

// Styled Paper component
const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme: Theme) => ({
    root: {
        margin: "6px 0px",
        border: "none",
        width: "436px",
        display: "flex",
        // justifyContent: "space-between",
        "@media (max-width: 800px)": {
            width: "100%",
        },
    },
    grouped: {
        border: "none",
        fontSize: "12px",
        padding: "4px 6px",
        "@media (max-width: 800px)": {
            fontSize: "10px",
        },
    },
}));

const StyledToggleButton = withStyles(ToggleButton, (theme: Theme) => ({
    /* Styles applied to the root element. */
    root: {
        border: "none !important",
        background: "none !important",
        "&:hover": {
            textDecoration: "underline",
            textDecorationThickness: 2,
            textUnderlineOffset: "3px",
            // Reset on mouse devices
            backgroundColor: "none !important",
            "@media (hover: none)": {
                backgroundColor: "none !important",
            },
            "&$disabled": {
                backgroundColor: "none !important",
            },
        },
    },

    /* Pseudo-class applied to the root element if `selected={true}`. */
    selected: {
        color: "#173357",
        background: "none !important",
        textDecoration: "underline ",
        textDecorationThickness: 2,
        textUnderlineOffset: "3px",
        "&:hover": {
            background: "none !important",
            textDecorationThickness: 2,
        },
    },
}));

const TaskSwitch: React.FC<Props> = ({ myTasksLength, setShowExamples, showExamples, loading = false }) => {
    if (loading) {
        return (
            <StyledToggleButtonGroup exclusive>
                <StyledToggleButton selected={false} disabled value={false}>
                    Mijn taken ({myTasksLength})
                </StyledToggleButton>
                <div style={{ width: "4px" }} />
                <StyledToggleButton selected={false} disabled value={true}>
                    Voorbeelden ({taskExamples.length})
                </StyledToggleButton>
            </StyledToggleButtonGroup>
        );
    }

    return (
        <StyledToggleButtonGroup exclusive>
            <StyledToggleButton
                onClick={() => {
                    setShowExamples(false);
                }}
                selected={!showExamples}
                disabled={myTasksLength === 0}
                value={false}
            >
                Mijn taken ({myTasksLength})
            </StyledToggleButton>
            <div style={{ width: "4px" }} />
            <StyledToggleButton
                onClick={() => {
                    setShowExamples(true);
                }}
                selected={showExamples}
                disabled={loading}
                value={true}
            >
                Voorbeelden ({taskExamples.length})
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default TaskSwitch;
