import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { DoubleArrow } from "@mui/icons-material";
import { addTaskToTemplate, addTaskToTemplateVariables } from "../../../../__generated__/addTaskToTemplate";
import { ADD_TASK_TO_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Priority } from "../../../../__generated__/globalTypes";

interface Props {
    template_id: string;
    template_name: string;
    task_id: string;
    refetchTemplate: () => void;
}

const AddTaskToTemplate: React.FC<Props> = ({ template_id, template_name, task_id, refetchTemplate }) => {
    // Explain mutation
    const [mutate, { loading, error }] = useMutation<addTaskToTemplate, addTaskToTemplateVariables>(ADD_TASK_TO_TEMPLATE);

    // Explain mutation handler
    const addToTemplate = async () => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await mutate({
                variables: { templateId: template_id, taskId: task_id, task_priority: Priority.MEDIUM },
                onCompleted: () => {
                    refetchTemplate();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };
    return (
        <Tooltip placement="top" title={`Toevoegen aan ${template_name}`}>
            <IconButton disabled={loading} onClick={addToTemplate} size="small">
                <DoubleArrow fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default AddTaskToTemplate;
