import React from "react";
import SearchBar from "./filters/SearchBar";
import Priority from "./filters/Priority";
import Deadline from "./filters/Deadline";
// import ProjectState from "./filters/ProjectState";
import { getTasksWithFilters_filterUserTasks_filters, getTasksWithFiltersVariables } from "../../../__generated__/getTasksWithFilters";
import TaskComplete from "./filters/TaskComplete";
import Person from "./filters/Person";
import ProjectID from "./filters/ProjectID";

interface Props {
    filterData: getTasksWithFilters_filterUserTasks_filters | null | undefined;
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
}

const TaskFilterBar: React.FC<Props> = ({ filterData, activeFilters, setActiveFilters }) => {
    return (
        <div style={{ height: "125px" }}>
            {/*  SEARCHBAR */}
            <SearchBar activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            <div style={{ height: "20px", width: "100%" }} />
            {/* User filter*/}
            <Person filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            {/* Task completion */}
            <TaskComplete filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            {/* ProjectID filter*/}
            <ProjectID filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            {/* Deadline filter */}
            <Deadline filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            {/* Priority filter */}
            <Priority filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
            {/* Project state filter */}
            {/* <ProjectState setFilterCriteria={setFilterCriteria} filterData={filterData} /> */}
        </div>
    );
};

export default TaskFilterBar;
