import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { DELETE_TASK_IN_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import { deleteTaskInTemplate, deleteTaskInTemplateVariables } from "../../../../__generated__/deleteTaskInTemplate";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

interface Props {
    id: string;
    refetch: () => void;
}

const DeleteTaskInTemplate: React.FC<Props> = ({ id, refetch }) => {
    // Explain mutation
    const [mutate, { loading, error }] = useMutation<deleteTaskInTemplate, deleteTaskInTemplateVariables>(DELETE_TASK_IN_TEMPLATE);

    // Explain mutation handler
    const deletefromtemplate = async () => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await mutate({
                variables: { id: id },
                onCompleted: () => {
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };
    return (
        <Tooltip disableInteractive title="Taak verwijderen uit template">
            <IconButton disabled={loading} size="small" onClick={() => deletefromtemplate()}>
                <Clear fontSize="small" sx={{ color: "#c0392b" }} />
            </IconButton>
        </Tooltip>
    );
};

export default DeleteTaskInTemplate;
