import React from "react";
import { IconButton, List, ListItem, Paper, Tooltip } from "@mui/material";
import { taskLibrary_library } from "../../../__generated__/taskLibrary";
import { AddBox, Check } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { createTaskInLibrary, createTaskInLibraryVariables } from "../../../__generated__/createTaskInLibrary";
import { CREATE_NEW_TASK_IN_LIBRARY } from "../../../graphql/mutationDefTasks";
import { toast } from "react-toastify";

interface Props {
    existingTasks: taskLibrary_library[];
    refetch: () => void;
}

export const taskExamples = [
    "Kick-off meeting inplannen",
    "Opstellen interne projectplanning",
    "Beoordelen geschiktheidseisen",
    "Bezoeken voorlichtingsbijeenkomst",
    "Opstellen vragen NvI",
    "Verzamelen bewijsstukken",
    "Juridische check overeenkomst",
    "Analyseren NvI",
    "Opstellen calculatie/prijsaanbieding",
    "Schrijven conceptversie",
    "Review conceptversie",
    "Feedback review verwerken",
    "Opmaken inschrijving",
    "Indienen inschrijving",
    "Voorbereiden presentatie",
    "Voorbereiden verificiatie",
];

const HardCodedTasks: React.FC<Props> = ({ existingTasks, refetch }) => {
    const existingDescriptions = existingTasks.map((task) => task.task_description);

    // Explain mutation
    const [addTaskToLibrary, { loading: loadCreate, error: errorCreate }] = useMutation<createTaskInLibrary, createTaskInLibraryVariables>(
        CREATE_NEW_TASK_IN_LIBRARY
    );

    // Explain mutation handler
    const addNewTask = async (q: string) => {
        if (errorCreate) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await addTaskToLibrary({
                variables: {
                    description: q,
                },
                onCompleted: (data) => {
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <List dense sx={{ padding: 0 }}>
            {taskExamples.map((t, i) => {
                const alreadyExists = existingDescriptions.includes(t);
                return (
                    <Paper key={i} variant="outlined" sx={{ marginBottom: "4px", padding: "4px 8px" }}>
                        <ListItem
                            sx={{
                                padding: 0,
                                display: "flex",
                                justifyContent: "space-between",
                                height: "30px",
                                fontStyle: alreadyExists ? "normal" : "italic",
                                ".MuiListItemSecondaryAction-root": {
                                    right: 0,
                                },
                            }}
                            secondaryAction={
                                <>
                                    {alreadyExists ? (
                                        <IconButton size="small" edge="end" disabled>
                                            <Check fontSize="small" sx={{ color: "#225E4D" }} />
                                        </IconButton>
                                    ) : (
                                        <Tooltip title="Toevoegen aan Mijn taken" disableInteractive placement="top">
                                            <IconButton
                                                disabled={loadCreate}
                                                onClick={() => addNewTask(t)}
                                                size="small"
                                                edge="end"
                                                aria-label="delete"
                                            >
                                                <AddBox fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            }
                        >
                            {t}
                        </ListItem>
                    </Paper>
                );
            })}
        </List>
    );
};

export default HardCodedTasks;
