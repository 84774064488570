import { Checkbox, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { updateTaskCompletion, updateTaskCompletionVariables } from "../../../../__generated__/updateTaskCompletion";
import { UPDATE_TASK_COMPLETION, UPDATE_TASK_DESCRIPTION } from "../../../../graphql/mutationDefProjects";
import { updateTaskDescription, updateTaskDescriptionVariables } from "../../../../__generated__/updateTaskDescription";
import EditableTaskField from "../../projectModal/EditableTaskField";
import MatomoEvent from "../../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Clear, RadioButtonUnchecked, TaskAlt } from "@mui/icons-material";
import { getTasksWithFilters_filterUserTasks_data } from "../../../../__generated__/getTasksWithFilters";
import { GET_TASKS_WITH_FILTERS } from "../../../../graphql/queryDefTasks";
import moment from "moment";

interface Props {
    taskOfCu: boolean;
    task: getTasksWithFilters_filterUserTasks_data;
    onTaskChange: <K extends keyof getTasksWithFilters_filterUserTasks_data>(
        updatedTask: getTasksWithFilters_filterUserTasks_data,
        key: K,
        value: getTasksWithFilters_filterUserTasks_data[K]
    ) => void;
}

const TaskDescription: React.FC<Props> = ({ taskOfCu, task, onTaskChange }) => {
    const { trackEvent } = useMatomo();
    const [clickedBox] = useState<string>("");
    const [hoverBox, setHoverBox] = useState<string>("");

    const userId = localStorage.getItem("user_id");
    const isUserInTask = task.users?.some((u) => u.id === userId) ?? false;

    const [saveTaskCompletion] = useMutation<updateTaskCompletion, updateTaskCompletionVariables>(UPDATE_TASK_COMPLETION);
    // Change description of task
    const [saveTaskDescription] = useMutation<updateTaskDescription, updateTaskDescriptionVariables>(UPDATE_TASK_DESCRIPTION);

    // UPDATE LOCAL STATE
    const toggleTaskCompletion = () => {
        const updatedTask = { ...task, task_completed: !task.task_completed };
        onTaskChange(updatedTask, "task_completed", updatedTask.task_completed);
    };

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };
    /**
     * Update complete state of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onChangeBoolean = async (taskId: string, boolean: boolean) => {
        track({ category: "Project", action: "Click-mark-task-as-complete-via-taskview", name: "mark-as-completed" });

        try {
            await saveTaskCompletion({
                variables: {
                    id: taskId,
                    completed: boolean,
                },
                refetchQueries: [GET_TASKS_WITH_FILTERS],
                onCompleted: () => {
                    toggleTaskCompletion();
                },
            });
        } catch (e) {}
    };

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangesaveTaskDescription = async (taskId: string, description: string) => {
        try {
            await saveTaskDescription({
                variables: {
                    id: taskId,
                    description: description,
                },
                refetchQueries: [GET_TASKS_WITH_FILTERS],
                onCompleted: () => {
                    // refetch();
                    track({ category: "Project", action: "Change-task-description-via-taskview", name: "description" });
                    const updatedTask = { ...task, task_description: description };
                    onTaskChange(updatedTask, "task_description", description);
                },
            });
        } catch (e) {}
    };

    return (
        <>
            <Tooltip
                disableInteractive
                title={
                    task.deleted_at
                        ? `Verwijderd op ${moment(task.deleted_at).format("L")}`
                        : task.task_completed
                        ? `Voltooid op ${moment(task.task_completed_at).format("L")}`
                        : "Taak voltooien"
                }
                placement="right"
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -16],
                                },
                            },
                        ],
                    },
                }}
            >
                <Checkbox
                    onMouseOver={() => setHoverBox(task.id)}
                    onMouseOut={() => setHoverBox("")}
                    icon={
                        task.deleted_at ? (
                            <Clear fontSize="small" sx={{ color: "#c0392b" }} />
                        ) : clickedBox === task.id ? (
                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF" }} />
                        ) : hoverBox === task.id ? (
                            <TaskAlt fontSize="small" sx={{ color: "#2E76FF4d" }} />
                        ) : (
                            <RadioButtonUnchecked fontSize="small" />
                        )
                    }
                    checkedIcon={task.deleted_at ? <Clear fontSize="small" sx={{ color: "#c0392b" }} /> : <TaskAlt sx={{ color: "#2E76FF" }} />}
                    edge="start"
                    size="small"
                    checked={task.task_completed}
                    disabled={isUserInTask ? false : true}
                    disableRipple
                    sx={{ marginLeft: "4px" }}
                    // setState to checked value
                    onChange={(e) => {
                        if (task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id"))) {
                            onChangeBoolean(task.id, e.target.checked);
                        }
                    }}
                />
            </Tooltip>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: task.deleted_at ? "line-through" : task.task_completed ? "line-through" : "none",
                    width: "100%",
                    marginLeft: "4px",
                }}
            >
                <EditableTaskField
                    width={"100%"}
                    disabled={task.deleted_at || task.task_completed}
                    tasktext={task.task_description}
                    setTasktext={(txt) => {
                        if (taskOfCu) {
                            onChangesaveTaskDescription(task.id, txt);
                        }
                    }}
                    taskID={task.id}
                    bossOfProject={taskOfCu}
                />
            </div>
        </>
    );
};

export default TaskDescription;
