import React from "react";
import WidgetBox from "../../components/WidgetBox";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import TabMenuOrganizationModule from "./OrganizationDetailTabMenu";

import { useQuery } from "@apollo/client";
import FullPageSpinner from "../../components/loading/FullPageSpinner";
import setTitle, { setTitleLoading } from "../../utils/setTitle";
import { GetOrganizationNameOnly, GetOrganizationNameOnlyVariables } from "../../__generated__/GetOrganizationNameOnly";
import { DynamicTab } from "../../components/ModuleTabs";
import TenderTabMenuModule from "./detailPage/tenderWidget/TenderTabMenuModule";
import RenderPage from "../../components/loading/RenderPage";
import OrgTopBarModule from "./detailPage/OrgTopBarModule";
import { useParams } from "react-router-dom";
import Div404 from "../Div404";
import { QUERY_ORGANIZATIONNAME } from "../../graphql/queryDefOrg";
import { QUERY_CURRENT_USER_SECTORS } from "../../graphql/queryDefCurrentUser";
import { getSectorOfCurrentUser } from "../../__generated__/getSectorOfCurrentUser";

interface Props {
    registerTab: (tab: DynamicTab) => void;
}

const OrganizationDetailPage: React.FC<Props> = ({ registerTab }) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const org_id = id as string;
    const { loading, error, data } = useQuery<GetOrganizationNameOnly, GetOrganizationNameOnlyVariables>(QUERY_ORGANIZATIONNAME, {
        variables: { id: org_id },
        fetchPolicy: "network-only",
    });

    const { loading: loadAmount, data: sectordata } = useQuery<getSectorOfCurrentUser>(QUERY_CURRENT_USER_SECTORS, {
        fetchPolicy: "network-only",
    });

    if (loading || loadAmount) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    // Errormessage. TODO: fancy error page
    if (error || !data?.tenderer) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <Div404 url={`/organizations/${org_id}`} />
            </RenderPage>
        );
    }

    // Extract sector IDs from the user's subscriptions
    const sectorIds =
        sectordata?.currentUser.employee.organization.subscriptions.flatMap((s: any) => s.sectors?.map((se: any) => se?.id) || []) || [];

    // Get id and name and use as tabinfo
    const tabValue = `/organizations/${id}`;
    const name = data.tenderer.name;
    setTitle(name || "Marktpartij");
    registerTab({ value: tabValue, label: name || "" });
    // Count settlings without rechtspersoon type
    const settlingCount = data.tenderer.settlings?.data.filter((s) => s.type !== "rechtspersoon").length;
    return (
        <div className="responsive-container">
            <OrgTopBarModule status={null} id={data.tenderer.id} name={name} showStar={data.tenderer.bids.length > 0} />
            <Typography variant="h2" style={{ paddingTop: 12 }}>
                {data.tenderer.name}
            </Typography>

            {/* organization widget with tabs, get data per individual tab instead of all in one */}
            <WidgetBox header="" highlight="pink" padding={0}>
                <div>
                    <TabMenuOrganizationModule id={org_id} settlingCount={settlingCount ? settlingCount : 0} />
                </div>
            </WidgetBox>

            {/* Tenderwidget with tabs, get data per individual tab instead of all in one */}

            {data.tenderer.bids.length > 0 && (
                <React.Fragment>
                    <Typography variant="h3">{sectorIds.length === 1 ? t("tendersInSector") : t("tendersInSectors")}</Typography>
                    <div>
                        <TenderTabMenuModule org_id={org_id} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default OrganizationDetailPage;
