import React from "react";
import { Theme } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { useProfile } from "../../../components/contextProviders/ProfileOpenerContext";

interface Props {
    myWordsLength: number;
    orgWordsLength: number;
    setShowMyWords: React.Dispatch<React.SetStateAction<boolean>>;
    showMyWords: boolean;
    loading?: boolean;
}

// Styled Paper component
const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme: Theme) => ({
    root: {
        padding: "0px 16px",
        margin: "6px 0px",
        border: "none",
        width: "436px",
        display: "flex",
        // justifyContent: "space-between",
        "@media (max-width: 800px)": {
            width: "100%",
        },
    },
    grouped: {
        border: "none",
        fontSize: "12px",
        padding: "4px 6px",
        "@media (max-width: 800px)": {
            fontSize: "10px",
        },
    },
}));

const StyledToggleButton = withStyles(ToggleButton, (theme: Theme) => ({
    /* Styles applied to the root element. */
    root: {
        border: "none !important",
        background: "none !important",
        "&:hover": {
            textDecoration: "underline",
            textDecorationThickness: 2,
            textUnderlineOffset: "3px",
            // Reset on mouse devices
            backgroundColor: "none !important",
            "@media (hover: none)": {
                backgroundColor: "none !important",
            },
            "&$disabled": {
                backgroundColor: "none !important",
            },
        },
    },

    /* Pseudo-class applied to the root element if `selected={true}`. */
    selected: {
        color: "#173357",
        background: "none !important",
        textDecoration: "underline ",
        textDecorationThickness: 2,
        textUnderlineOffset: "3px",
        "&:hover": {
            background: "none !important",
            textDecorationThickness: 2,
        },
    },
}));

const WordSwitch: React.FC<Props> = ({ myWordsLength, orgWordsLength, loading, showMyWords, setShowMyWords }) => {
    const { org_name } = useProfile();

    if (loading) {
        <div style={{ height: "29px" }} />;
    }

    return (
        <StyledToggleButtonGroup exclusive>
            <StyledToggleButton
                onClick={() => {
                    setShowMyWords(true);
                }}
                selected={showMyWords}
                disabled={myWordsLength === 0}
                value={false}
            >
                Mijn zoekwoorden ({myWordsLength})
            </StyledToggleButton>
            <div style={{ width: "4px" }} />
            <StyledToggleButton
                onClick={() => {
                    setShowMyWords(false);
                }}
                selected={!showMyWords}
                disabled={loading || orgWordsLength === 0}
                value={true}
            >
                {org_name} ({orgWordsLength})
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    );
};

export default WordSwitch;
