import React, { useRef, useState } from "react";
import { Typography, Box, Link, Breadcrumbs, Skeleton, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// GQL
import { useQuery } from "@apollo/client";
import { SingleArticle, SingleArticleVariables } from "../../__generated__/SingleArticle";
import { useTranslation } from "react-i18next";
import dompurify from "dompurify";
import { useFaq } from "../contextProviders/FaqOpenerContext";
import { QUERY_GETSINGLEARTICLE } from "../../graphql/queryDefFaq";
import VcardColumn from "../VcardColumn";
import { WEBINAR_LINK } from "../../graphql/queryDefUserAndTeams";
import { webinar } from "../../__generated__/webinar";
import { Forward10, Pause, PlayArrow, Replay10, VolumeOff, VolumeUp } from "@mui/icons-material";
import ColumnContent from "../../routes/columns/ColumnContent";

interface Props {
    article_id: string;
}

const KnowledgeBaseDetailPage: React.FC<Props> = ({ article_id }) => {
    const { setView } = useFaq();
    const { data, error, loading } = useQuery<SingleArticle, SingleArticleVariables>(QUERY_GETSINGLEARTICLE, { variables: { id: article_id } });
    const { data: webinardata } = useQuery<webinar>(WEBINAR_LINK);
    // Reference to the video element
    const videoRef = useRef<HTMLVideoElement | null>(null);

    // State to track if the video is playing or paused
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    const { t } = useTranslation();

    // ####################################################################
    // ################################## VIDEO HANDLERS
    // ####################################################################

    // Function to mute/unmute the video
    const toggleMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !videoRef.current.muted;
            setIsMuted(videoRef.current.muted);
        }
    };

    // Function to jump forward by 10 seconds
    const skipForward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime += 10; // Skip forward by 10 seconds
        }
    };

    // Function to jump backward by 10 seconds
    const skipBackward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime -= 10; // Skip backward by 10 seconds
        }
    };

    // ####################################################################
    // ################################## END VIDEO HANDLERS
    // ####################################################################

    // Loader
    if (loading) {
        return (
            <React.Fragment>
                {/* Breadcrumb link chain */}
                <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 16, marginTop: 12 }}>
                    {/* Link with breadcrumbs to show user the category of the clicked item */}
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton animation="wave" height={12} width="50px" />
                    <Skeleton animation="wave" height={12} width="50px" />
                </Breadcrumbs>

                {/* box with information */}
                <Box p={2}>
                    <Skeleton animation="wave" height={24} width="200px" style={{ marginBottom: 16 }} />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="90%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="90%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                    <Skeleton animation="wave" height={18} width="100%" />
                    <Skeleton animation="wave" height={18} width="95%" />
                    <Skeleton animation="wave" height={18} width="89%" />
                </Box>
            </React.Fragment>
        );
    }
    if (error || !data || !data.article) return <p>error loading articles</p>;
    const article = data.article;

    /**
     * dompurify imported for sanitizing html
     */
    const frag = dompurify.sanitize(article.content, { FORCE_BODY: true });

    /**
     * Check if content has external urls to navigate to.
     * If its true => add target blank to url tag(s)
     */
    const CheckUrlsInContent = frag.includes(`<a href="https://tool.tender.guide/`) ? frag : frag.replaceAll(`<a`, `<a target="_blank"`);

    /**
     * Get sanitized content and replace the html tags (<icon_name>) with material icons
     */
    const showIcons = CheckUrlsInContent.replace(/&lt;/g, `<span class="material-icons">`).replace(/&gt;/g, `</span>`);

    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = showIcons;

    // Find the img tag within the parsed HTML
    const imgElement = tempElement.querySelector("img");
    // Find all <figcaption> elements
    const figcaptions = tempElement.querySelectorAll("figcaption");

    // If img tag is found, modify its width and height attributes
    if (imgElement) {
        imgElement.setAttribute("width", "100%");
        imgElement.setAttribute("height", "auto");
    }
    // Remove each <figcaption> element
    figcaptions.forEach((figcaption) => {
        figcaption.remove();
    });

    // Get the modified HTML string
    const modifiedHtmlString = tempElement.innerHTML;

    // Change <figure> to <div>
    const removeFigure = modifiedHtmlString.replace(/<figure/g, "<div").replace(/<\/figure>/g, "</div>");

    // Function to toggle play/pause
    const togglePlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <React.Fragment>
            {/* Breadcrumb link chain */}
            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 16, marginTop: 12 }}>
                {/* Link with breadcrumbs to show user the category of the clicked item */}
                <Link
                    underline="none"
                    color="inherit"
                    component="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        setView("start");
                    }}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        ":hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    <SearchIcon sx={{ width: "20px", height: "20px" }} />
                </Link>

                <Link
                    underline="none"
                    color="inherit"
                    component="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        setView(article.category === "column" ? "column" : article.module !== null ? article.module : "start");
                    }}
                >
                    {article.category === "column" ? "column" : t(`faq.${article.module}`)}
                </Link>

                {/* <Link color="inherit" component="button" onClick={() => setView(article.category !== null ? article.category : "start")}>
                    {t(`faq.${article.category}`)}
                </Link> */}
            </Breadcrumbs>

            {/* box with information */}
            <Box p={2}>
                <Typography sx={{ color: "#707070", marginBottom: "16px" }} variant="h5">
                    {article.title}
                </Typography>

                {article.category !== "column" && (
                    <Typography
                        // style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                            __html: removeFigure,
                        }}
                    />
                )}
                {article.category === "column" && <ColumnContent text={article.content} column_id={article_id} username={""} />}

                {article.category === "column" && article.author && (
                    <div style={{ marginTop: "32px" }}>
                        <VcardColumn
                            // logo
                            logo={article.author.organization.logo || ""}
                            // picture in avatar
                            img={article.author.picture || ""}
                            role={article.author.function || ""}
                            showmenu={true}
                            showLabel={false}
                            full_name={article.author.name || ""}
                            first_name={article.author.givenname || ""}
                            last_name={article.author.familyname || ""}
                            cardVariant={"elevation"}
                            cardElevation={1}
                            nameStyle="body1"
                            mail={article.author.email || ""}
                            social={article.author.linkedin || ""}
                            organization={article.author.organization.name || ""}
                            phone={article.author.phonenumber || ""}
                            // "0570-758011"
                        />
                    </div>
                )}

                {/*
                 * TEST div with iframe to create poc with video
                 */}
                {article_id === "136" && webinardata?.currentUser.webinar && (
                    <div style={{ width: "100%", maxWidth: "920px", margin: "0 auto" }}>
                        <div
                            style={{
                                position: "relative",
                                display: "block",
                                margin: "0 auto",
                                width: "100%",
                                maxWidth: "920px",
                                marginTop: "12px",
                            }}
                        >
                            <div>
                                <video ref={videoRef} width="100%" height="auto">
                                    <source src={webinardata?.currentUser.webinar} type="video/mp4" />
                                    {/* // Your browser does not support the video tag. //{" "} */}
                                </video>

                                {!isPlaying && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            zIndex: 2,
                                            cursor: "pointer",
                                            backgroundColor: "#00000050",
                                            marginBottom: "6px",
                                        }}
                                        onClick={togglePlay}
                                    >
                                        <IconButton
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "50%",
                                                width: "100px",
                                                height: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <PlayArrow style={{ fontSize: "64px", color: "#173357" }} />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Custom Controls */}
                        <div className="custom-controls" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            {/* Mute/Unmute Button */}
                            <IconButton onClick={toggleMute}>{isMuted ? <VolumeOff /> : <VolumeUp />}</IconButton>

                            <div>
                                <IconButton onClick={skipBackward}>
                                    <Replay10 />
                                </IconButton>

                                {/* Play/Pause Button */}
                                <IconButton onClick={togglePlay}>{isPlaying ? <Pause /> : <PlayArrow />}</IconButton>

                                {/* Forward/Backward Buttons */}
                                <IconButton onClick={skipForward}>
                                    <Forward10 />
                                </IconButton>
                            </div>
                            <div style={{ width: "40px" }} />
                        </div>
                    </div>
                )}
            </Box>
        </React.Fragment>
    );
};

export default KnowledgeBaseDetailPage;
