import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import {
    contracting_authority_favicon_to_show,
    contracting_authority_favicon_to_showVariables,
} from "../__generated__/contracting_authority_favicon_to_show";
import { Box, Skeleton } from "@mui/material";
import { QUERY_ALL_FAVICONS_OF_CAS } from "../graphql/queryDefCA";

interface Props {
    /**
     * Favicon of organization
     */
    favicon: string | null;
    /**
     * color of text in avatar
     */
    color?: string;
    /**
     * Name is fallback if favicon === null
     */
    name?: string | null;
    /**
     * if not set, borderRadius = 6
     */
    borderRadius?: number;
    /**
     * marginTop, if not set, marginTop = 3
     */
    marginTop?: number;
    id?: string;
    size?: string;
}

const FaviconBox: React.FC<Props> = ({ favicon, name, borderRadius, color, marginTop, id, size = "18px" }) => {
    const letters = name?.match(/\b(\w)/g)?.slice(0, 2);
    const [flag, setFlag] = useState<string | null>(null);

    const { loading: loadingQuery } = useQuery<contracting_authority_favicon_to_show, contracting_authority_favicon_to_showVariables>(
        QUERY_ALL_FAVICONS_OF_CAS,
        {
            skip: id === undefined ? true : false,
            variables: { id: id !== undefined ? id : "" },
            onCompleted: (data) => {
                setFlag(data?.contracting_authority?.favicon_resized ?? data?.contracting_authority?.favicon ?? null);
            },
        }
    );
    if (loadingQuery) {
        return (
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                    height: size,
                    minWidth: size,
                    maxWidth: size,
                    marginRight: "8px",
                    borderRadius: borderRadius === undefined ? "6px" : borderRadius,
                    background: "#e0e0e0",
                    display: "flex",
                    marginTop: marginTop !== undefined ? `${marginTop}px` : "3px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <React.Fragment>
            {/* Check if favicon !== null -> show favicon */}
            {favicon ? (
                <div
                    style={{
                        height: size,
                        minWidth: size,
                        maxWidth: size,
                        marginRight: 8,
                        backgroundImage: `url(https://storage.googleapis.com/organizations_favicons/${favicon})`,
                        backgroundSize: "cover",
                        borderRadius: borderRadius === undefined ? "6px" : borderRadius,
                    }}
                />
            ) : flag !== null ? (
                <div
                    style={{
                        height: size,
                        minWidth: size,
                        maxWidth: size,
                        marginRight: 8,
                        backgroundImage: `url(https://storage.googleapis.com/organizations_favicons/${flag})`,
                        backgroundSize: "cover",
                        borderRadius: borderRadius === undefined ? "6px" : borderRadius,
                    }}
                />
            ) : (
                // If favicon is null -> get 1 or 2 letters of organization name
                <Box
                    sx={{
                        height: size,
                        minWidth: size,
                        maxWidth: size,
                        marginRight: "8px",
                        borderRadius: borderRadius === undefined ? "6px" : borderRadius,
                        background: "#e0e0e0",
                        display: "flex",
                        marginTop: marginTop !== undefined ? `${marginTop}px` : "3px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            textTransform: "uppercase",
                            fontSize: "0.6rem",
                            color: color !== undefined ? color : "#000000",
                        }}
                    >
                        {letters}
                    </Typography>
                </Box>
            )}
        </React.Fragment>
    );
};

export default FaviconBox;
