import React, { useState } from "react";
import {
    Typography,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails,
    Grid,
    IconButton,
    Accordion as MuiAccordion,
    Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FoundResult from "../../../components/opportunityDetail/FoundResult";
import { GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities } from "../../../__generated__/GetopportunityPagePersonalOpportunities";
import { ReactComponent as IconSeen } from "../../../assets/icons/check-outlined.svg";
import { withStyles } from "tss-react/mui";

interface Props {
    page_id: string;
    page_number: number;
    onPageClick(): void;
    pdfUrl: string | null;
    data: GetopportunityPagePersonalOpportunities_opportunityPagePersonalOpportunities[] | undefined;
    active: number | boolean;
    read: boolean | null;
    year_from: number | null;
}

// accordion styling
const Accordion = withStyles(MuiAccordion, {
    root: {
        cursor: "default !important",
        border: "none",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
        "&:hover:not(.Mui-disabled)": {
            cursor: "default",
        },
    },
    expanded: {
        margin: "0 !important",
    },
});

// accordion summary change cursor to default
const AccordionSummary = withStyles(MuiAccordionSummary, {
    root: {
        cursor: "default !important",
        "&:hover:not(.Mui-disabled)": {
            cursor: "default",
        },
    },
});

const CollapsableOpportunity: React.FC<Props> = ({ page_id, page_number, onPageClick, pdfUrl, data, active, read, year_from }) => {
    const [expanded, setExpanded] = useState<number | false>(active === page_number ? active : false);

    // expand accordion based on lot ID
    const handleChange = (panel: number) => {
        if (panel === expanded) {
            setExpanded(false);
        } else {
            setExpanded(panel);
        }
    };

    return (
        <>
            <Accordion expanded={expanded === page_number}>
                <AccordionSummary
                    expandIcon={
                        <IconButton size="small" onClick={() => handleChange(page_number)}>
                            <ExpandMore />
                        </IconButton>
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Grid container style={{ cursor: "default !important" }}>
                        <Grid zeroMinWidth item xs={12} md={8} style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 8 }}>
                                <Typography variant="h6">Pagina {page_number}</Typography>
                            </div>
                            {/* <Typography
                                sx={{   maxHeight: "1.5em",
                                    lineHeight: "1.5em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    width: "fit-content",}}
                                    id={`opportunity-${page_number}`}
                                    dangerouslySetInnerHTML={{
                                    __html: "fragments",
                                }}
                            /> */}
                        </Grid>
                    </Grid>

                    {/*
                     *   Grid with archive button
                     */}
                    <Grid item xs={12} md={1} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Tooltip title="Pagina gezien" placement="left">
                            <IconSeen style={{ width: "22px", height: "22px", fill: "#508c79" }} />
                        </Tooltip>
                    </Grid>
                </AccordionSummary>
                {/*
                 *   collapse div
                 */}

                <AccordionDetails style={{ padding: 0 }}>
                    <div style={{ width: "100%" }}>
                        <FoundResult
                            year_from={year_from}
                            read={read}
                            showPageNumber={false}
                            page={page_number}
                            pdfUrl={pdfUrl}
                            active={active}
                            onPageClick={onPageClick}
                            fragment_id={page_id}
                            data={data}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default CollapsableOpportunity;
