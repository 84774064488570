import React, { useEffect, useState } from "react";
import TasksContainer from "./TasksContainer";
import TaskFilterBar from "./TaskFilterBar";
import { useLazyQuery } from "@apollo/client";
import { GET_TASKS_WITH_FILTERS } from "../../../graphql/queryDefTasks";
import {
    getTasksWithFilters,
    getTasksWithFilters_filterUserTasks_data,
    getTasksWithFilters_filterUserTasks_filters,
    getTasksWithFilters_filterUserTasks_pagination,
    getTasksWithFiltersVariables,
} from "../../../__generated__/getTasksWithFilters";
import setTitle from "../../../utils/setTitle";
import { useUserId } from "../../../components/contextProviders/UserIdContext";
import { Disable } from "react-disable";

interface Props {
    a?: number;
}

const TaskView: React.FC<Props> = (props) => {
    const { userId } = useUserId();

    // State for filters
    const [filterData, setFilterData] = useState<getTasksWithFilters_filterUserTasks_filters | null>();

    const defaultVariables: getTasksWithFiltersVariables = {
        page: 1,
        perPage: 20,
    };

    const [data, setData] = useState<getTasksWithFilters_filterUserTasks_data[]>([]);

    // State for pagination
    const [pagination, setPagination] = useState<getTasksWithFilters_filterUserTasks_pagination>();

    const [activeFilters, setActiveFilters] = useState<getTasksWithFiltersVariables>({
        page: defaultVariables.page,
        perPage: defaultVariables.perPage,
        user_ids: [userId],
        task_completed: "false",
    });

    /**
     * Define the GQL query
     */
    const [run, { error, loading }] = useLazyQuery<getTasksWithFilters, getTasksWithFiltersVariables>(GET_TASKS_WITH_FILTERS, {
        variables: { page: 1, perPage: 10 },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && data.filterUserTasks) {
                setPagination(data.filterUserTasks.pagination);
                setData(data.filterUserTasks.data);
                setFilterData(data.filterUserTasks.filters);
            }
        },
    });

    useEffect(() => {
        setTitle("Taken");

        // Run the GraphQL query with the updated filters
        run({
            variables: activeFilters, // Only include non-empty filters, and set empty arrays to null
        });
    }, [run, activeFilters]);

    if (loading) {
        return (
            <Disable disabled>
                <TaskFilterBar filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
                <TasksContainer
                    data={data}
                    pagination={pagination}
                    setActiveFilters={setActiveFilters}
                    setData={setData}
                    activeFilters={activeFilters}
                    loading={loading}
                    refetch={run}
                />
            </Disable>
        );
    }

    if (error || !data) {
        return (
            <Disable disabled>
                <TaskFilterBar filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
                error
            </Disable>
        );
    }

    return (
        <div>
            <TaskFilterBar filterData={filterData} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />

            <TasksContainer
                data={data}
                pagination={pagination}
                setActiveFilters={setActiveFilters}
                activeFilters={activeFilters}
                loading={loading}
                refetch={() =>
                    run({
                        variables: activeFilters, // Only include non-empty filters, and set empty arrays to null
                    })
                }
                setData={setData}
            />
        </div>
    );
};

export default TaskView;
