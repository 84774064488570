import { Link, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Typography } from "@mui/material";

//icons used in this widget
import PhoneIcon from "@mui/icons-material/Phone";
import PrintIcon from "@mui/icons-material/Print";
import LanguageIcon from "@mui/icons-material/Language";
import MailIcon from "@mui/icons-material/Mail";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";

import {
    GetOrganizationDetail_tenderer_emails,
    GetOrganizationDetail_tenderer_phonenumbers,
    GetOrganizationDetail_tenderer_postal_addresses,
    GetOrganizationDetail_tenderer_urls,
} from "../../__generated__/GetOrganizationDetail";
import GeneralInformationModule from "../GeneralInformationModule";
import { EmailType } from "../../__generated__/globalTypes";

interface Props {
    urls: GetOrganizationDetail_tenderer_urls[];
    phonenumbers: GetOrganizationDetail_tenderer_phonenumbers[];
    emails: GetOrganizationDetail_tenderer_emails[];
    postal_adresses: GetOrganizationDetail_tenderer_postal_addresses[];
}

const PhoneWebWidget: React.FC<Props> = ({ urls, phonenumbers, emails, postal_adresses }) => {
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div style={{ marginBottom: 20, width: "100%" }}>
            {/* map over settlings to get type 'hoofdvestiging' check length of faxnumber array. if 0 and other values are also 0 dont show title */}
            {urls.length === 0 || (urls.length > 0 && phonenumbers.length === 0 && emails.length === 0 && postal_adresses.length === 0) ? (
                <></>
            ) : (
                <Typography variant="h4" sx={{ margin: "4px 0" }}>
                    Contactgegevens
                </Typography>
            )}
            {/* PHONE */}
            {phonenumbers?.map((phone) => {
                if (phone.phonenumber !== null)
                    return (
                        <GeneralInformationModule
                            key={phone.id}
                            icon={phone.type === "PHONE" ? <PhoneIcon fontSize="small" /> : <PrintIcon fontSize="small" />}
                        >
                            <ul
                                style={{
                                    listStyle: "none",
                                    margin: onlyMobile ? "0px" : "0 0 0 16px",
                                    padding: "0px",
                                }}
                            >
                                <Link
                                    underline="none"
                                    key={phone.id}
                                    href={`tel: ${phone.phonenumber}`}
                                    style={{ color: "#465c84", textDecoration: "none" }}
                                >
                                    <li>
                                        <Typography noWrap>{phone.phonenumber}</Typography>
                                    </li>
                                </Link>
                            </ul>
                        </GeneralInformationModule>
                    );
                return <></>;
            })}

            {/* Email */}
            {emails
                .filter((m) => m.type !== EmailType.FINANCE)
                .filter((m) => m.type !== EmailType.INVOICE)
                .map((mail) => {
                    if (mail.email !== null)
                        return (
                            <GeneralInformationModule key={mail.id} icon={<MailIcon fontSize="small" />}>
                                <ul
                                    style={{
                                        listStyle: "none",
                                        margin: onlyMobile ? "0px" : "0 0 0 16px",
                                        padding: "0px",
                                    }}
                                >
                                    <Link
                                        underline="none"
                                        key={mail.id}
                                        href={`mailto: ${mail.email}`}
                                        style={{ color: "#465c84", textDecoration: "none" }}
                                    >
                                        <li>
                                            <Typography noWrap>{mail.email}</Typography>
                                        </li>
                                    </Link>
                                </ul>
                            </GeneralInformationModule>
                        );
                    return <></>;
                })}
            {/* WEBSITE URL */}
            {urls?.map((url, i) => {
                //change ABOUT_US to another type when its possible.
                if (url.type === "HOME") {
                    // If url start with 'www' add 'https://' otherwise use url.url
                    const urlToNavigateTo = url.url.startsWith("www") ? "https://" + url.url : url.url;
                    return (
                        <GeneralInformationModule key={i} icon={<LanguageIcon fontSize="small" />}>
                            <ul
                                style={{
                                    listStyle: "none",
                                    margin: onlyMobile ? "0px" : "0 0 0 16px",
                                    padding: "0px",
                                }}
                            >
                                <Link
                                    underline="none"
                                    key={url.id}
                                    href={urlToNavigateTo}
                                    style={{ color: "#465c84", textDecoration: "none" }}
                                    target="_blank"
                                >
                                    <li>
                                        <Typography noWrap>{url.url.replace(/(^\w+:|^)\/\//, "")}</Typography>
                                    </li>
                                </Link>
                            </ul>
                        </GeneralInformationModule>
                    );
                }
                return <></>;
            })}
            {/* POSTAL */}
            {postal_adresses.map((item) => {
                if (postal_adresses !== null)
                    return (
                        <GeneralInformationModule key={item.id} icon={<MarkunreadMailboxOutlinedIcon fontSize="small" />}>
                            <ul
                                style={{
                                    listStyle: "none",
                                    margin: onlyMobile ? "0px" : "0 0 0 16px",
                                    padding: "0px",
                                }}
                            >
                                <div key={item.id}>
                                    <li>
                                        {item.street} {item.housenumber} {item.housenumber_suffix}, {item.postal_code} {item.city}{" "}
                                        {item.country !== "NL" ? `( ${item.country})` : null}
                                    </li>
                                </div>
                            </ul>
                        </GeneralInformationModule>
                    );
                return <></>;
            })}
        </div>
    );
};

export default PhoneWebWidget;
