import React from "react";
import ChosenTemplateToEdit from "./ChosenTemplateToEdit";
import CreatedTemplates from "./CreatedTemplates";
import { taskTemplates_templates } from "../../../../__generated__/taskTemplates";
import { ApolloError } from "@apollo/client";

interface Props {
    templateToEdit: taskTemplates_templates | null;
    setTemplateToEdit: (template: taskTemplates_templates | null) => void;
    loading: boolean;
    error: ApolloError | undefined;
    templates: taskTemplates_templates[];
    refetch: () => void;
}

const TemplateContainer: React.FC<Props> = ({ templateToEdit, setTemplateToEdit, loading, error, templates, refetch }) => {
    return (
        <>
            {/* LIST OF CREATED TEMPLATES */}
            {templateToEdit === null && (
                <CreatedTemplates setTemplateToEdit={setTemplateToEdit} templates={templates} loading={loading} error={error} refetch={refetch} />
            )}
            {/* TEMPLATE TO EDIT */}
            {templateToEdit !== null && (
                <ChosenTemplateToEdit template={templateToEdit} returnToOverview={() => setTemplateToEdit(null)} refetch={refetch} />
            )}
        </>
    );
};

export default TemplateContainer;
