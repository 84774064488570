import React from "react";
import { Theme, Grid } from "@mui/material";
import FilterDropdownTemplate from "./FilterDropdownTemplate";
import { TenderFilterInput } from "../../__generated__/globalTypes";
import update from "immutability-helper";
import moment, { Moment } from "moment";
// import MomentUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
export type DateRange = { from: Moment | null; to: Moment | null };

interface Props {
    dateRangeState: DateRange;
    onChange(searchInput: TenderFilterInput): void;
    searchInput: TenderFilterInput;
    // Pass through to template component
    label: string;
    borderColor?: keyof Theme["moduleColors"];
    setDateRange(dateRange: DateRange): void;
    active: boolean | null;
}

const PeriodFilter: React.FC<Props> = ({ setDateRange, dateRangeState, onChange, label, borderColor, searchInput, active }) => {
    const { t } = useTranslation();

    /**
     * DatePicker
     */
    const error = dateRangeState.from !== null && dateRangeState.to !== null && dateRangeState.from.valueOf() > dateRangeState.to.valueOf();
    const labelToUse = label === "lastUpdate" ? "lastUpdate" : label.toLocaleLowerCase();
    /**
     * Handler when the popover gets closed
     * onError => unset filter to avoid errors in GUI
     * onSuccess => set filter to selected DateRange
     * Format to YYYY-MM-DD (format that is used in backend)
     */
    const handleSave = () => {
        if (error || dateRangeState.from === null || dateRangeState.to === null) {
            return onChange(update(searchInput, { $unset: [labelToUse as keyof TenderFilterInput] }));
        }
        onChange(
            update(searchInput, {
                [labelToUse]: {
                    $set: {
                        from: moment(dateRangeState.from).format("YYYY-MM-DD"),
                        to: moment(dateRangeState.to).format("YYYY-MM-DD"),
                    },
                },
            })
        );
    };

    // Close modal and set state to daterange
    const handleClose = () => {
        setDateRange(dateRangeState);
    };

    /**
     * Template settings
     */
    const labelForTemplate: React.ReactNode = active === true ? <strong>{t(`filter.${label}`)}</strong> : t(`filter.${label}`);

    // TODO: change to keyboard date picker -> possible to type date
    return (
        <FilterDropdownTemplate
            // TODO: add dynamic disable value
            amountSelectedItems={active === true ? 1 : 0}
            disabled={false}
            label={labelForTemplate}
            borderColor={borderColor}
            onSave={handleSave}
            onClose={handleClose}
            allowReset={active === true}
            onReset={() => {
                setDateRange({ from: null, to: null });
                onChange(update(searchInput, { $unset: [label as keyof TenderFilterInput] }));
            }}
        >
            <div style={{ maxWidth: 400, padding: 12 }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.From")}
                            value={dateRangeState.from}
                            onChange={(date) => setDateRange({ from: date ? date : null, to: dateRangeState.to })}
                            maxDate={dateRangeState.to ? dateRangeState.to.clone() : undefined}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.To")}
                            // autoOk={true}
                            value={dateRangeState.to}
                            onChange={(date) => {
                                setDateRange({ to: date ? date : null, from: dateRangeState.from });
                            }}
                            minDate={dateRangeState.from ? dateRangeState.from.clone() : undefined}
                        />
                    </Grid>
                </Grid>
            </div>
        </FilterDropdownTemplate>
    );
};

export default PeriodFilter;
