import React from "react";
import { useIncognito } from "./IncognitoContext";
import { Visibility } from "@mui/icons-material";

interface Props {
    children: React.ReactNode; // Content of your app
}

const IncognitoWrapper: React.FC<Props> = ({ children }) => {
    const { helpingUser } = useIncognito();

    const wrapperStyle: React.CSSProperties = {
        position: "relative",
        border: helpingUser ? "5px solid orange" : "none",
        // zIndex: 9999,
        overflow: "hidden",

        height: "100vh",
        boxSizing: "border-box",
    };

    const bannerStyle: React.CSSProperties = {
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "orange",
        display: "flex",
        alignItems: "center",
        color: "white",
        padding: "5px 15px",
        borderRadius: "0 0 5px 5px",
        zIndex: 1000,
        fontSize: "16px",
        fontWeight: "bold",
    };

    return (
        <div style={wrapperStyle}>
            {helpingUser && (
                <div style={bannerStyle}>
                    <Visibility htmlColor={"#fff"} sx={{ marginRight: "8px" }} /> {helpingUser}{" "}
                </div>
            )}
            {children}
        </div>
    );
};

export default IncognitoWrapper;
