import React, { useState } from "react";
import WidgetBox from "../../components/WidgetBox";
import CATabs from "./detailpage/mainWidget/CATabs";
import CATenderTabs from "./detailpage/tenderWidget/CATenderTabs";
import CATopBarModule from "./detailpage/CATopBarModule";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../../components/loading/FullPageSpinner";
import setTitle, { setTitleLoading } from "../../utils/setTitle";
import { GetCADetails, GetCADetailsVariables } from "../../__generated__/GetCADetails";
import { useQuery } from "@apollo/client";
import RenderPage from "../../components/loading/RenderPage";
import { useParams } from "react-router-dom";
import Div404 from "../Div404";
import { GetCaPurchasingPolicies, GetCaPurchasingPoliciesVariables } from "../../__generated__/GetCaPurchasingPolicies";
import { DynamicTab } from "../../components/ModuleTabs";
import { GetCADetailsContacts, GetCADetailsContactsVariables } from "../../__generated__/GetCADetailsContacts";
import { QUERY_CADETAILS, QUERY_CONTACTS, QUERY_PURCHASINGPOLICIES } from "../../graphql/queryDefCA";

interface Props {
    registerTab: (tab: DynamicTab) => void;
}

const ContractingAuthorityDetailPage: React.FC<Props> = ({ registerTab }) => {
    const { id } = useParams();
    const CAID = id as string;
    const { t } = useTranslation();
    const { loading, error, data } = useQuery<GetCADetails, GetCADetailsVariables>(QUERY_CADETAILS, {
        variables: { id: CAID },
        fetchPolicy: "network-only",
    });
    const { data: contact } = useQuery<GetCADetailsContacts, GetCADetailsContactsVariables>(QUERY_CONTACTS, {
        variables: { id: CAID },
        fetchPolicy: "network-only",
    });
    const [urls, setUrls] = useState(false);
    const [thresholds, setThresholds] = useState(false);

    useQuery<GetCaPurchasingPolicies, GetCaPurchasingPoliciesVariables>(QUERY_PURCHASINGPOLICIES, {
        variables: { id: CAID },
        onCompleted: (data) => {
            if (
                data.contracting_authority?.urls.filter((u) => u.type === "PURCHASINGPOLICY") !== undefined &&
                data.contracting_authority?.urls.filter((u) => u.type === "PURCHASINGPOLICY").length > 0
            ) {
                setUrls(true);
            }
            if (data.contracting_authority?.thresholds.length !== undefined && data.contracting_authority?.thresholds.length > 0) {
                setThresholds(true);
            }
        },
    });

    if (loading) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    if (error || !data?.contracting_authority) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <Div404 url={`/contracting-authorities/${id}`} />
            </RenderPage>
        );
    }
    const tabValue = `/contracting-authorities/${id}`;
    const name = data.contracting_authority.name;
    setTitle(name || "Organisatie");

    registerTab({ value: tabValue, label: name || "" });
    // check if ca has settlings -> if yes -> show tab 'settlings'
    const settlingCount = data.contracting_authority.settlings?.data.filter((s) => s.type !== "rechtspersoon").length;
    const contacts = contact?.contracting_authority?.employees?.length;
    return (
        <div className="responsive-container">
            <CATopBarModule
                status={
                    null
                    //data.contracting_authority.status
                }
                id={data.contracting_authority.id}
                name={data.contracting_authority.name}
            />
            {/* Dynamic name of CA */}
            <Typography variant="h2" style={{ paddingTop: 12 }}>
                {name}
            </Typography>
            {/* Widgetbox with tabs for CA details */}
            <WidgetBox header="" highlight="yellow" padding={0}>
                <div>
                    <CATabs
                        id={data.contracting_authority.id}
                        settlingCount={settlingCount ? settlingCount : 0}
                        policyLength={urls || thresholds}
                        contacts={contacts}
                    />
                </div>
            </WidgetBox>

            {/* widgetbox with tabs for CA tenderdetails */}

            {data.contracting_authority.tenders.length > 0 && (
                <React.Fragment>
                    <Typography variant="h3">{t("tenders")}</Typography>
                    <WidgetBox header="" highlight="blue" padding={0}>
                        <div>
                            <CATenderTabs
                                ca_id={CAID}
                                ca_name={data.contracting_authority.name}
                                ca_favicon={data.contracting_authority.favicon}
                                ca_favicon_resized={data.contracting_authority.favicon_resized}
                            />
                        </div>
                    </WidgetBox>
                </React.Fragment>
            )}
        </div>
    );
};

export default ContractingAuthorityDetailPage;
