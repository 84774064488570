import React, { useState, useCallback, useEffect } from "react";
import { Dialog, Paper, Popper, useMediaQuery, useTheme } from "@mui/material";
import { Divider, IconButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import TooltipOnNewLines from "../../components/TooltipOnNewLines";
import FaviconBox from "../../components/FaviconBox";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { SavedTenderEvents_getCalendarEvents_TenderDate } from "../../__generated__/SavedTenderEvents";
import moment from "moment";
import StatusDot from "../../components/StatusDot";

interface Props {
    content: React.ReactNode;
    data: SavedTenderEvents_getCalendarEvents_TenderDate;
    setId(): void;
    setActiveTooltip: (bln: any) => void;
    openId: string;
    setOpenId: (str: string) => void;
    placement?:
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end"
        | "right-start"
        | "right-end"
        | "left-start"
        | "left-end"
        | "top"
        | "left"
        | "right"
        | "bottom";
}

const TenderDateTooltip: React.FC<Props> = ({ content, data, setActiveTooltip, setId, openId, setOpenId, placement = "right-start" }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [opened, setOpened] = useState<string>("");
    /**
     * Open popover underneath clicked item
     * @param event clicked target props
     */
    const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
        setAnchorEl(event.currentTarget);
        setOpenId(id);
    };

    /**
     * Close popover
     */
    const handleTooltipClose = useCallback(() => {
        setAnchorEl(null);
        setActiveTooltip(false);
    }, [setActiveTooltip]);

    useEffect(() => {
        if (openId !== data.id) {
            handleTooltipClose();
        }
    }, [data.id, handleTooltipClose, openId]);

    /**
     * Boolean if anchorEl is set
     */
    const open = Boolean(anchorEl) && Boolean(data.id === openId);

    return (
        <React.Fragment>
            {isMobile ? (
                <>
                    <div onClick={(e) => setOpened(data.id)}>{content}</div>
                    <Dialog fullWidth open={data.id === opened} onClose={() => setOpened("")}>
                        <EventPaper data={data} setId={setId} setOpenId={setOpenId} />
                    </Dialog>
                </>
            ) : (
                <div>
                    <div onClick={(e) => handleTooltipOpen(e, data.id)}>{content}</div>
                    <Popper
                        style={{
                            zIndex: 99,
                            boxShadow: `0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2)`,
                            borderRadius: "8px",
                        }}
                        id={open ? data.id : undefined}
                        open={open}
                        anchorEl={anchorEl}
                        placement={placement}
                        // onClose={handleTooltipClose}
                        // anchorOrigin={{
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        // }}
                        // transformOrigin={{
                        //     vertical: "top",
                        //     horizontal: "left",
                        // }}
                    >
                        <EventPaper data={data} setId={setId} setOpenId={setOpenId} />
                    </Popper>
                </div>
            )}
        </React.Fragment>
    );
};

interface EventPaperProps {
    data: SavedTenderEvents_getCalendarEvents_TenderDate;
    setId(str: string): void;
    setOpenId: (str: string) => void;
}

/**
 * @returns Tooltip component with eventbased data/actions
 */
const EventPaper: React.FC<EventPaperProps> = ({ data: tada, setOpenId, setId }) => {
    // const { t } = useTranslation();
    const theme = useTheme();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const data: SavedTenderEvents_getCalendarEvents_TenderDate = tada;
    /**
     * Get ca with category 'A'
     */
    // const clientA = data.tender?.clients.find((ca) => ca.pivot?.category === "A");
    const clientA = data.tender?.clientA[0];
    /**
     * Title to show in tooltip
     * Check if timesignificant is true or false
     */
    const title = data.timeSignificant
        ? `<span style="font-weight:bold;">${moment(data.planned).format("LT")}</span> ${data.name.replace(/ percelen: 0/g, "")}`
        : data.name.replace(/ percelen: 0/g, "");

    return (
        <Paper square={false} elevation={0} style={{ width: onlyMobile ? undefined : "550px", padding: "8px" }}>
            <div
                style={{
                    minHeight: "44px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                    alignItems: "flex-start",
                    padding: "6px",
                }}
            >
                <div>
                    <TooltipOnNewLines
                        placement={"bottom-start"}
                        text={title || ""}
                        variant={"body1"}
                        lines={2}
                        id={`T${data.tender?.id as string}`}
                        fontweight={"normal"}
                        fontSize={16}
                    />
                    <Typography style={{ textTransform: "capitalize", marginTop: "4px", fontSize: "16px" }}>
                        {moment(data.planned).format("dddd, D MMMM")}
                    </Typography>
                </div>

                <div
                    style={{
                        height: "44px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginBottom: "4px",
                        alignItems: "center",
                        marginTop: "-8px",
                    }}
                >
                    {/* Edit button */}
                    {/* <Tooltip title="Bewerken">
                        <IconButton>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                    {/* Delete button */}
                    {/* <Tooltip title="Verwijderen">
                        <IconButton>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                    {/* Share button */}
                    {/* <Tooltip title="Delen">
                        <IconButton>
                            <Share fontSize="small" />
                        </IconButton>
                    </Tooltip> */}

                    {/* Close button */}
                    {!onlyMobile && (
                        <Tooltip title="Sluiten">
                            <IconButton onClick={() => setOpenId("")}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            {/* Div to split header and content field */}
            <Divider />
            <div style={{ padding: "6px", display: "flex", alignItems: "center" }}>
                <ListItemText
                    primary={
                        // Tender name with option to click to tender
                        <Link style={{ display: "inline-block" }} onClick={() => setId(data.tender?.id as string)} to={`/tenders/${data.tender?.id}`}>
                            <TooltipOnNewLines
                                placement={"bottom-start"}
                                text={data.tender?.namegiventocontract || ""}
                                variant={"body1"}
                                lines={2}
                                id={`T${data.tender?.id as string}`}
                                fontweight={"normal"}
                                fontSize={16}
                            />{" "}
                        </Link>
                    }
                    secondary={
                        <>
                            {/* Contracting authority name and favicon and option to click to ca detailpage */}
                            <Typography sx={{ fontSize: "12px", color: "#173351", display: "flex", alignItems: "center", marginBottom: "4px" }}>
                                <FaviconBox
                                    favicon={clientA?.favicon_resized ?? clientA?.favicon ?? null}
                                    name={clientA?.name || data.tender?.published_opdrachtgever}
                                    color={"#F57117"}
                                />{" "}
                                {clientA ? (
                                    <Link to={`/contracting-authorities/${clientA?.id}`}>
                                        <TooltipOnNewLines
                                            placement={"bottom-start"}
                                            text={clientA.name || data.tender?.published_opdrachtgever}
                                            variant={"body1"}
                                            lines={1}
                                            id={`T${clientA.id}`}
                                            fontweight={"normal"}
                                            fontSize={14}
                                        />
                                    </Link>
                                ) : (
                                    <TooltipOnNewLines
                                        placement={"bottom-start"}
                                        text={data.tender?.published_opdrachtgever}
                                        variant={"body1"}
                                        lines={1}
                                        id={`T${data.tender?.id}`}
                                        fontweight={"normal"}
                                        fontSize={14}
                                    />
                                )}
                            </Typography>
                        </>
                    }
                />
                <div style={{ marginRight: "12px" }}>
                    <StatusDot type={data.tender?.status || null} placement={"left"} />
                </div>
            </div>
        </Paper>
    );
};

export default TenderDateTooltip;
