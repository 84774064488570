import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import setTitle from "../../../utils/setTitle";
import PersonalPageContainer from "./PersonalPageContainer";

const PersonalInformation: React.FC = () => {
    const { t } = useTranslation();
    //set tab title
    useEffect(() => {
        setTitle(t("PersonalInfo"));
    });

    return (
        <PersonalPageContainer />

        // <ProfileLayout title={<h3>{t("PersonalInfo")} </h3>}>
        //     <Grid container spacing={2}>
        //         <Grid item xs={12} md={5}>
        //             <WidgetBox header="" highlight="grey" padding={0}>
        //                 <PersonalDetails />
        //             </WidgetBox>
        //         </Grid>
        //         <Grid item xs={12} md={7}>
        //             <WidgetBox header="" highlight="grey" padding={0}>
        //                 <SecuritySettings />
        //             </WidgetBox>
        //         </Grid>
        //     </Grid>
        // </ProfileLayout>
    );
};

export default PersonalInformation;
