import { gql } from "@apollo/client";
//******************************************************* */
//****************** LIBRARY MUTATIONS ***************** */
//******************************************************* */
export const CREATE_NEW_TASK_IN_LIBRARY = gql`
    mutation createTaskInLibrary($description: String!) {
        createTaskInLibrary(description: $description) {
            id
            task_description
        }
    }
`;

export const UPDATE_TASK_IN_LIBRARY = gql`
    mutation updateTaskInLibrary($id: ID!, $description: String!) {
        updateTaskInLibrary(id: $id, description: $description) {
            id
            task_description
        }
    }
`;

export const DELETE_TASK_IN_LIBRARY = gql`
    mutation deleteTaskInLibrary($id: ID!) {
        deleteTaskInLibrary(id: $id)
    }
`;
//******************************************************* */
//****************** TEMPLATE MUTATIONS ***************** */
//******************************************************* */
export const ADD_TASK_TO_TEMPLATE = gql`
    mutation addTaskToTemplate($templateId: ID!, $taskId: ID!, $task_priority: Priority!) {
        addTaskToTemplate(templateId: $templateId, taskId: $taskId, task_priority: $task_priority)
    }
`;

export const UPDATE_TASK_IN_TEMPLATE = gql`
    mutation updateTaskInTemplate($id: ID!, $task_priority: Priority!) {
        updateTaskInTemplate(id: $id, task_priority: $task_priority)
    }
`;

export const DELETE_TASK_IN_TEMPLATE = gql`
    mutation deleteTaskInTemplate($id: ID!) {
        deleteTaskInTemplate(id: $id)
    }
`;

export const CREATE_TEMPLATE = gql`
    mutation createTemplate($name: String!) {
        createTemplate(name: $name) {
            id
            created_by {
                id
                employee {
                    name
                }
            }
            template_name
            tasks {
                id
                taskInfo {
                    id
                    task_description
                }
                task_deadline
                task_priority
            }
        }
    }
`;

export const UPDATE_TEMPLATE = gql`
    mutation updateTemplate($id: ID!, $name: String!) {
        updateTemplate(id: $id, name: $name) {
            id
            template_name
        }
    }
`;

export const DELETE_TEMPLATE = gql`
    mutation deleteTemplate($id: ID!) {
        deleteTemplate(id: $id)
    }
`;
