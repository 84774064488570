import React from "react";

import { IconButton, List, ListItem, Paper, Typography } from "@mui/material";
import { taskTemplates_templates } from "../../../../__generated__/taskTemplates";
import TemplateTitle from "./TemplateTitle";
import TaskPriorityChip from "../../projectModal/task/TaskPriorityChip";
import { Priority } from "../../../../__generated__/globalTypes";
import { ArrowBack } from "@mui/icons-material";
import DeleteTaskInTemplate from "./DeleteTaskInTemplate";
import { updateTaskInTemplate, updateTaskInTemplateVariables } from "../../../../__generated__/updateTaskInTemplate";
import { UPDATE_TASK_IN_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

interface Props {
    template: taskTemplates_templates;
    returnToOverview: () => void;
    refetch: () => void;
}

const ChosenTemplateToEdit: React.FC<Props> = ({ template, returnToOverview, refetch }) => {
    // Explain mutation
    const [mutate, { loading, error }] = useMutation<updateTaskInTemplate, updateTaskInTemplateVariables>(UPDATE_TASK_IN_TEMPLATE);

    // Explain mutation handler
    const changePrio = async (id: string, prio: Priority) => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await mutate({
                variables: {
                    id: id,
                    task_priority: prio,
                },
                onCompleted: () => {
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <>
            {/* title of template with option to edit name */}
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton size="small" onClick={returnToOverview}>
                    <ArrowBack fontWeight="small" />
                </IconButton>
                <TemplateTitle title={template.template_name} id={template.id} refetch={refetch} />
            </div>
            <List
                dense
                sx={{
                    paddingRight: "24px",
                    height: `calc(100% - 24px)`,
                    overflow: "auto",
                }}
            >
                {template.tasks.map((task) => {
                    return (
                        <Paper key={task.id} variant="outlined" sx={{ marginBottom: "4px", padding: "4px 8px" }}>
                            <ListItem key={task.id} sx={{ padding: 0, display: "flex", justifyContent: "space-between" }}>
                                {/* TITLE */}
                                <Typography>{task.taskInfo.task_description}</Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* PRIORITY */}
                                    {task.task_priority ? (
                                        <TaskPriorityChip
                                            dot={false}
                                            disabled={loading}
                                            priority={task.task_priority.toLocaleUpperCase() as Priority}
                                            updatePrio={(prio) => {
                                                changePrio(task.id, prio as Priority);
                                            }}
                                        />
                                    ) : (
                                        <>ntb</>
                                    )}
                                    <DeleteTaskInTemplate id={task.id} refetch={refetch} />
                                </div>
                            </ListItem>
                        </Paper>
                    );
                })}
            </List>
        </>
    );
};

export default ChosenTemplateToEdit;
