import React, { useState } from "react";
import { Box, CircularProgress, IconButton, List, ListItemButton, Paper, Tooltip, Typography } from "@mui/material";
import { taskTemplates_templates } from "../../../../__generated__/taskTemplates";
import { Add, Edit } from "@mui/icons-material";
import DeleteTemplate from "./DeleteTemplate";
import CreateTemplate from "./CreateTemplate";
import { ApolloError } from "@apollo/client";

interface Props {
    setTemplateToEdit: (template: taskTemplates_templates | null) => void;
    templates: taskTemplates_templates[];
    loading: boolean;
    error: ApolloError | undefined;
    refetch: () => void;
}

const CreatedTemplates: React.FC<Props> = ({ setTemplateToEdit, loading, templates, error, refetch }) => {
    const [add, setAdd] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<string>("");

    // LOADING
    if (loading) {
        return (
            <div style={{ paddingRight: "24px" }}>
                <div style={{ height: "34px", display: "flex", alignItems: "center" }}>
                    <Typography fontWeight={500} variant="h5">
                        Sjablonen
                    </Typography>
                </div>
                <Box sx={{ height: "38px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress color="primary" size="20px" />
                </Box>
            </div>
        );
    }

    // ERROR
    if (error) {
        return (
            <Paper elevation={0} variant="outlined" style={{ marginBottom: "16px" }}>
                error
            </Paper>
        );
    }

    return (
        <div style={{ paddingRight: "24px" }}>
            <div style={{ height: "34px", display: "flex", alignItems: "center", marginRight: "4px" }}>
                {!add ? (
                    <Typography fontWeight={500} variant="h5">
                        Sjablonen
                    </Typography>
                ) : (
                    <CreateTemplate refetch={refetch} onCancel={() => setAdd(false)} />
                )}
                {!add && (
                    <IconButton size="small" onClick={() => setAdd(!add)}>
                        <Tooltip title="Sjabloon toevoegen" placement="right" disableInteractive>
                            <Add fontSize="small" />
                        </Tooltip>
                    </IconButton>
                )}
            </div>

            <Paper elevation={0} variant="outlined" style={{ marginBottom: "16px", overflowX: "hidden", marginTop: "8px" }}>
                <List disablePadding>
                    {templates.map((template) => {
                        return (
                            <Tooltip
                                key={template.id}
                                title={`${template.template_name} aanpassen`}
                                followCursor
                                placement="top"
                                open={showTooltip === template.id}
                                onMouseOver={() => setShowTooltip(template.id)}
                                onMouseOut={() => setShowTooltip("")}
                            >
                                <ListItemButton
                                    onClick={() => {
                                        setTemplateToEdit(template);
                                    }}
                                    key={template.id}
                                    sx={{ padding: 0, display: "flex", justifyContent: "space-between" }}
                                >
                                    <Typography sx={{ padding: "0px 8px", fontWeight: 500 }}>{template.template_name}</Typography>
                                    <div style={{ margin: "4px", display: "flex" }}>
                                        <Tooltip title={`${template.template_name} aanpassen`} placement="top">
                                            <IconButton
                                                onMouseOver={(e) => {
                                                    e.stopPropagation();
                                                    setShowTooltip("");
                                                }}
                                                onMouseOut={(e) => {
                                                    e.stopPropagation();
                                                    setShowTooltip("");
                                                }}
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setTemplateToEdit(template);
                                                }}
                                            >
                                                <Edit fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <div
                                            onMouseOver={(e) => {
                                                e.stopPropagation();
                                                setShowTooltip("");
                                            }}
                                            onMouseOut={(e) => {
                                                e.stopPropagation();
                                                setShowTooltip("");
                                            }}
                                        >
                                            <DeleteTemplate id={template.id} refetch={refetch} />
                                        </div>
                                    </div>
                                </ListItemButton>
                            </Tooltip>
                        );
                    })}
                </List>
            </Paper>
        </div>
    );
};

export default CreatedTemplates;
