import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, Box, Divider, Typography } from "@mui/material";
import TaskContainer from "./library/TaskContainer";
import { taskTemplates, taskTemplates_templates } from "../../../__generated__/taskTemplates";
import { GET_TASK_TEMPLATES } from "../../../graphql/queryDefTasks";
import { useQuery } from "@apollo/client";
import TemplateExample from "./template/TemplateExample";
import TemplateContainer from "./template/TemplateContainer";

interface Props {
    btn: React.ReactNode;
}

const TaskLibrary: React.FC<Props> = ({ btn }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [templateToEdit, setTemplateToEdit] = useState<taskTemplates_templates | null>(null);

    const { data, loading, error, refetch } = useQuery<taskTemplates>(GET_TASK_TEMPLATES, {
        fetchPolicy: "network-only",
    });

    const [width, setWidth] = useState(400); // Initial width of TEMPLATING
    const containerRef = useRef<HTMLDivElement | null>(null); // Explicitly define type
    const isResizing = useRef(false);

    // Update width dynamically when data changes
    useEffect(() => {
        if (data?.templates?.length === 0) {
            setWidth(window.innerWidth); // Set to full width if data is empty
        }
    }, [data]);

    const startResizing = useCallback((e: React.MouseEvent) => {
        if (!containerRef.current) return;

        isResizing.current = true;
        const initialWidth = containerRef.current.offsetWidth;
        const initialX = e.clientX;

        const onMouseMove = (moveEvent: MouseEvent) => {
            if (isResizing.current && containerRef.current) {
                const diff = initialX - moveEvent.clientX; // Invert the calculation

                setWidth(Math.max(initialWidth + diff, 0)); // Prevent negative width
            }
        };

        const stopResizing = () => {
            isResizing.current = false;
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", stopResizing);
            document.body.style.cursor = "default"; // Reset cursor
        };

        document.body.style.cursor = "col-resize"; // Change cursor style during resizing
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", stopResizing);
    }, []);

    return (
        <>
            <Box
                sx={{ marginRight: "8px", display: "flex", alignItems: "center" }}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {btn}
            </Box>
            <Dialog
                open={open}
                maxWidth={"lg"}
                fullWidth
                onClose={() => {
                    setOpen(false);
                    setTimeout(() => {
                        setTemplateToEdit(null);
                    }, 500);
                }}
            >
                <DialogContent
                    sx={{ display: "flex", flexDirection: "row", height: "80vh", padding: "0px", paddingBottom: "24px", paddingTop: "24px" }}
                >
                    <Box sx={{ flex: 1, paddingLeft: "24px" }}>
                        {/* TASKCOMPONENT */}
                        <TaskContainer template={templateToEdit} refetchTemplate={refetch} />
                    </Box>

                    {/* resizer */}
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            margin: "0px 28px",
                            position: "relative", // Ensure proper positioning context
                            "& .MuiDivider-wrapper": {
                                position: "absolute", // Position the wrapper within the divider
                                top: "50%", // Center the wrapper vertically
                                left: "50%", // Center the wrapper horizontally
                                transform: "translate(-50%, -50%) rotate(-90deg)", // Center and rotate
                                transformOrigin: "center", // Rotate around the center
                                color: "#b8b8b8", // Text color
                                fontSize: "16px", // Font size
                                fontWeight: 500, // Font weight
                            },
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography
                                fontWeight={500}
                                sx={{ backgroundColor: "#fff", cursor: "pointer", padding: "8px", userSelect: "none" }}
                                onMouseDown={startResizing}
                            >
                                Sjablonen
                            </Typography>
                        </div>
                    </Divider>

                    <Box
                        ref={containerRef}
                        sx={{
                            width: `${width}px`,
                            overflow: "hidden",
                        }}
                    >
                        {data && data.templates && data?.templates?.length > 0 ? (
                            <TemplateContainer
                                templateToEdit={data?.templates?.find((t) => t.id === templateToEdit?.id) ?? null}
                                setTemplateToEdit={setTemplateToEdit}
                                templates={data?.templates ?? []}
                                loading={loading}
                                error={error}
                                refetch={refetch}
                            />
                        ) : (
                            <TemplateExample refetch={refetch} />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TaskLibrary;
