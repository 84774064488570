import React, { createContext, useRef, useState } from "react";
import "./MainLayout.scss";

import TopHeader from "./TopHeader";
import NavBar from "./NavBar/NavBar";
import MobileHeader from "./MobileHeader";
import TenderNoteDialog from "../dialogs/TenderNoteDialog";
import { TenderChatContext } from "../contextProviders/TenderChatContext";
import { NotificationContext } from "../contextProviders/NotificationOpenerContext";
import { ArchiveContext } from "../contextProviders/ArchiveContext";
import ArchiveDialog from "../dialogs/ArchiveDialog";
import { ProfileContext } from "../contextProviders/ProfileOpenerContext";
import { useMediaQuery, useTheme } from "@mui/material";
import { SearchCustomOptionsContext } from "../contextProviders/SearchRuleCustomOptionProvider";
import Tour from "reactour";
import { TourContext } from "../contextProviders/TourContext";
import { FirstSearchRuleContext } from "../contextProviders/FirstSearchRuleContext";
import { useQuery } from "@apollo/client";
import { CuOrganizationContext } from "../contextProviders/CurrentUserOrganization";
import { QUERY_ORGANIZATION_CURRENT_USER } from "../../graphql/queryDefUserAndTeams";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { OrganizationOfCurrentUser } from "../../__generated__/OrganizationOfCurrentUser";
import {
    GetLoggedInUsersData,
    GetLoggedInUsersData_currentUser_contracting_authorities,
    GetLoggedInUsersData_currentUser_market_parties,
} from "../../__generated__/GetLoggedInUsersData";
import { User } from "../../routes/projects/projectModal/Avatars";
import ProjectModal from "../../routes/projects/projectModal/ProjectModal";
import { full_single_project_project_comments, full_single_project_project_tasks_users } from "../../__generated__/full_single_project";
import { QUERY_GETUSERDATA } from "../../graphql/queryDefCurrentUser";
import { TenderSearchProvider } from "../guides/tenderWizard/WizardContext";
import useScrollPosition from "./useScrollPosition";
import { useMyContext } from "./ScrollableContentContextProvider";
import IncognitoWrapper from "./NavBar/admin_components/IncognitoWrapper";

// import DesktopWindowsIcon from "@mui/icons-material/DesktopWindowsOutlined";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import BusinessIcon from "@mui/icons-material/Business";
// import TodayIcon from "@mui/icons-material/Today";
// import DescriptionIcon from "@mui/icons-material/Description";
// import GroupIcon from "@mui/icons-material/Group";
// import MenuIcon from "@mui/icons-material/Menu";

interface Props {
    children: React.ReactNode;
    page?: string;
    header?: React.ReactNode;
}

export interface Widget {
    summary: boolean;
    planning: boolean;
    scope: boolean;
    documents: boolean;
    contract: boolean;
    labels: boolean;
}

export enum Priority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export interface Task {
    __typename: "UserTask";
    id: string;
    task_description: string;
    task_completed: boolean;
    task_deadline: any | null;
    task_priority: Priority | null;
    users: full_single_project_project_tasks_users[] | null;
}
export interface Project {
    projectID: string;
    projectTitle: string;
    users: User[];
    ca_ids: string[];
    tender_ids: string[];
    widgets: Widget;
    state: string;
    tasks: Task[] | null;
    notes: full_single_project_project_comments[];
}

type ProjectToOpenContextType = {
    open: boolean;
    setOpen: (bln: boolean) => void;
    project: Project;
    changeProject: (change: Partial<Project>) => void;
};

export const ProjectToOpenContext = createContext<ProjectToOpenContextType>(null as unknown as ProjectToOpenContextType);

const MainLayout: React.FC<Props> = ({ children, page, header }) => {
    const theme = useTheme();
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [chatTitle, setChatTitle] = useState("");
    const [archive_id, setArchiveId] = useState("");
    const [openModalArchive, setOpenModalArchive] = useState(false);
    const [archive_title, setArchiveTitle] = useState("");
    const [modelId, setModelId] = useState<string | null>(null);
    const [modelType, setModelType] = useState<string | null>(null);
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [customOptions, setCustomOptions] = useState([]);
    const [openSupport, setOpenSupport] = useState<boolean>(false);
    const [initials, setInitials] = useState<string>("");
    const [givenname, setGivenname] = useState<string>("");
    const [middlename, setMiddlename] = useState<string>("");
    const [org_id, setOrgid] = useState<string>("");
    const [org_name, setOrgname] = useState<string>("");
    const [org_logo, setOrglogo] = useState<string>("");
    const [org_favicon, setOrgfavicon] = useState<string>("");
    const [familyname, setFamilyname] = useState<string>("");
    const [employee_id, setEmployeeid] = useState<string>("");
    const [email, setMail] = useState<string>("");
    const [conversationIdToOpen, setConversationIdToOpen] = useState<string>("");
    const [datafetched, setDatafetched] = useState<boolean>(false);
    const [myCAS, setMyCas] = useState<GetLoggedInUsersData_currentUser_contracting_authorities[]>([]);
    const [myMPs, setMyMPs] = useState<GetLoggedInUsersData_currentUser_market_parties[]>([]);
    const { setValue } = useMyContext();

    useQuery<OrganizationOfCurrentUser>(QUERY_ORGANIZATION_CURRENT_USER, {
        onCompleted: (data) => {
            if (data) {
                setOrgid(data.currentUser.employee.organization.id);
            }
        },
    });
    const { pathname } = useLocation();

    // const navigate = useNavigate();

    const setProfileData = (
        new_initials: string,
        new_givenname: string,
        new_middlename: string,
        new_familyname: string,
        new_org_id: string,
        new_org_name: string,
        new_org_logo: string,
        new_org_favicon: string,
        new_employee_id: string,
        new_email: string,
        fetched: boolean
    ) => {
        setInitials(new_initials);
        setGivenname(new_givenname);
        setMiddlename(new_middlename);
        setFamilyname(new_familyname);
        setOrgid(new_org_id);
        setOrgname(new_org_name);
        setOrglogo(new_org_logo);
        setOrgfavicon(new_org_favicon);
        setEmployeeid(new_employee_id);
        setMail(new_email);
        setDatafetched(fetched);
    };

    useQuery<GetLoggedInUsersData>(QUERY_GETUSERDATA, {
        skip: datafetched === true,
        onCompleted: (data) => {
            if (data && data.currentUser) {
                // First letter of given name
                const firstLetter = data.currentUser.employee.givenname !== null ? data.currentUser.employee.givenname.charAt(0) : "";
                // first letter of family name
                const secondLetter = data.currentUser.employee.familyname !== null ? data.currentUser.employee.familyname.charAt(0) : "";
                setProfileData(
                    `${firstLetter}${secondLetter}`,
                    data.currentUser.employee.givenname || "",
                    data.currentUser.employee.middlename || "",
                    data.currentUser.employee.familyname || "",
                    data.currentUser.employee.organization.id,
                    data.currentUser.employee.organization.name || "",
                    data.currentUser.employee.organization.logo || "",
                    data.currentUser.employee.organization.favicon || "",
                    data.currentUser.employee.id,
                    data.currentUser.email,
                    true
                );
                setMyCas(data.currentUser.contracting_authorities);
                setMyMPs(data.currentUser.market_parties);
            }
        },
    });
    localStorage.setItem("initials", initials as string);

    // Tour states
    const [openTour, setOpenTour] = useState<boolean>(false);
    const [steps, setSteps] = useState<any[]>([]);
    const [currentTour, setCurrentTour] = useState<string>("");
    const [tourColor, setTourColor] = useState<string>("#173351");
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [lockTour, setLockTour] = useState<boolean>(false);

    // First searchrule provider
    const [wordModalTourOpen, setWordModalTourOpen] = useState<boolean>(false);
    const [domainModalTourOpen, setDomainModalTourOpen] = useState<boolean>(false);
    const [resultModalTourOpen, setResultModalTourOpen] = useState<boolean>(false);
    const [distModalTourOpen, setDistModalTourOpen] = useState<boolean>(false);

    /**
     * PROJECT SECTION
     */
    const [open, setOpen] = useState<boolean>(false);
    const [project, setProject] = useState<Project>({
        projectID: `newProject`,
        projectTitle: "",
        state: "",
        widgets: { summary: true, planning: true, scope: true, documents: true, contract: true, labels: true },
        tasks: [],
        users: [],
        ca_ids: [],
        tender_ids: [],
        notes: [],
    });

    const changeProject = (change: Partial<Project>) => {
        setProject((state) =>
            state
                ? { ...state, ...change }
                : {
                      projectID: `newProject`,
                      projectTitle: "",
                      state: "",
                      tasks: [],
                      tender_ids: [],
                      users: [],
                      ca_ids: [],
                      notes: [],
                      widgets: {
                          summary: true,
                          planning: false,
                          scope: true,
                          documents: true,
                          contract: true,
                          labels: true,
                      },
                  }
        );
    };

    /**
     * END OF PROJECT SECTION
     */

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const setChatTarget = (newModelId: string, newModelType: string, newChatTitle: string, newConversationIdToOpen: string) => {
        setModelId(newModelId);
        setModelType(newModelType);
        setChatTitle(newChatTitle);
        setConversationIdToOpen(newConversationIdToOpen);
    };

    const setTourVariables = (newSteps: any, newTourColor: string, activeTour: string) => {
        setSteps(newSteps);
        setTourColor(newTourColor);
        setCurrentTour(activeTour);
    };

    const closeTour = () => {
        /**
         * Track if user stops tour earlier
         */
        trackevents({
            category: "Help",
            action: "Clicked-stop-tour",
            documentTitle: currentTour,
            name: `${steps[currentStep].selector}`,
            value: currentStep,
        });
        setOpenTour(false);
        setCurrentStep(0);
        setLockTour(false);
        setSteps([]);
    };

    const contentContainerRef = useRef<HTMLDivElement | null>(null);

    // Use custom hook to check if scroll position exceeds threshold
    const trigger = useScrollPosition(contentContainerRef, 300);

    setValue(trigger);

    return (
        <IncognitoWrapper>
            <ProjectToOpenContext.Provider value={{ project, changeProject, open, setOpen }}>
                <ProfileContext.Provider
                    value={{
                        openProfile,
                        setOpenProfile,
                        initials,
                        givenname,
                        middlename,
                        familyname,
                        org_id,
                        org_name,
                        org_logo,
                        org_favicon,
                        employee_id,
                        email,
                        datafetched,
                        setProfileData,
                        myCAS,
                        myMPs,
                        setMyCas,
                        setMyMPs,
                    }}
                >
                    <TenderSearchProvider>
                        <SearchCustomOptionsContext.Provider value={{ customOptions, setCustomOptions }}>
                            <NotificationContext.Provider value={{ openNotifications, setOpenNotifications }}>
                                <TenderChatContext.Provider
                                    value={{
                                        openModal,
                                        setOpenModal,
                                        chatTitle,
                                        modelId,
                                        modelType,
                                        setChatTarget,
                                        messageSent,
                                        setMessageSent,
                                        conversationIdToOpen,
                                    }}
                                >
                                    <ArchiveContext.Provider
                                        value={{
                                            archive_id,
                                            setArchiveId,
                                            openModalArchive,
                                            setOpenModalArchive,
                                            archive_title,
                                            setArchiveTitle,
                                        }}
                                    >
                                        <FirstSearchRuleContext.Provider
                                            value={{
                                                wordModalTourOpen,
                                                setWordModalTourOpen,
                                                domainModalTourOpen,
                                                setDomainModalTourOpen,
                                                resultModalTourOpen,
                                                setResultModalTourOpen,
                                                distModalTourOpen,
                                                setDistModalTourOpen,
                                            }}
                                        >
                                            <TourContext.Provider
                                                value={{
                                                    setLockTour,
                                                    lockTour,
                                                    openTour,
                                                    setOpenTour,
                                                    steps,
                                                    tourColor,
                                                    currentStep,
                                                    setCurrentStep,
                                                    setTourVariables,
                                                    currentTour,
                                                }}
                                            >
                                                <CuOrganizationContext.Provider value={{ org_id, setOrgId: setOrgid }}>
                                                    <div className="MainLayout">
                                                        <div className="navbar-container">
                                                            <NavBar activePage={page} />
                                                        </div>

                                                        {!noMobile && (
                                                            <div className="mobile-header">
                                                                <MobileHeader
                                                                    activePage={page}
                                                                    openSupport={openSupport}
                                                                    setOpenSupport={setOpenSupport}
                                                                />
                                                            </div>
                                                        )}

                                                        {/* Dont show header on tendenz page */}
                                                        {noMobile && !pathname.includes("tendenz") && !pathname.includes("knowledge-hub") && (
                                                            <div className="header-container">
                                                                <TopHeader>{header}</TopHeader>
                                                            </div>
                                                        )}

                                                        <div
                                                            ref={pathname.includes("tendenz") ? contentContainerRef : undefined}
                                                            className="main-content-container"
                                                            style={{
                                                                backgroundColor: pathname.includes("knowledge-hub") ? "#ffffff" : "#f4f4f4",
                                                                paddingTop: pathname.includes("knowledge-hub")
                                                                    ? "0px"
                                                                    : pathname.includes("projects") && !noMobile
                                                                    ? "0px"
                                                                    : "64px",
                                                            }}
                                                        >
                                                            {children}
                                                        </div>
                                                    </div>

                                                    {/* When a model Id and type is selected by the context, we allow the dialog to render and be openend on trigger */}
                                                    {modelId && modelType && (
                                                        <TenderNoteDialog
                                                            modelId={modelId}
                                                            conversationIdToOpen={conversationIdToOpen}
                                                            modelType={modelType}
                                                            chat_title={chatTitle}
                                                            open={openModal}
                                                            handleClose={() => {
                                                                setConversationIdToOpen("");
                                                                setOpenModal(!openModal);
                                                                setChatTarget("", "", "", "");
                                                            }}
                                                        />
                                                    )}
                                                    <ArchiveDialog
                                                        archive_id={archive_id}
                                                        archive_title={archive_title}
                                                        open={openModalArchive}
                                                        handleClose={() => setOpenModalArchive(!openModalArchive)}
                                                    />

                                                    {/*
                                                     * User can start a tour through the whole application.
                                                     * Tour will be defined in each button that start fire a tour/guide
                                                     * Onclick the following data will be set:
                                                     * - Color of highlight
                                                     * - Steps => 'data-tut' property will be used to define each step with content and/ or action(s) that has to be shown for each step
                                                     * - Disable dotsnavigation & keyboardnavigation
                                                     */}

                                                    {openTour && (
                                                        <Tour
                                                            closeWithMask={false}
                                                            steps={steps}
                                                            disableKeyboardNavigation
                                                            disableDotsNavigation
                                                            // disableDotsNavigation={lockTour}
                                                            disableFocusLock={true}
                                                            disableInteraction={!lockTour}
                                                            onRequestClose={closeTour}
                                                            isOpen={openTour}
                                                            rounded={4}
                                                            accentColor={tourColor}
                                                            onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                                                            onBeforeClose={() => (document.body.style.overflowY = "auto")}
                                                            nextStep={() => setCurrentStep(currentStep + 1)}
                                                            prevStep={() => setCurrentStep(currentStep - 1)}
                                                            goToStep={currentStep}
                                                            showButtons={!lockTour}
                                                            lastStepNextButton={<></>}
                                                        />
                                                    )}
                                                    {open && <ProjectModal />}
                                                </CuOrganizationContext.Provider>
                                            </TourContext.Provider>
                                        </FirstSearchRuleContext.Provider>
                                    </ArchiveContext.Provider>
                                </TenderChatContext.Provider>
                            </NotificationContext.Provider>
                        </SearchCustomOptionsContext.Provider>
                    </TenderSearchProvider>
                </ProfileContext.Provider>
            </ProjectToOpenContext.Provider>
        </IncognitoWrapper>
    );
};

export default MainLayout;
