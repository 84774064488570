import React, { useContext } from "react";
import { Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import TaskDescription from "./taskComponents/TaskDescription";
import TaskDeadline from "./taskComponents/TaskDeadline";
import MyProjectFaseSelector from "../../Dashboard/myProjects/MyProjectFaseSelector";
import TaskUser from "./taskComponents/TaskUser";
import TaskPriority from "./taskComponents/TaskPriority";
import { getTasksWithFilters_filterUserTasks_data } from "../../../__generated__/getTasksWithFilters";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { useNavigate } from "react-router-dom";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import update from "immutability-helper";
import CasTooltip from "../projectModal/task/CasTooltip";

interface Props {
    task: getTasksWithFilters_filterUserTasks_data;
    setData: React.Dispatch<React.SetStateAction<getTasksWithFilters_filterUserTasks_data[]>>;
    refetch: () => void;
}

const SingleTask: React.FC<Props> = ({ task, refetch, setData }) => {
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);

    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    const taskOfCu = Boolean(task.users && task.users.filter((u) => u.id === localStorage.getItem("user_id")).length > 0);

    // Generic handler to update task fields
    const handleTaskChange = <K extends keyof getTasksWithFilters_filterUserTasks_data>(
        updatedTask: getTasksWithFilters_filterUserTasks_data,
        key: K,
        value: getTasksWithFilters_filterUserTasks_data[K]
    ) => {
        setData((prevData) =>
            update(prevData, {
                $apply: (t: getTasksWithFilters_filterUserTasks_data[]) =>
                    t.map((task) =>
                        task.id === updatedTask.id
                            ? { ...task, [key]: value } // Dynamically update the property
                            : task
                    ),
            })
        );
    };

    return (
        <>
            <Paper sx={{ marginBottom: "6px", padding: "4px" }} elevation={0}>
                <Grid container sx={{ backgroundColor: "#ffffff" }} columns={14}>
                    <Grid item xs={14} md={5} sx={{ display: "flex" }}>
                        <TaskDescription taskOfCu={taskOfCu} task={task} onTaskChange={handleTaskChange} />
                    </Grid>
                    <Grid item xs={14} md={1} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "8px" }}>
                        <TaskDeadline taskOfCu={taskOfCu} task={task} onTaskChange={handleTaskChange} />
                    </Grid>

                    <Grid item xs={14} md={6} sx={{ display: "flex", alignItems: "center" }}>
                        {/* Prioritychip  */}
                        <TaskPriority taskOfCu={taskOfCu} task={task} onTaskChange={handleTaskChange} />
                        <CasTooltip project_id={task.project?.id as string} />
                        <Typography
                            noWrap
                            fontWeight={500}
                            sx={{
                                paddingRight: "12px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                if (isMobile) {
                                    navigate(`/projects/${task.project?.id as string}`);
                                    track({
                                        category: "Dashboard",
                                        action: "Click-navigate-to-project-mobile",
                                        href: window.location.href,
                                    });
                                } else {
                                    changeProject({
                                        projectID: task.project?.id as string,
                                        projectTitle: task.project?.title as string,
                                        state: task.project?.state?.id as string,
                                    });
                                    track({
                                        category: "Project",
                                        action: "Click-from-task-to-project",
                                        href: window.location.href,
                                    });
                                    setOpen(true);
                                }
                            }}
                        >
                            {task.project?.title}
                        </Typography>
                    </Grid>

                    <Grid item xs={14} md={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "16px" }}>
                        <div style={{ width: "75%" }}>
                            {task.project && (
                                <MyProjectFaseSelector currentFase={task.project.state?.name ?? ""} projectId={task.project.id} refetch={refetch} />
                            )}
                        </div>
                        <div style={{ width: "25%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <TaskUser task={task} refetch={refetch} />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default SingleTask;
