import React, { useState } from "react";

import FilterDropdownTemplate from "../../../../components/TopFilterBar/FilterDropdownTemplate";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";
import { getTasksWithFilters_filterUserTasks_filters, getTasksWithFiltersVariables } from "../../../../__generated__/getTasksWithFilters";
import update from "immutability-helper";

type NewType = getTasksWithFiltersVariables;

interface Props {
    filterData: getTasksWithFilters_filterUserTasks_filters | null | undefined;
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<NewType>>;
}

const Deadline: React.FC<Props> = ({ filterData, setActiveFilters, activeFilters }) => {
    const { t } = useTranslation();
    const [dateRangeState, setDateRange] = useState<{
        from: Moment | null;
        to: Moment | null;
    }>({ from: null, to: null });

    const deadlineActiveState = Boolean(
        activeFilters.deadline !== undefined &&
            activeFilters.deadline !== null &&
            activeFilters.deadline?.to !== null &&
            activeFilters.deadline?.from !== null
    );

    const labelForTemplate: React.ReactNode = deadlineActiveState ? <strong>Deadline</strong> : "Deadline";

    return (
        <FilterDropdownTemplate
            // TODO: add dynamic disable value
            disabled={false}
            label={labelForTemplate}
            amountSelectedItems={deadlineActiveState ? 1 : 0}
            allowReset={dateRangeState.from !== null || dateRangeState.to !== null}
            onReset={() => {
                setActiveFilters(update(activeFilters, { $unset: ["deadline"] }));
                setDateRange({ from: null, to: null });
            }}
            onSave={() => {
                setActiveFilters(
                    update(activeFilters, {
                        deadline: { $set: { from: dateRangeState.from?.format("YYYY-MM-DD"), to: dateRangeState.to?.format("YYYY-MM-DD") } },
                    })
                );
            }}
        >
            <div style={{ maxWidth: 400, padding: 12 }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.From")}
                            value={dateRangeState.from}
                            onChange={(date) => setDateRange({ from: date ? date : null, to: dateRangeState.to })}
                            // Clone endDate and subtract 1 day to prevent selecting the same day
                            maxDate={dateRangeState.to ? dateRangeState.to.clone() : undefined}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <DatePicker
                            slotProps={{ textField: { variant: "standard" } }}
                            label={t("filter.To")}
                            // autoOk={true}
                            value={dateRangeState.to}
                            onChange={(date) => {
                                setDateRange({ to: date ? date : null, from: dateRangeState.from });
                            }}
                            // {...sharedProps}
                            // Clone startDate and add 1 day to prevent selecting the same day
                            minDate={dateRangeState.from ? dateRangeState.from.clone() : undefined}
                        />
                    </Grid>
                </Grid>
            </div>
        </FilterDropdownTemplate>
    );
};

export default Deadline;
