import React, { useState } from "react";
import { Box, IconButton, InputAdornment, ListItemButton, ListItemText, Popover, TextField, Tooltip } from "@mui/material";
import { LibraryBooks, Search } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GET_TASK_TEMPLATES } from "../../../../graphql/queryDefTasks";
import { taskTemplates, taskTemplates_templates } from "../../../../__generated__/taskTemplates";
import { Priority } from "../../../../__generated__/globalTypes";
import { Task } from "./Tasks";

interface Props {
    projectId: string;
    setTemporaryTasks: React.Dispatch<React.SetStateAction<Task[]>>;
}

const AddTemplate: React.FC<Props> = ({ projectId, setTemporaryTasks }) => {
    const [openShare, setOpenShare] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { data } = useQuery<taskTemplates>(GET_TASK_TEMPLATES);

    const handleToggleTemplate = (item: taskTemplates_templates) => {
        const createTaskArray = item.tasks.map((task) => {
            return {
                id: task.id,
                task_description: task.taskInfo.task_description,
                task_priority: task.task_priority?.toUpperCase() as Priority,
                task_deadline: null,
                task_completed: false,
                users: null,
            };
        });

        setTemporaryTasks(createTaskArray);
        setAnchorEl(null);
        setOpenShare(false);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenShare(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenShare(false);
    };

    const results = !searchTerm
        ? data?.templates
        : data?.templates?.filter((item) => item.template_name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <>
            <Tooltip title="Sjabloon toepassen" placement="left" disableInteractive>
                <IconButton disabled={data?.templates?.length === 0} onClick={handleClickSub} size="small">
                    <LibraryBooks fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                open={openShare}
                onClose={handleCloseSub}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
                // PaperProps={{
                //     style: { width: "300px" },
                // }}
            >
                <div>
                    {/*
                     * Searchbox
                     */}
                    <Box style={{ width: "100%", display: "flex" }}>
                        <TextField
                            onClick={(e) => e.stopPropagation()}
                            sx={{ margin: "10px", marginBottom: "0px", flexGrow: 1 }}
                            placeholder="Zoek een sjabloon..."
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                                e.stopPropagation();
                                setSearchTerm(e.target.value);
                            }}
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <Box>
                        {results?.map((item) => {
                            return (
                                <ListItemButton
                                    dense
                                    key={item.id}
                                    sx={{ pl: 3, display: "flex", alignItems: "center" }}
                                    alignItems="flex-start"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleToggleTemplate(item);
                                    }}
                                >
                                    <ListItemText primary={item.template_name} />
                                </ListItemButton>
                            );
                        })}
                    </Box>
                </div>
            </Popover>
        </>
    );
};

export default AddTemplate;
