import { AccountTree } from "@mui/icons-material";
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Skeleton } from "@mui/material";
import React, { useContext } from "react";
import TooltipOnNewLines from "../TooltipOnNewLines";
import { ProjectToOpenContext } from "../layout/MainLayout";
import { useQuery } from "@apollo/client";
import { SINGLE_COMMENT_NOTIFICATION, SINGLE_REPLY_NOTIFICATION } from "../../graphql/queryDefProject";
import { GetNotificationsCurrentUser_currentUser_notifications_data } from "../../__generated__/GetNotificationsCurrentUser";
import { single_comment_notification, single_comment_notificationVariables } from "../../__generated__/single_comment_notification";
import { useTranslation } from "react-i18next";
import { parseComment } from "../../hooks/commentParser";
import { single_reply_notification, single_reply_notificationVariables } from "../../__generated__/single_reply_notification";

interface Props {
    notification: GetNotificationsCurrentUser_currentUser_notifications_data;
    onClick(e: string): void;
}

const MentionedInProject: React.FC<Props> = ({ notification, onClick }) => {
    const grey = "#707070";
    const { setOpen, changeProject } = useContext(ProjectToOpenContext);
    const { t } = useTranslation();

    const { data, loading } = useQuery<single_comment_notification, single_comment_notificationVariables>(SINGLE_COMMENT_NOTIFICATION, {
        skip: Boolean(notification.data && (notification.data[1] as string) === "reply"),
        variables: {
            id: notification.data ? (notification.data[0] as string) : "",
        },
        fetchPolicy: "network-only",
    });

    const { data: replyData, loading: replyLoading } = useQuery<single_reply_notification, single_reply_notificationVariables>(
        SINGLE_REPLY_NOTIFICATION,
        {
            skip: Boolean(notification.data && (notification.data[1] as string) !== "reply"),
            variables: {
                id: notification.data ? (notification.data[0] as string) : "",
            },
            fetchPolicy: "network-only",
        }
    );

    const inProjectOnComment = Boolean(data?.comment?.project?.activeUsers?.find((i) => i.id === localStorage.getItem("user_id")));
    const inProjectOnReply = Boolean(replyData?.reply?.project?.activeUsers?.find((i) => i.id === localStorage.getItem("user_id")));

    /**
     * Return null when no data is available
     */
    if (notification === null || notification.data === null) return null;

    /**
     * Spinner on loading state
     */
    if (loading || replyLoading) {
        return (
            <MenuItem
                style={{
                    whiteSpace: "normal",
                    maxHeight: 250,
                    opacity: notification.read_at !== null ? 0.5 : undefined,
                }}
            >
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>

                <ListItemText primary={<Skeleton variant="text" width={"100%"} />} secondary={<Skeleton variant="text" width={"75%"} />} />
            </MenuItem>
        );
    }

    const type = notification.data[1];

    /**
     * Check if reply is deleted
     * => show deleted message instead of tranlsationkey
     * => use commentparse to show mentioned user(s)
     */
    const commentText = !inProjectOnComment
        ? "Project niet meer beschikbaar"
        : data && data.comment && data.comment.comment === "valIsDeleted"
        ? `${t("valIsDeleted")} ${data.comment.user.employee.name}`
        : data && data.comment && data.comment.comment
        ? parseComment(data.comment.comment)
        : "";

    const replyText = !inProjectOnReply
        ? "Project niet meer beschikbaar"
        : replyData && replyData.reply && replyData.reply.comment === "valIsDeleted"
        ? `${t("valIsDeleted")} ${replyData.reply.user.employee.name}`
        : replyData && replyData.reply && replyData.reply.comment
        ? parseComment(replyData.reply.comment)
        : "";

    const isReply = notification.data && (notification.data[1] as string) === "reply";
    const isComment = notification.data && (notification.data[1] as string) === "comment";

    // Check falsy conditions only for their respective types
    const falsyReply = Boolean(isReply && (!inProjectOnReply || replyData?.reply?.comment === "valIsDeleted"));
    const falsyComment = Boolean(isComment && (!inProjectOnComment || data?.comment?.comment === "valIsDeleted"));

    return (
        <MenuItem
            onClick={() => {
                if (notification.data) {
                    onClick(notification.data[0] as string);
                    if (
                        type === "reply" &&
                        replyData !== undefined &&
                        replyData.reply !== null &&
                        replyData.reply.project !== null &&
                        inProjectOnReply
                    ) {
                        changeProject({ projectID: replyData.reply.project.id, projectTitle: replyData.reply.project.title });
                        setOpen(true);
                    } else {
                        if (data && data.comment && data.comment.project && inProjectOnComment) {
                            changeProject({ projectID: data.comment.project.id, projectTitle: data.comment.project.title });
                            setOpen(true);
                        }
                    }
                }
            }}
            style={{
                whiteSpace: "normal",
                maxHeight: 250,
                opacity: notification.read_at !== null ? 0.5 : undefined,
                position: "relative",
                padding: "8px 16px",
            }}
        >
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: grey }}>
                    <AccountTree fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <div
                        style={{
                            textDecoration: falsyReply ? "line-through" : falsyComment ? "line-through" : "none",
                        }}
                    >
                        <TooltipOnNewLines
                            showTooltip={false}
                            dontSanitize={true}
                            placement={"bottom-start"}
                            text={
                                type === "true"
                                    ? ` ${
                                          data && data.comment && data.comment.project
                                              ? `Genoemd in notitie bij project: ${data.comment.project.title}`
                                              : `Project verwijderd`
                                      }`
                                    : type === "false"
                                    ? `${
                                          data && data.comment && data.comment.project !== null
                                              ? `Nieuwe notitie bij project: ${data.comment.project.title}`
                                              : "Project verwijderd"
                                      }`
                                    : type === "reply"
                                    ? `${
                                          replyData && replyData.reply && replyData.reply.project !== null
                                              ? `Genoemd in reactie bij project: ${replyData.reply.project.title}`
                                              : "Project verwijderd"
                                      }`
                                    : ""
                            }
                            variant={"body1"}
                            lines={1}
                            id={`T${notification.id as string}first`}
                            fontweight={notification.read_at === null ? 500 : "normal"}
                            fontSize={14}
                        />
                    </div>
                }
                secondary={
                    <TooltipOnNewLines
                        showTooltip={false}
                        placement={"top-start"}
                        text={type === "true" ? `${commentText}` : type === "false" ? `${commentText}` : type === "reply" ? `${replyText}` : ""}
                        variant={"body1"}
                        lines={1}
                        id={`T${notification.id as string}second`}
                        fontweight={"normal"}
                        fontSize={12}
                    />
                }
            />
        </MenuItem>
    );
};

export default MentionedInProject;
