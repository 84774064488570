import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

// Components used
import TopbarCalendar from "./TopbarCalendar";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import DayView from "./DayView";
import CalendarMobile from "./CalendarMobile";

import moment, { Moment } from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import setTitle from "../../utils/setTitle";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
    FilterCalendarDataItems_filterTenders_results_data_looptijd_start,
    FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde,
} from "../../__generated__/FilterCalendarDataItems";
import { GetIcalUser } from "../../__generated__/GetIcalUser";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { toast } from "react-toastify";
import { QUERY_ICAL_LINK, QUERY_USER_AND_TENDER_EVENTS } from "../../graphql/queries/calendarQueries";
import { SavedTenderEvents, SavedTenderEventsVariables } from "../../__generated__/SavedTenderEvents";

interface Props {
    a?: number;
}

export interface Item {
    id: string;
    event: string;
    startDate: (FilterCalendarDataItems_filterTenders_results_data_looptijd_start | null)[] | null;
    endDate: (FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde | null)[] | null;
    deadline: string;
    type: string;
}

export interface EventItem {
    id: string;
    event: string;
    startDate: (FilterCalendarDataItems_filterTenders_results_data_looptijd_start | null)[] | null;
    endDate: (FilterCalendarDataItems_filterTenders_results_data_next_looptijd_einde | null)[] | null;
    deadline: string;
    type: string;
}

const getFullMonthViewRange = (year: number = moment().year(), month: number = moment().month()): { start: string; end: string } => {
    // First day of the month
    const firstDayOfMonth: Moment = moment([year, month, 1]);
    // Last day of the month
    const lastDayOfMonth: Moment = moment(firstDayOfMonth).endOf("month");

    // Start of the week (Monday) for the first day
    const startOfWeek: Moment = moment(firstDayOfMonth).startOf("week"); // Adjust for Monday
    // End of the week (Sunday) for the last day
    const endOfWeek: Moment = moment(lastDayOfMonth).endOf("week"); // Adjust for Monday

    // Format the dates as YYYY-MM-DD
    return {
        start: startOfWeek.format("YYYY-MM-DD"),
        end: endOfWeek.format("YYYY-MM-DD"),
    };
};

const CalendarModule: React.FC<Props> = (props) => {
    const theme = useTheme();
    const { trackEvent } = useMatomo();
    const { t } = useTranslation();
    const today = moment().format();

    const [currentMonth, setCurrentMonth] = useState<string>(today);
    const [currentDate, setCurrentDate] = useState(moment()); // State holds the current month

    const [selectedDate] = useState<string>("");
    const [view, setView] = useState<number>(0);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const monthStart = moment(currentMonth).startOf("month").format();
    const monthEnd = moment(currentMonth).endOf("month").format();
    const startDate = moment(monthStart).startOf("week").format("YYYY-MM-DD");
    const endDate = moment(monthEnd).endOf("week").format("YYYY-MM-DD");
    const [searchInput, setSearchInput] = useState({ from: startDate, till: endDate });
    const [icalLink, setIcalLink] = useState<string | null>(null);

    /**
     * Track event Ical click
     */
    const trackIcalClick = () => {
        trackEvent({ category: "Calendar", action: "clicked-Ical" });
    };

    /**
     * set title
     */
    useEffect(() => {
        setTitle(t("agenda"));
    }, [t]);

    /**
     * Define the GQL query to fetch ical link
     */
    useQuery<GetIcalUser>(QUERY_ICAL_LINK, {
        fetchPolicy: "network-only", // Used for first execution
        onCompleted: (data) => {
            if (data.currentUser.ical_link) {
                setIcalLink(data.currentUser.ical_link);
            }
        },
    });

    /**
     * Define the GQL query
     */
    const [runQuery, { loading: loadingQuery, data: Querydata }] = useLazyQuery<SavedTenderEvents, SavedTenderEventsVariables>(
        QUERY_USER_AND_TENDER_EVENTS,
        {
            variables: searchInput,
            fetchPolicy: "network-only", // Used for first execution
        }
    );

    /**
     * @param period go to next month/week/day based on view
     */
    const nextHandler = (period: "month" | "week" | "day") => {
        const getRange = (unit: any, amount: any) => {
            const nextPeriod = moment(currentMonth).add(amount, unit).format();

            const start = moment(nextPeriod).startOf(unit).startOf("week").format("YYYY-MM-DD");
            const end = moment(nextPeriod).endOf(unit).endOf("week").format("YYYY-MM-DD");

            return { start, end };
        };

        let range;
        switch (period) {
            case "month":
                // setCurrentMonth(moment(currentMonth).add(1, "months").format());
                // range = getRange("month", 1);
                // range = getFullMonthViewRange(moment(currentMonth).add(1, "month").year(), moment(currentMonth).add(1, "month").month());
                ///////
                setCurrentMonth(moment(currentMonth).add(1, "months").format());
                setCurrentDate(currentDate.clone().add(1, "month")); // Move to the next month
                range = getFullMonthViewRange(moment(currentMonth).add(1, "month").year(), moment(currentMonth).add(1, "month").month());
                break;

            case "week":
                setCurrentMonth(moment(currentMonth).add(1, "weeks").format());
                setCurrentDate(currentDate.clone().add(1, "weeks")); // Move to the next month

                range = getRange("week", 1);
                break;

            case "day":
                setCurrentMonth(moment(currentMonth).add(1, "days").format());
                setCurrentDate(currentDate.clone().add(1, "days")); // Move to the next month
                const dayStart = moment(currentMonth).subtract(1, "days").startOf("day").format("YYYY-MM-DD");
                const dayEnd = moment(currentMonth).endOf("day").add(1, "days").format("YYYY-MM-DD");
                range = { start: dayStart, end: dayEnd };
                break;

            default:
                console.error("Invalid period type");
                return;
        }

        setSearchInput({
            from: range.start,
            till: range.end,
        });
    };

    /**
     * @param period go to previous month/week/day based on view
     */
    const prevHandler = (period: "month" | "week" | "day") => {
        const getRange = (unit: any, amount: any) => {
            const prevPeriod = moment(currentMonth).subtract(amount, unit).format();

            const start = moment(prevPeriod).startOf(unit).startOf("week").format("YYYY-MM-DD");
            const end = moment(prevPeriod).endOf(unit).endOf("week").format("YYYY-MM-DD");

            return { start, end };
        };

        let range;
        switch (period) {
            case "month":
                setCurrentMonth(moment(currentMonth).subtract(1, "months").format());
                setCurrentDate(currentDate.clone().subtract(1, "month")); // Move to the next month
                range = getFullMonthViewRange(moment(currentMonth).subtract(1, "month").year(), moment(currentMonth).subtract(1, "month").month());

                // range = getRange("month", 1);
                break;

            case "week":
                setCurrentMonth(moment(currentMonth).subtract(1, "weeks").format());
                setCurrentDate(currentDate.clone().subtract(1, "weeks")); // Move to the next month
                range = getRange("week", 1);
                break;

            case "day":
                setCurrentMonth(moment(currentMonth).subtract(1, "days").format());
                setCurrentDate(currentDate.clone().subtract(1, "days")); // Move to the next month

                const dayStart = moment(currentMonth).subtract(2, "days").startOf("day").format("YYYY-MM-DD");
                const dayEnd = moment(currentMonth).endOf("day").format("YYYY-MM-DD");
                range = { start: dayStart, end: dayEnd };
                break;

            default:
                console.error("Invalid period type");
                return;
        }

        setSearchInput({
            from: range.start,
            till: range.end,
        });
    };

    /**
     * Go to today, same in all views
     */
    const setToday = () => {
        setCurrentMonth(moment().format());
        setCurrentDate(moment()); // Move to the next month

        const { start, end } = getFullMonthViewRange();

        setSearchInput({
            from: start,
            till: end,
        });
    };

    /**
     * Change to clicked view
     * @param view
     */
    const onSetView = (view: number) => {
        setView(view);

        const calculateRange = (unit: any, amount: any) => {
            const prev = moment(currentMonth).subtract(amount, unit).startOf(unit).format("YYYY-MM-DD");
            const next = moment(currentMonth).add(amount, unit).endOf(unit).format("YYYY-MM-DD");
            return { from: prev, till: next };
        };

        let range;
        switch (view) {
            case 0: // Month view
                const { start, end } = getFullMonthViewRange();
                // range = calculateRange("month", 1); // "month" is a valid unit
                range = { from: start, till: end };
                break;

            case 1: // Week view
                range = calculateRange("week", 1); // "week" is a valid unit
                break;

            case 2: // Day view
                const prevDay = moment(currentMonth).subtract(1, "days").startOf("day").format("YYYY-MM-DD");
                const nextDay = moment(currentMonth).add(1, "days").endOf("day").add(1, "days").format("YYYY-MM-DD");
                setCurrentMonth(moment(currentMonth).add(1, "days").format());
                range = { from: prevDay, till: nextDay };
                break;

            default:
                console.error("Invalid view type");
                return;
        }

        setSearchInput(range);
    };

    /**
     * Handler to copy calendar
     */
    const copyCal = () => {
        if (icalLink !== null) {
            navigator.clipboard.writeText(icalLink);
            toast.success("Agenda link gekopieerd", { autoClose: 1500 });
        }
        trackIcalClick();
    };

    /**
     * Set page title
     */
    useEffect(() => {
        runQuery({ variables: { from: searchInput.from, till: searchInput.till } });
    }, [t, runQuery, searchInput]);

    // customized TODO: connect to knowledgebase id
    // const action = (key: any) => (
    //     <React.Fragment>
    //         <Button
    //             style={{ color: "#fff" }}
    //             onClick={() => {
    //                 alert(`Deze knop opent FAQ met item over ical invoegen in agenda applicatie`);
    //             }}
    //         >
    //             Info
    //         </Button>
    //     </React.Fragment>
    // );

    /**
     * Mobile version of calendar
     */
    if (isMobile) {
        return (
            <React.Fragment>
                <CalendarMobile
                    loading={loadingQuery}
                    currentMonth={currentMonth}
                    setToday={setToday}
                    view={view}
                    setView={onSetView}
                    copyCal={copyCal}
                    selectedDate={selectedDate}
                    data={Querydata?.getCalendarEvents || []}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                />
            </React.Fragment>
        );
    }

    /**
     * @param view month | week | day
     * @returns Shows view user has chosen to view
     */
    const renderSwitch = (view: number) => {
        switch (view) {
            case 0:
                return <MonthView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
            case 1:
                return <WeekView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
            default:
                return <DayView currentMonth={currentMonth} selectedDate={selectedDate} data={Querydata?.getCalendarEvents || []} />;
        }
    };
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <TopbarCalendar
                    loading={loadingQuery}
                    currentMonth={currentMonth}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    setToday={setToday}
                    view={view}
                    setView={onSetView}
                    copyCal={copyCal}
                />
                {/* Render chosen view */}
                {renderSwitch(view)}
            </div>
        </React.Fragment>
    );
};

export default CalendarModule;
