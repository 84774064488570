import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { deleteTemplate, deleteTemplateVariables } from "../../../../__generated__/deleteTemplate";
import { DELETE_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

interface Props {
    id: string;
    refetch: () => void;
}

const DeleteTemplate: React.FC<Props> = ({ id, refetch }) => {
    // Explain mutation
    const [mutate, { loading, error }] = useMutation<deleteTemplate, deleteTemplateVariables>(DELETE_TEMPLATE);

    // Explain mutation handler
    const deleteTemplate = async () => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await mutate({
                variables: { id: id },
                onCompleted: (data) => {
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <Tooltip title="Verwijder sjabloon" disableInteractive placement="top">
            <IconButton
                disabled={loading}
                size="small"
                sx={{ color: "#c0392b" }}
                onClick={(e) => {
                    e.stopPropagation();
                    deleteTemplate();
                }}
            >
                <Clear fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default DeleteTemplate;
