import React from "react";
import { CircularProgress, Divider, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import { Add, Clear, LibraryAdd, Search } from "@mui/icons-material";
import { GetWordArray_currentUser_keywords } from "../../../__generated__/GetWordArray";
import { organizationKeywords_organizationKeywords } from "../../../__generated__/organizationKeywords";
import { useProfile } from "../../../components/contextProviders/ProfileOpenerContext";

interface Props {
    query: string;
    handleSearchChange: (e: any) => void;
    addWordToBucket: (w: string) => void;
    addWordToORGBucket: (w: string) => void;
    isSubAdmin: boolean;
    reset: () => void;
    disabled: boolean;
    currentView: "org" | "my";
    myWords: GetWordArray_currentUser_keywords[];
    orgWords: organizationKeywords_organizationKeywords[];
    loadingAdd: boolean;
}

const Searchbar: React.FC<Props> = ({
    query,
    disabled,
    handleSearchChange,
    addWordToBucket,
    addWordToORGBucket,
    reset,
    currentView,
    myWords,
    orgWords,
    isSubAdmin,
    loadingAdd,
}) => {
    const { org_name } = useProfile();
    const existsInMyWords = !query ? myWords : myWords.filter((item) => item.keyword.toLowerCase() === query.toLowerCase());
    const existsInOrgWords = !query ? orgWords : orgWords.filter((item) => item.keyword?.toLowerCase() === query.toLowerCase());

    return (
        <div data-tut="reactour__addWord" style={{ display: "flex", alignItems: "center", padding: "16px 16px 0px 16px" }}>
            <Paper
                data-tut="reactour__addWordField"
                square
                component="form"
                sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}
            >
                {/* Searchicon */}
                <Search style={{ margin: "10px 0px 10px 6px", color: "#707070" }} />

                {/* Inputfield query */}
                <InputBase
                    sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                    placeholder={"Woord zoeken/toevoegen..."}
                    // autoFocus={onlyMobile}
                    value={query} // Controlled field
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                        if (!disabled) {
                            if (currentView === "my" && e.key === "Enter") {
                                // add to users wordlist
                                addWordToBucket(query);
                            } else if (currentView === "org" && e.key === "Enter") {
                                // add to organisation wordlist
                                addWordToORGBucket(query);
                            }
                        }
                    }}
                />
                {loadingAdd && <CircularProgress color="inherit" size={14} sx={{ marginRight: "8px" }} />}

                {/* admin button */}
                {query.length >= 3 && isSubAdmin && (
                    <Tooltip placement="right" title={`Toevoegen aan ${org_name} zoekwoorden`} disableInteractive>
                        <IconButton
                            type="submit"
                            id="add_searchterm_btn"
                            data-tut="add_searchterm_btn"
                            disabled={existsInOrgWords.length !== 0 || disabled}
                            color="primary"
                            aria-label="directions"
                            onClick={(e) => {
                                e.stopPropagation();
                                addWordToORGBucket(query);
                            }}
                        >
                            <LibraryAdd fontSize="small" sx={{ color: existsInOrgWords.length !== 0 ? "#b3b3b3" : "primary.main" }} />
                        </IconButton>
                    </Tooltip>
                )}
                {query.length >= 3 && (
                    <Tooltip placement="right" title="Zoekwoord toevoegen" disableInteractive>
                        <IconButton
                            type="submit"
                            id="add_searchterm_btn"
                            data-tut="add_searchterm_btn"
                            disabled={existsInMyWords.length !== 0 || disabled}
                            color="primary"
                            size="small"
                            aria-label="directions"
                            onClick={(e) => {
                                e.stopPropagation();
                                addWordToBucket(query);
                            }}
                        >
                            <Add sx={{ color: existsInMyWords.length !== 0 ? "#b3b3b3" : "primary.main" }} />
                        </IconButton>
                    </Tooltip>
                )}
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {/*
                 * RESET BUTTON
                 */}
                <Tooltip placement="right" title="Zoekwoord verwijderen" disableInteractive>
                    <IconButton
                        // disabled={activeFilters.search === ""}
                        color="primary"
                        size="small"
                        aria-label="directions"
                        onClick={(e) => {
                            e.stopPropagation();
                            reset();
                        }}
                    >
                        <Clear sx={{ color: query === "" ? "#b3b3b3" : "error.main" }} />
                    </IconButton>
                </Tooltip>
            </Paper>
        </div>
    );
};

export default Searchbar;
