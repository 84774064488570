import React from "react";
import { Condition, ConditionGroup, LogicalOperator } from "./QueryBuilderContext";
import BuilderBlock from "./BuilderBlock";

interface Props {
    condition: Condition;
    logicalOperators: LogicalOperator[];
    groupId: string;
    group: ConditionGroup;
    handleNearCondition: (groupId: string, conditionId: string) => void;
    updateConditionOperator: (groupId: string, conditionId: string, newOperator?: LogicalOperator, secondOperator?: LogicalOperator) => void;
    updateConditionKeyword: (groupId: string, conditionId: string, newKeyword: string) => void;
    handleNearTermChange: (groupId: string, conditionId: string, newNearTerm: string) => void;
    handleProximityChange: (groupId: string, conditionId: string, newProximity: number | undefined) => void;
    removeCondition: (groupId: string, conditionId: string) => void;
    handleRemoveNearTerm: (groupId: string, conditionId: string) => void;
    isLastConditionAndNotActive: boolean;
    highlightedConditionId: string | null;
    activeGroup: string;
    nextKeyword: Condition | undefined;
    isFirstCondition: boolean;
    themeColor: string;
}

const QueryBuilderSearchTermBox: React.FC<Props> = ({
    group,
    groupId,
    condition,
    themeColor,
    activeGroup,
    nextKeyword,
    isFirstCondition,
    logicalOperators,
    highlightedConditionId,
    isLastConditionAndNotActive,
    updateConditionOperator,
    updateConditionKeyword,
    handleProximityChange,
    handleRemoveNearTerm,
    handleNearTermChange,
    handleNearCondition,
    removeCondition,
}) => {
    return (
        <BuilderBlock
            group={group}
            groupId={groupId}
            condition={condition}
            themeColor={themeColor}
            activeGroup={activeGroup}
            nextKeyword={nextKeyword}
            logicalOperators={logicalOperators}
            isFirstCondition={isFirstCondition}
            highlightedConditionId={highlightedConditionId}
            isLastConditionAndNotActive={isLastConditionAndNotActive}
            removeCondition={removeCondition}
            handleNearCondition={handleNearCondition}
            handleNearTermChange={handleNearTermChange}
            handleProximityChange={handleProximityChange}
            updateConditionKeyword={updateConditionKeyword}
            updateConditionOperator={updateConditionOperator}
            handleRemoveNearTerm={handleRemoveNearTerm}
        />
    );
};

export default QueryBuilderSearchTermBox;
