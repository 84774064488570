import React, { useState } from "react";
import { Typography, Button, List, MenuList, ListItemButton } from "@mui/material";
import DocFragment from "../../../components/opportunityDetail/DocFragment";
import { useQuery } from "@apollo/client";
import RenderPage from "../../../components/loading/RenderPage";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import Div404 from "../../Div404";
import { GetOppDetailPage, GetOppDetailPageVariables } from "../../../__generated__/GetOppDetailPage";
import { useTranslation } from "react-i18next";
import NewOppOnpageSelect from "../../../components/opportunityDetail/NewOppOnPageSelect";
import { useOppOpener } from "../../../components/contextProviders/CreateNewOpportunityContext";
import OppDetailFilter from "./OppDetailFilter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { QUERY_DETAILPAGE_OF_OPPORTUNITY } from "../../../graphql/queryDefinitions";

interface Props {
    active: number | false;
    setActive: (active: number) => void;
    opp_id: number;
    pdfUrl: string | null;
    onClose: () => void;
    page_id: string;
    onOpenSearchRuleWizard: () => void;
    year_from: number | null;
}
// const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));

export interface ChosenPage {
    page: number;
    id: string;
}
const OpportunityResultList: React.FC<Props> = ({ opp_id, active, setActive, pdfUrl, onClose, page_id, onOpenSearchRuleWizard, year_from }) => {
    const { t } = useTranslation();
    const { newOpp } = useOppOpener();
    const [viewSet, setViewSet] = useState<number>(1);

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // viewset 2 = only pages with read = true
    const vars = viewSet === 2 ? { file_id: opp_id, first: 25, page: 1, status: true } : { file_id: opp_id, first: 25, page: 1 };

    const { loading, error, data } = useQuery<GetOppDetailPage, GetOppDetailPageVariables>(QUERY_DETAILPAGE_OF_OPPORTUNITY, {
        variables: vars,
        fetchPolicy: "network-only",
    });

    const { data: dataCount } = useQuery<GetOppDetailPage, GetOppDetailPageVariables>(QUERY_DETAILPAGE_OF_OPPORTUNITY, {
        variables: { file_id: opp_id, first: 25, page: 1 },
        fetchPolicy: "network-only",
    });

    if (loading) {
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    if (error || !data?.opportunityFilePersonalPages?.data || !dataCount?.opportunityFilePersonalPages?.data) {
        return (
            <RenderPage>
                <Div404 />
            </RenderPage>
        );
    }
    const datalength = dataCount.opportunityFilePersonalPages.paginatorInfo.total;
    const opps = data.opportunityFilePersonalPages.data;
    const opportunities = [...opps];

    return (
        <React.Fragment>
            <div
                style={{
                    marginBottom: -2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {opportunities.length === 0 ? (
                    // No matches with searchrule(s)
                    <Typography style={{ fontSize: "0.9rem" }}>{t("no_match_found")}</Typography>
                ) : opportunities.length === 1 ? (
                    // 1 match found
                    <Typography style={{ fontSize: "0.9rem" }}>
                        {opportunities.length} {t("page_found")}
                    </Typography>
                ) : (
                    // multiple matches found
                    <Typography style={{ fontSize: "0.9rem" }}>
                        {opportunities.length} {t("pages_found")}
                    </Typography>
                )}

                {/*
                 * Button to open wizard to create opportunity searchrule
                 */}
                {datalength === 0 && (
                    <Button
                        size="small"
                        onClick={() => {
                            onOpenSearchRuleWizard();
                            trackevents({ category: "Opportunity", action: "Click-create-searchrule-from-opportunity-detail" });
                        }}
                        variant="text"
                        color="primary"
                    >
                        Maak zoekregel
                    </Button>
                )}

                {datalength > 0 && (
                    <OppDetailFilter label={`Pagina's`} disabled={false}>
                        <MenuList style={{ padding: 4 }}>
                            <List dense style={{ padding: 0, width: 140 }}>
                                <ListItemButton
                                    style={{ height: 32 }}
                                    onClick={() => {
                                        setViewSet(1);
                                    }}
                                >
                                    {viewSet === 1 ? <strong>Alle</strong> : <span>Alle</span>}
                                </ListItemButton>
                                <ListItemButton
                                    style={{ height: 32 }}
                                    onClick={() => {
                                        setViewSet(2);
                                    }}
                                >
                                    {viewSet === 2 ? <strong>Beoordeeld</strong> : <span>Beoordeeld</span>}
                                </ListItemButton>
                                <ListItemButton
                                    style={{ height: 32 }}
                                    onClick={() => {
                                        setViewSet(3);
                                    }}
                                >
                                    {viewSet === 3 ? <strong>Niet beoordeeld</strong> : <span>Niet beoordeeld</span>}
                                </ListItemButton>
                            </List>
                        </MenuList>
                    </OppDetailFilter>
                )}

                {/*
                 *  Filter with searchrules with results in this document
                 */}
                {/* <FilterDropdown
                    disabled={false}
                    label={t("Searchrule")}
                    values={searchRule}
                    onChange={setSearchRule}
                    options={[{ key: "red", label: "zoekregel a", id: 1 }]}
                /> */}
                {/* <div style={{ padding: "4px 5px", marginRight: 8, marginBottom: 8, height: 30 }} /> */}
            </div>

            {/*
             *   id 'result' needed to replace tagname 'tenderguide' for highlighting
             */}
            <div id="list-of-results" style={{ height: "94vh", overflow: "scroll" }}>
                {newOpp === true && !opportunities.find((opp: any) => opp.page === (active as number)) && (
                    <NewOppOnpageSelect active={active as number} page_id={page_id} onClose={onClose} year_from={year_from} />
                )}
                {viewSet === 3
                    ? opportunities
                          .filter((opp) => opp.status !== true)
                          .sort(function (a: any, b: any) {
                              return a?.page - b?.page;
                          })
                          .map((opp, i) => {
                              return (
                                  <div key={opp.id} id={`${opp.page}`}>
                                      <DocFragment
                                          year_from={year_from}
                                          read={opp.status}
                                          pdfUrl={pdfUrl}
                                          active={active}
                                          data={opp}
                                          onPageClick={() => {
                                              setActive(opp.page as number);
                                          }}
                                      />
                                  </div>
                              );
                          })
                    : opportunities
                          .sort(function (a: any, b: any) {
                              return a?.page - b?.page;
                          })
                          .map((opp, i) => {
                              return (
                                  <div key={opp.id} id={`${opp.page}`}>
                                      <DocFragment
                                          year_from={year_from}
                                          read={opp.status}
                                          pdfUrl={pdfUrl}
                                          active={active}
                                          data={opp}
                                          onPageClick={() => {
                                              setActive(opp.page as number);
                                          }}
                                      />
                                  </div>
                              );
                          })}
            </div>
        </React.Fragment>
    );
};

export default OpportunityResultList;
