import React from "react";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";

import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from "mui-datatables";
import {
    GetSettlingsDetails_tenderer_settlings_data,
    GetSettlingsDetails_tenderer_settlings_paginatorInfo,
} from "../../../../__generated__/GetSettlingsDetails";
import Paper from "@mui/material/Paper";
import CountryFlagBox from "../../../../components/boxes/CountryFlagBox";
import StatusDotOrg from "../../../../components/StatusDotOrg";
import { Grid, MenuItem, Select } from "@mui/material";
import { Pagination } from "@mui/lab";
import { useTranslation } from "react-i18next";
import LinearLoader from "../../../../components/loading/LinearLoader";
import { Disable } from "react-disable";

interface Props {
    rows: GetSettlingsDetails_tenderer_settlings_data[] | undefined;
    /**
     * change in show/hide columns
     */
    // onTableChange: MUIDataTableOptions["onTableChange"];
    /**
     * Pagination info
     */
    paginatorInfo: GetSettlingsDetails_tenderer_settlings_paginatorInfo;
    /**
     * onChange page or rows per page
     */
    onChangePagination(page: number, rowsPerPage: number): void;
    loading: boolean;
}

//Hide toolbar in table
const getMuiTheme = (theme: Theme) =>
    createTheme({
        palette: {
            primary: {
                main: "#E03660",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro, system-ui, sans-serif",
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: "none",
                    },
                },
            },
        },
    });

const columns: MUIDataTableColumnDef[] = [
    {
        label: "id",
        name: "id",
        options: { display: "excluded" },
    },
    {
        label: "country",
        name: "country",
        options: { display: "excluded" },
    },
    {
        label: "helper",
        name: "helper",
        options: { display: "excluded" },
    },
    {
        label: "Type",
        name: "type",
    },
    {
        label: "Adres",
        name: "address",
    },
    {
        label: "Plaats",
        name: "city",
        options: {
            customBodyRender(value: any, tableMeta) {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {value && (
                            <div style={{ marginRight: 8, display: "flex", marginLeft: -4, marginTop: -3 }}>
                                <CountryFlagBox code={tableMeta.rowData[1]} />
                            </div>
                        )}
                        <div>{value}</div>
                    </div>
                );
            },
        },
    },
    {
        label: "Gegunde opdrachten",
        name: "wonBids",
    },
    {
        label: "Status",
        name: "status",
        options: {
            customBodyRender(status: any, tableMeta) {
                return status !== null && <StatusDotOrg active={status} placement={"left"} textualHelper={tableMeta.rowData[2]} />;
            },
        },
    },
];

const SettlingsTable: React.FC<Props> = ({ rows, paginatorInfo, onChangePagination, loading }) => {
    const { t } = useTranslation();
    const amountPages = Math.ceil(paginatorInfo.total / paginatorInfo.perPage);

    const options: MUIDataTableOptions = {
        responsive: "standard",
        elevation: 0,
        filter: false,
        download: false,
        print: false,
        pagination: false,
        search: false,
        filterType: "checkbox",
        draggableColumns: {
            enabled: false,
            transitionTime: 200,
        },
        selectableRows: "none",
        customFooter() {
            return (
                <Grid container spacing={2} style={{ padding: 20 }} alignItems="center" justifyContent="space-between">
                    {/* Left section
                     *  number of total results found
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                        {paginatorInfo?.total === 0 ? 0 : paginatorInfo?.firstItem?.toLocaleString()}-{paginatorInfo?.lastItem?.toLocaleString()}{" "}
                        {t("of")} {paginatorInfo?.total.toLocaleString()}
                    </Grid>

                    {/* Middle section,
                     *  Show amount of pages with back/next arrow
                     */}
                    <Grid item sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Pagination
                            page={paginatorInfo.currentPage}
                            count={amountPages}
                            onChange={(_, page) => onChangePagination(page, paginatorInfo.perPage)}
                        />
                    </Grid>

                    {/* Right section
                     *  Selectable field with option to show more/less rows per page
                     */}
                    <Grid item sm={12} md={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <Select
                            variant="standard"
                            value={paginatorInfo.perPage}
                            onChange={(e) => onChangePagination(paginatorInfo.currentPage, e.target.value as number)}
                            style={{ marginRight: 4 }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        {t("resultsPerPage")}
                    </Grid>
                </Grid>
            );
        },
    };

    // dont show items with type rechtspersoon
    const result = rows ? rows.filter((row) => row.type !== "rechtspersoon") : [];
    const copyRes = [...result];
    // Reformat object in array to combine single address elements.
    const convertedData = copyRes.map((item) => {
        const container: any = {};
        const address = "address";
        const id = "id";
        const type = "type";
        const status = "status";
        const country = "country";
        const city = "city";
        const wonBids = "wonBids";
        // const helper = "helper";

        const street = item.visit_address?.street || null;
        const housenumber = item.visit_address?.housenumber || null;
        const suffix = item.visit_address?.housenumber_suffix || null;
        const postalcode = item.visit_address?.postal_code || null;

        // check if some values are null
        const commaOrNot =
            postalcode && housenumber && suffix
                ? `${housenumber} ${suffix}, `
                : postalcode && housenumber && suffix === null
                ? `${housenumber}, `
                : "";

        // capitalize first character with check for empty string
        function capitlizeFirst(str: string | null) {
            // checks for null, undefined and empty string
            if (!str) return;
            return str.match("^[a-z]") ? str.charAt(0).toUpperCase() + str.substring(1) : str;
        }

        const settlingType = capitlizeFirst(item.type);

        container[id] = item.id;
        container[type] = settlingType;
        container[status] = item.active;
        // container[helper] = item.status;

        // show detail when its not equal to null, if its null -> show nothing
        container[address] = `${street ? street + " " : ""} ${commaOrNot}${postalcode ? postalcode : ""}`;
        container[city] = ` ${item.visit_address?.city !== null ? item.visit_address?.city : ""}`;
        container[country] = item.visit_address?.country;
        container[wonBids] = item.wonBids.length;

        return container;
    });

    // return table with settling details.
    return (
        <>
            <LinearLoader loading={loading} color={"pink"} />
            <Disable disabledOpacity={0.6} disabled={loading}>
                <ThemeProvider theme={getMuiTheme}>
                    {/*
                    - Added paper element around table to use the square variant instead of the default, 
                    - in MuidatatableOptons: elevation -> 0 
                */}

                    <Paper square variant="outlined">
                        <MUIDataTable title={""} data={convertedData} columns={columns} options={options} />
                    </Paper>
                </ThemeProvider>
            </Disable>
        </>
    );
};
export default SettlingsTable;
