import moment from "moment";
import React from "react";
import { Disable } from "react-disable";
import TaskDatePicker from "../../projectModal/task/TaskDatePicker";
import { useMutation } from "@apollo/client";
import { updateTaskDeadline, updateTaskDeadlineVariables } from "../../../../__generated__/updateTaskDeadline";
import { UPDATE_TASK_DEADLINE } from "../../../../graphql/mutationDefProjects";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getTasksWithFilters_filterUserTasks_data } from "../../../../__generated__/getTasksWithFilters";
import { GET_TASKS_WITH_FILTERS } from "../../../../graphql/queryDefTasks";

interface Props {
    taskOfCu: boolean;
    task: getTasksWithFilters_filterUserTasks_data;
    onTaskChange: <K extends keyof getTasksWithFilters_filterUserTasks_data>(
        updatedTask: getTasksWithFilters_filterUserTasks_data,
        key: K,
        value: getTasksWithFilters_filterUserTasks_data[K]
    ) => void;
}

const TaskDeadline: React.FC<Props> = ({ taskOfCu, task, onTaskChange }) => {
    const { trackEvent } = useMatomo();
    // Change deadline of task
    const [saveTaskDeadline] = useMutation<updateTaskDeadline, updateTaskDeadlineVariables>(UPDATE_TASK_DEADLINE);

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangesaveTaskDeadline = async (taskId: string, deadline: moment.Moment | null) => {
        if (deadline !== null) {
            try {
                saveTaskDeadline({
                    variables: {
                        id: taskId,
                        deadline: moment(deadline).format("YYYY-MM-DD"),
                    },
                    refetchQueries: [GET_TASKS_WITH_FILTERS],
                    onCompleted: () => {
                        trackEvent({ category: "Project", action: "Change-task-deadline", name: deadline.format() });
                        const formattedDeadline = moment(deadline).format("YYYY-MM-DD"); // Format the deadline for API
                        const updatedTask = { ...task, task_deadline: formattedDeadline }; // Prepare the updated task
                        // Update local state upon successful API call
                        onTaskChange(updatedTask, "task_deadline", formattedDeadline);
                    },
                });
            } catch (e) {}
        }
    };
    return (
        <Disable
            disabledOpacity={task.deleted_at ? 0.6 : task.task_completed ? 0.6 : 0.8}
            disabled={task.deleted_at || task.task_completed || !taskOfCu}
        >
            <TaskDatePicker
                date={task.task_deadline !== null ? moment(task.task_deadline) : null}
                updateDate={(date) => {
                    if (taskOfCu) {
                        onChangesaveTaskDeadline(task.id, date);
                    }
                }}
            />
        </Disable>
    );
};

export default TaskDeadline;
