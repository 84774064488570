import React from "react";
import { Divider, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import { useMutation } from "@apollo/client";
import { createTaskInLibrary, createTaskInLibraryVariables } from "../../../../__generated__/createTaskInLibrary";
import { CREATE_NEW_TASK_IN_LIBRARY } from "../../../../graphql/mutationDefTasks";
import { toast } from "react-toastify";
import { Add, Clear, Search } from "@mui/icons-material";

interface Props {
    refetch: () => void;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    searchQuery: string;
}

// Styled Paper component

const Searchbar: React.FC<Props> = ({ refetch, setSearchQuery, searchQuery }) => {
    // Explain mutation
    const [addTaskToLibrary, { loading: loadCreate, error: errorCreate }] = useMutation<createTaskInLibrary, createTaskInLibraryVariables>(
        CREATE_NEW_TASK_IN_LIBRARY
    );

    // Explain mutation handler
    const addNewTask = async () => {
        if (errorCreate) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await addTaskToLibrary({
                variables: {
                    description: searchQuery,
                },
                onCompleted: (data) => {
                    refetch();
                    setSearchQuery("");
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <Paper square component="form" sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}>
            {/* Searchicon */}
            <Search style={{ margin: "10px 0px 10px 6px", color: "#707070" }} />

            {/* Inputfield query */}
            <InputBase
                sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                placeholder="Zoek/maak taak..."
                // autoFocus={onlyMobile}
                value={searchQuery} // Controlled field
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery.length >= 3 && (
                <Tooltip placement="right" title="Taak toevoegen" disableInteractive>
                    <IconButton
                        disabled={loadCreate || searchQuery.length < 3}
                        color="primary"
                        size="small"
                        aria-label="directions"
                        onMouseOver={(e) => {
                            e.stopPropagation();
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            addNewTask();
                        }}
                    >
                        <Add color="primary" />
                    </IconButton>
                </Tooltip>
            )}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            {/*
             * RESET BUTTON
             */}
            <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                <IconButton
                    disabled={searchQuery === "" || loadCreate}
                    color="primary"
                    size="small"
                    aria-label="directions"
                    onClick={(e) => {
                        e.stopPropagation();
                        setSearchQuery("");
                    }}
                >
                    <Clear sx={{ color: searchQuery === "" ? "#b3b3b3" : "error.main" }} />
                </IconButton>
            </Tooltip>
        </Paper>
    );
};

export default Searchbar;
