import { gql } from "@apollo/client";

export const GET_ENDING_CONTRACTS = gql`
    query EndingContracts {
        currentUser {
            id
            endingContracts {
                quarter
                searchResults
                liked
            }
        }
    }
`;

export const GET_AMOUNT_OF_SEARCHRULES = gql`
    query getSearchRuleCount {
        currentUser {
            id
            searches {
                id
            }
        }
    }
`;

export const QUERY_CURRENT_USER_SECTORS = gql`
    query getSectorOfCurrentUser {
        currentUser {
            id
            employee {
                id
                organization {
                    id
                    subscriptions {
                        ends_at
                        sectors {
                            id
                        }
                    }
                }
            }
        }
    }
`;

//? get all searchrules of current user
export const QUERY_AMOUNT_OF_SEARCHRULES = gql`
    query GetAmountOfUserSearchRules {
        currentUser {
            id
            # Tender searchrules
            searches {
                id
            }

            # opportunity searchrules
            opportunitySearches {
                id
            }
        }
    }
`;

//? get email of current user
export const QUERY_EMAIL = gql`
    query GetUserEmail {
        currentUser {
            id
            email
            employee {
                id
                organization {
                    id
                    subscriptions {
                        sectors {
                            id
                            name
                            parent {
                                id
                                name
                            }
                            children {
                                id
                                name
                                parent {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

// ? GraphQL query to fetch all searchterms from user
export const QUERY_ALL_SEARCHTERMS = gql`
    query GetWordArray {
        currentUser {
            id
            keywords {
                id
                keyword
            }
        }
    }
`;

export const QUERY_CU_SECTOR = gql`
    query GetSectorSetOfCU {
        currentUser {
            id
            employee {
                id
                organization {
                    id
                    subscriptions {
                        sectors {
                            id
                            name
                            children {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

//? Get user settings
export const QUERY_CURRENT_USER_SETTINGS = gql`
    query GetCurrentUserSettings {
        currentUser {
            id
            email
            language
            employee {
                id
                cellphone
                phonenumber
                function
                givenname
                middlename
                familyname
            }
        }
    }
`;

//? get all tenders in platform, sorted column: updated_at
export const QUERY_USER_SETTINGS = gql`
    query GetUserSettings {
        currentUser {
            id
            settings {
                ... on StringSetting {
                    name
                    string: value
                    __typename
                }

                ... on IntSetting {
                    name
                    Int: value
                    __typename
                }

                ... on BooleanSetting {
                    name
                    Bool: value
                    __typename
                }

                ... on ArraySetting {
                    name
                    Arr: value
                    __typename
                }
            }
        }
    }
`;

//? get all tenders in platform, sorted column: updated_at
export const QUERY_USER_IS_SUB_ADMIN = gql`
    query CheckIfUserIsAdmin {
        currentUser {
            id
            subscription_admin
            searches {
                id
            }
        }
    }
`;

//? get all searchrules of current user
export const QUERY_SEARCHRULES = gql`
    query GetUserSearchRules {
        currentUser {
            id
            subscription_admin
            ownedSearches {
                id
                name
                state
                moment
                query
                target
                # needed for searchrule filterset
                filters
                filters_with_labels {
                    key
                    labels
                }
                pivot {
                    email_interval
                    in_app
                    worklist
                }
                color

                owner {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
                participants {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }

            # Tender searchrules
            searches {
                id
                name
                state
                moment
                query
                target
                # needed for searchrule filterset
                filters
                filters_with_labels {
                    key
                    labels
                }
                color
                pivot {
                    email_interval
                    in_app
                    worklist
                }
                owner {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
                participants {
                    id
                    employee {
                        id
                        name
                        givenname
                        familyname
                    }
                }
            }

            # opportunity searchrules
            opportunitySearches {
                id
                name
                query
                filters
                state
                filters_with_labels {
                    key
                    labels
                }
            }
        }
    }
`;

//?  Query for highlighting in title, summary and description
export const GET_NOTIFICATIONS_CURRENT_USER = gql`
    query GetNotificationsCurrentUser($notiFirst: Int!, $notiPage: Int!) {
        currentUser {
            id
            notifications(first: $notiFirst, page: $notiPage) {
                data {
                    id
                    type
                    data
                    created_at
                    read_at
                }
                paginatorInfo {
                    total
                    lastPage
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    perPage
                    lastPage
                }
            }
        }
    }
`;

//?  Query for highlighting in title, summary and description
export const GET_AMOUNT_OF_UREAD_NOTIFICATIONS_CURRENT_USER = gql`
    query GetAmountOfUnreadNotificationsCurrentUser {
        currentUser {
            id
            unreadNotificationsCount
        }
    }
`;

//? get email
export const GET_USER_EMAIL = gql`
    query currentUserEmail {
        currentUser {
            id
            email
        }
    }
`;

// ? get length of worklist of current user
export const QUERY_WORKLISTLENGTH = gql`
    query GetWorkListLength {
        currentUser {
            id
            worklistLength
        }
    }
`;

//? get the user's liked cas
export const QUERY_USER_LIKED_CAS = gql`
    query GetLikedCas {
        currentUser {
            id
            contracting_authorities {
                id
            }
        }
    }
`;

// ? Get list of liked contracting auhtorities
export const QUERY_USER_LIKED_TENDERERS = gql`
    query GetLikedTenderers {
        currentUser {
            id
            market_parties {
                id
            }
        }
    }
`;

// ? get the user's liked tenderer
export const QUERY_USER_LIKED_ORGS = gql`
    query GetLikedOrgs {
        currentUser {
            id
            market_parties {
                id
            }
        }
    }
`;
// ? Get list of liked tenders
export const QUERY_USER_LIKED_TENDERS = gql`
    query GetLikedTendersOfUser {
        currentUser {
            id
            likedTenders {
                id
            }
        }
    }
`;

//? Get user name and id
export const QUERY_CURRENT_USER_NAME = gql`
    query GetCurrentUserNameToShow {
        currentUser {
            id
            employee {
                id
                name
                familyname
            }
        }
    }
`;

// ? get starred contracting authorities of current user (logged in)
export const QUERY_GETSTARREDCAS = gql`
    query GetStarredCas {
        currentUser {
            contracting_authorities {
                id
            }
        }
    }
`;

// ? get starred tenderes/organizations of current user (logged in)
export const QUERY_GETSTARREDORGS = gql`
    query GetStarredOrganizations {
        currentUser {
            id
            market_parties {
                id
            }
        }
    }
`;

//? get personal data of current user (logged in)
export const QUERY_GETUSERDATA = gql`
    query GetLoggedInUsersData {
        currentUser {
            id
            email
            employee {
                id
                givenname
                middlename
                familyname
                organization {
                    id
                    name
                    favicon
                    logo
                }
            }
            contracting_authorities {
                id
                name
            }
            market_parties {
                id
                name
            }
        }
    }
`;

//? get all users in organization
export const QUERY_ALL_USERS_IN_ORGANIZATION = gql`
    query AllUsersInOrganization {
        currentUser {
            id
            employee {
                id
                organization {
                    id
                    teams {
                        users {
                            id
                            email
                            employee {
                                id
                                familyname
                                middlename
                                givenname
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ADD_USER_TO_ORGANIZATION = gql`
    mutation AddUserToOrganization(
        $givenname: String
        $familyname: String
        $middlename: String
        $function: String
        $email: String!
        $cellphone: String
        $phonenumber: String
    ) {
        createUser(
            givenname: $givenname
            familyname: $familyname
            middlename: $middlename
            function: $function
            email: $email
            cellphone: $cellphone
            phonenumber: $phonenumber
        ) {
            id
        }
    }
`;

export const DELETE_USER = gql`
    mutation RemoveUser($id: ID!) {
        deleteUser(id: $id)
    }
`;

export const MARK_INTRO_AS_SEEN = gql`
    mutation markIntroAsSeen {
        markIntroAsSeen
    }
`;

export const QUERY_INTRO_HAS_SEEN = gql`
    query queryIntroSeen {
        currentUser {
            id
            hasSeenIntro
            employee {
                givenname
            }
        }
    }
`;

// ? Get dashboard layout
export const QUERY_DASHBOARD_LAYOUT = gql`
    query GetDashboardLayout {
        GetDashboardLayout {
            rows {
                columns {
                    id
                    name
                    widgetId
                }
                id
                order
            }
        }
    }
`;

export const QUERY_TILE_WIDGET_DATA = gql`
    query GetTileWidgetData($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                data {
                    id
                    namegiventocontract
                    status
                    datum_gunning
                    searchresults {
                        search {
                            id
                            name
                            color
                        }
                    }
                    deadline_inschrijving
                    country {
                        name
                        alpha2
                        numeric
                    }
                    opties_einde {
                        value
                        lots
                    }

                    looptijd_einde {
                        lots
                        value
                    }
                    looptijd_start {
                        lots
                        value
                    }
                    lots {
                        id
                        number
                    }
                    clientA {
                        id
                        name
                        favicon
                        logo
                    }

                    bids {
                        marketParty {
                            id
                            name
                            logo
                            favicon
                        }
                        winner
                        bid
                        lot {
                            number
                        }
                    }

                    published_opdrachtgever
                }
                paginatorInfo {
                    count
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                    lastPage
                    perPage
                    total
                }
            }
        }
    }
`;

// ? Get dashboard layout
export const QUERY_MY_TASKS = gql`
    query GetMyTasks($task_completed: Boolean!) {
        tasks(task_completed: $task_completed) {
            id
            task_completed
            task_deadline
            task_description
            task_priority
            project {
                id
                title
                state {
                    id
                    name
                }
            }
            users {
                id
                employee {
                    id
                    name
                    familyname
                }
            }
        }
    }
`;

export const SET_USER_AS_SUBSCRIPTION_ADMIN = gql`
    mutation SetAsSubAdmin($id: Int!) {
        MakeUserSubscriptionAdmin(id: $id)
    }
`;

export const QUERY_INITIALS = gql`
    query QueryInitials {
        currentUser {
            id
            employee {
                name
                givenname
                familyname
            }
        }
    }
`;

export const GET_ORGANIZATION_SEARCHES = gql`
    query GetOrganizationSearchRules {
        getOrganizationSearches {
            id
            name
            state
            moment
            query
            target
            owner {
                id
                employee {
                    id
                    name
                    familyname
                }
            }

            # needed for searchrule filterset
            filters
            filters_with_labels {
                key
                labels
            }
            color
            pivot {
                email_interval
                in_app
                worklist
            }
            owner {
                id
                employee {
                    id
                    name
                    familyname
                }
            }
            participants {
                id
                employee {
                    id
                    name
                    familyname
                }
            }
        }
    }
`;

export const CHANGE_SEARCH_OWNER = gql`
    mutation changeSearchOwner($search_id: Int!, $new_owner: Int!) {
        changeSearchOwner(search_id: $search_id, new_owner: $new_owner)
    }
`;
