import React, { useContext, useState } from "react";
import { Chip, MenuItem, MenuList, Popover } from "@mui/material";
import { ProjectToOpenContext } from "../../../components/layout/MainLayout";
import { projectStates } from "../../../__generated__/projectStates";
import { ALL_PROJECTS_WIDGET, PROJECT_STATES } from "../../../graphql/queryDefProjects";
import { useMutation, useQuery } from "@apollo/client";
import { updateStateOfProject, updateStateOfProjectVariables } from "../../../__generated__/updateStateOfProject";
import { UPDATE_PROJECT_STATE } from "../../../graphql/mutationDefProjects";
import { useTranslation } from "react-i18next";
import { QUERY_MY_TASKS } from "../../../graphql/queryDefCurrentUser";
import { blue } from "@mui/material/colors";

interface Props {
    currentFase: string;
    projectId: string;
    refetch?: () => void;
}

const MyProjectFaseSelector: React.FC<Props> = ({ currentFase, projectId, refetch }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { project, changeProject } = useContext(ProjectToOpenContext);

    // fetch project states
    const { data } = useQuery<projectStates>(PROJECT_STATES, {
        onCompleted: (data) => {
            if (data && data.projectStates && project.state === "") {
                changeProject({ state: data.projectStates[0].id });
            }
        },
    });

    /**
     * Update project title
     */
    const [updateProjectState] = useMutation<updateStateOfProject, updateStateOfProjectVariables>(UPDATE_PROJECT_STATE, {
        refetchQueries: [ALL_PROJECTS_WIDGET, "GetAllProjectsDashboard", PROJECT_STATES, "projectStates", QUERY_MY_TASKS, "GetMyTasks"],
        awaitRefetchQueries: true,
    });

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItem = (item: string) => {
        changeProject({ state: item });
        updateProjectState({
            variables: { id: projectId, state: item },
            onCompleted: () => {
                refetch && refetch();
            },
        });
        handleClose();
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <React.Fragment>
            <Chip
                onClick={handleClick}
                sx={{
                    backgroundColor: blue[50],
                    borderRadius: "4px",
                    color: blue[700],
                    // borderColor: blue[200],
                    border: `1px solid ${blue[100]}`,
                    fontWeight: 500,
                    "&:hover": { color: blue[800], backgroundColor: blue[100] },
                }}
                label={currentFase === "New" ? t("New") : currentFase}
                size="small"
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <MenuList dense autoFocusItem={open} style={{ padding: 0 }}>
                    {data?.projectStates?.map((fase) => {
                        return (
                            <MenuItem
                                sx={{
                                    fontWeight: 500,
                                    color: blue[700],
                                    "&:hover": {
                                        backgroundColor: blue[50],
                                    },
                                    "&.Mui-selected": {
                                        backgroundColor: blue[50],
                                        "&:hover": {
                                            backgroundColor: blue[100],
                                        },
                                    },
                                }}
                                key={fase.id}
                                selected={currentFase === fase.name}
                                onClick={() => {
                                    handleItem(fase.id);
                                }}
                            >
                                {fase.name === "New" ? t("New") : fase.name}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Popover>
        </React.Fragment>
    );
};

export default MyProjectFaseSelector;
