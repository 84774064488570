import React from "react";
import FilterDropdownStringVersion from "../../../../components/TopFilterBar/FilterDropdownStringVersion";
import { getTasksWithFilters_filterUserTasks_filters, getTasksWithFiltersVariables } from "../../../../__generated__/getTasksWithFilters";
import update from "immutability-helper";

interface Props {
    filterData: getTasksWithFilters_filterUserTasks_filters | null | undefined;
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
}

const TaskComplete: React.FC<Props> = ({ filterData, setActiveFilters, activeFilters }) => {
    const options = filterData?.task_completed?.map((tsk) => {
        let label = "";
        let identifier = "";

        // Map priority string to the correct label and identifier
        switch (tsk) {
            case "false":
                label = "Openstaand taken"; // Use translation function t() for MEDIUM
                identifier = "false";
                break;

            case "true":
                label = "Afgeronde taken"; // Use translation function t() for HIGH
                identifier = "true";
                break;

            case "removed":
                label = "Verwijderde taken"; // Use translation function t() for LOW
                identifier = "removed";
                break;
            default:
                break;
        }

        return { label, identifier };
    });

    // shorter version
    const active = activeFilters.task_completed;

    return (
        <FilterDropdownStringVersion
            disabled={false}
            // allowSearch
            label="Status"
            setOnlyOne={true}
            values={active ? [active] : []}
            onChange={(selectedValues) => {
                if (selectedValues.length === 0) {
                    setActiveFilters(update(activeFilters, { $unset: ["task_completed"] }));
                } else {
                    setActiveFilters(update(activeFilters, { task_completed: { $set: selectedValues[0] } }));
                }
            }}
            customSorting={["false", "true", "removed"]}
            options={
                options?.map((state) => ({
                    key: state.identifier as string,
                    label: state.label as string,
                    id: state.identifier as string,
                })) || []
            }
            width={200}
        />
    );
};

export default TaskComplete;
