import React from "react";
import { SavedTenderEvents_getCalendarEvents_TenderDate } from "../../__generated__/SavedTenderEvents";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import TenderDateTooltip from "./TenderDateTooltip";
import FaviconBox from "../../components/FaviconBox";

interface Props {
    event: SavedTenderEvents_getCalendarEvents_TenderDate;
    setId(): void;
    setActiveTooltip: (bln: any) => void;
    activeTooltip: string | false;
    margin?: string;
    openId: string;
    setOpenId: (str: string) => void;
}

const TenderDateEvent: React.FC<Props> = ({ event, setId, setActiveTooltip, activeTooltip, margin, openId, setOpenId }) => {
    const tender = "#465c84";
    const hoverColor = "#173357";

    const clientA = event.tender?.clientA[0];

    return (
        <div style={{ zIndex: 95 }}>
            <TenderDateTooltip
                openId={openId}
                setOpenId={setOpenId}
                setActiveTooltip={setActiveTooltip}
                setId={setId}
                content={
                    <Box
                        component="div"
                        sx={{
                            backgroundColor: openId === event.id ? hoverColor : tender, //item.type === "Tender" ? `${tender}` : item.type === "opportunity" ? `${opportunity}` : `${other}`,
                            color: "white",
                            margin: margin ? margin : `0px 4px 4px 4px`,
                            zIndex: 99,
                            borderRadius: "3px",
                            boxShadow:
                                activeTooltip === event.id ? `rgba(0, 0, 0, 0.2) 0px 2px 5px 0px, rgba(0, 0, 0, 0.19) 0px 3px 2px 0px` : undefined,
                            "&:hover": {
                                backgroundColor: hoverColor,
                            },
                        }}
                        onClick={() => {
                            setActiveTooltip(event.id);
                        }}
                    >
                        <Typography noWrap style={{ paddingLeft: 4, paddingRight: 4, fontWeight: 500 }}>
                            {event.timeSignificant && <span style={{ fontWeight: 700 }}>{moment(event.planned).format("LT")}</span>}{" "}
                            {event.name.replace(/ percelen: 0/g, "")}
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center", padding: "2px 2px 2px 4px" }}>
                            <div style={{ backgroundColor: "#fff", borderRadius: "4px", width: "14px", height: "14px" }}>
                                <FaviconBox
                                    favicon={clientA?.favicon_resized ?? clientA?.favicon ?? null}
                                    name={clientA?.name}
                                    color="#F57117"
                                    size="14px"
                                    borderRadius={4}
                                />
                            </div>
                            <Typography variant="caption" sx={{ marginLeft: "4px" }} noWrap>
                                {clientA?.name}
                            </Typography>
                        </div>
                    </Box>
                }
                data={event}
            />
        </div>
    );
};

export default TenderDateEvent;
