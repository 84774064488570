import React from "react";
import WidgetBox from "../../../components/WidgetBox";
import { useQuery } from "@apollo/client";
import { QUERY_MY_TASKS } from "../../../graphql/queryDefCurrentUser";
import { GetMyTasks } from "../../../__generated__/GetMyTasks";
import MyTasksWidgetLoader from "./MyTasksWidgetLoader";
import MyTasksWidgetError from "./MyTasksWidgetError";
import MyTasksWidgetData from "./MyTasksWidgetData";

interface Props {
    a?: number;
}

const MyTasksWidget: React.FC<Props> = (props) => {
    const { data, loading, error, refetch } = useQuery<GetMyTasks>(QUERY_MY_TASKS, {
        variables: {
            task_completed: false,
        },
        fetchPolicy: "network-only",
    });

    if (loading) {
        return renderPage(<MyTasksWidgetLoader />);
    }

    // if error or empty -> show UpdateWidgetError component with given attributes
    if (error || !data || !data.tasks) return renderPage(<MyTasksWidgetError errorText={"Geen openstaande taken gevonden"} />);

    return renderPage(<MyTasksWidgetData data={data.tasks} refetch={refetch} />);
};

export default MyTasksWidget;

const renderPage = (content: React.ReactNode) => (
    <WidgetBox
        header={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* {t("dashboardPage.UpdatesInterestingTenders")} */}
                    Mijn openstaande taken
                </div>
                {/* <Typography>
                <Link id="link-to-full-list" to="/tenders/reviews">
                {t("dashboardPage.ShowAll")}
                </Link>
            </Typography> */}
            </div>
        }
        highlight="grey"
        padding={0}
    >
        <div>{content}</div>
    </WidgetBox>
);
