import React from "react";

import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineItem as MuiTimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
} from "@mui/lab";
import { Button, IconButton, Paper, Typography } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { logicalOperators, useQueryBuilder } from "./QueryBuilderContext";
import ChangeOperatorBetweenGroups from "./ChangeOperatorBetweenGroups";
import QueryBuilderSearchTermBox from "./QueryBuilderSearchTermBox";
import AddSearchGroup from "./AddSearchGroup";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
    onClose?: () => void;
    themeColor: string;
    hideButtons?: boolean;
}

/**
 * This is used for the advanced search
 * timeline component adds this styling automatically,
 * Now its overruled so its aligned on the left side.
 */
const TimelineItem = withStyles(MuiTimelineItem, {
    missingOppositeContent: {
        "&:before": {
            display: "none",
        },
    },
});

const QueryBuilder: React.FC<Props> = ({ onClose, themeColor, hideButtons = false }) => {
    const { t } = useTranslation();
    const {
        groups,
        activeGroup,
        setActiveGroup,
        highlightedConditionId,
        updateGroupOperator,
        updateConditionOperator,
        updateConditionKeyword,
        handleNearTermChange,
        handleProximityChange,
        removeCondition,
        handleNearCondition,
        addGroup,
        removeGroup,
        resetNearTerm,
        handleGenerateQuery,
        areThereNEARConditionsNotValid,
    } = useQueryBuilder();

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }}>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    flex: 1,
                }}
            >
                <Timeline
                    sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0,
                        },
                    }}
                >
                    {groups.map((group, groupIndex) => (
                        <>
                            {/* SHOW GROUP OPERATOR EXCEPT FOR THE FIRST GROUP */}
                            {groupIndex !== 0 && (
                                <ChangeOperatorBetweenGroups updateGroupOperator={updateGroupOperator} groupId={group.id} operator={group.operator} />
                            )}
                            <TimelineItem
                                key={group.id}
                                style={{
                                    margin: "10px 0",
                                    padding: "10px 10px 10px 0px",
                                }}
                            >
                                <TimelineOppositeContent
                                    sx={{ m: 0, p: 0, display: "flex", flexDirection: "column" }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                />
                                <TimelineSeparator sx={{ width: "12px" }}>
                                    {/* Thin line u see in the UI with a given height so its long enough to fit 3 elements */}
                                    <TimelineConnector
                                        style={{ marginLeft: "16px", backgroundColor: activeGroup === group.id ? themeColor : undefined }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                    }}
                                >
                                    {/*
                                     * BOX WITH CONDITIONS
                                     */}
                                    <Paper
                                        onClick={() => setActiveGroup(group.id)}
                                        elevation={0}
                                        sx={{
                                            width: "100%",
                                            padding: "16px",
                                            position: "relative",
                                            backgroundColor: activeGroup === group.id ? "#f4f4f4" : "#ffffff",
                                            "&:hover": {
                                                backgroundColor: activeGroup === group.id ? undefined : "#f4f4f4",
                                                cursor: activeGroup === group.id ? "auto" : "pointer",
                                            },
                                        }}
                                    >
                                        {groupIndex > 0 && group.conditions.length === 0 && (
                                            <IconButton
                                                size="small"
                                                sx={{ position: "absolute", right: 8, top: 12 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveGroup(groups[0].id);
                                                    removeGroup(group.id);
                                                }}
                                            >
                                                <Clear fontSize="small" />
                                            </IconButton>
                                        )}

                                        {activeGroup === group.id && group.conditions.length > 0 && (
                                            <Typography
                                                variant="caption"
                                                sx={{ position: "absolute", right: 12, top: 8, color: "#b8b8b8", fontWeight: 500, fontSize: 10 }}
                                            >
                                                {t("Edit")}
                                            </Typography>
                                        )}
                                        {group.conditions.length === 0 && (
                                            <Typography
                                                variant="button"
                                                fontStyle="italic"
                                                sx={{
                                                    marginTop: "2px",
                                                    color: "#b8b8b8",
                                                    fontSize: "0.7rem",
                                                    borderBottom: "1px dashed #b8b8b8",
                                                }}
                                            >
                                                {t("Add_Searchterm")}
                                            </Typography>
                                        )}
                                        {group.conditions.map((condition, index) => {
                                            // Return 'zoekwoord toevoegen' if keyword is empty
                                            if (condition.keyword === "" || condition.keyword === undefined) {
                                                return (
                                                    <Typography
                                                        key={condition.id}
                                                        variant="button"
                                                        fontStyle="italic"
                                                        sx={{
                                                            color: "#b8b8b8",
                                                            fontSize: "0.7rem",
                                                            borderBottom: "1px dashed #b8b8b8",
                                                        }}
                                                    >
                                                        {t("Add_Searchterm")}
                                                    </Typography>
                                                );
                                            }

                                            // SEARCHTERM BLOCK
                                            return (
                                                <QueryBuilderSearchTermBox
                                                    group={group}
                                                    groupId={group.id}
                                                    key={condition.id}
                                                    condition={condition}
                                                    themeColor={themeColor}
                                                    activeGroup={activeGroup}
                                                    isFirstCondition={index === 0}
                                                    isLastConditionAndNotActive={group.conditions.length - 1 === index && activeGroup !== group.id}
                                                    removeCondition={removeCondition}
                                                    logicalOperators={logicalOperators}
                                                    handleRemoveNearTerm={resetNearTerm}
                                                    handleNearCondition={handleNearCondition}
                                                    handleNearTermChange={handleNearTermChange}
                                                    handleProximityChange={handleProximityChange}
                                                    updateConditionKeyword={updateConditionKeyword}
                                                    highlightedConditionId={highlightedConditionId}
                                                    updateConditionOperator={updateConditionOperator}
                                                    nextKeyword={index + 1 < group.conditions.length ? group.conditions[index + 1] : undefined}
                                                />
                                            );
                                        })}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </>
                    ))}

                    {groups.length < 2 && groups[0].conditions.length >= 2 && (
                        <>
                            <TimelineSeparator>
                                <TimelineDot
                                    sx={{
                                        margin: 0,
                                        backgroundColor: groups.length === 2 || areThereNEARConditionsNotValid() ? "#cccccc" : "primary.main",
                                    }}
                                >
                                    <AddSearchGroup addGroup={addGroup} disabled={groups.length === 2 || areThereNEARConditionsNotValid()} />
                                </TimelineDot>
                            </TimelineSeparator>
                        </>
                    )}
                </Timeline>
            </div>
            {!hideButtons && (
                <div style={{ width: "100%", display: "flex", padding: "16px", alignItems: "center", justifyContent: "flex-end" }}>
                    <Button
                        color="error"
                        onClick={() => {
                            onClose && onClose();
                        }}
                    >
                        {t("close")}
                    </Button>
                    <Button
                        disabled={areThereNEARConditionsNotValid()}
                        variant="contained"
                        onClick={() => {
                            handleGenerateQuery();
                            onClose && onClose();
                        }}
                    >
                        {t("Search")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default QueryBuilder;
