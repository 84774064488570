import React from "react";
import MatomoEvent from "../../../models/MatomoEvent";
import { useMatomo } from "@datapunt/matomo-tracker-react";

interface Props {
    href: string;
}

const MailToLink: React.FC<Props> = ({ href, children }) => {
    const { trackEvent } = useMatomo();
    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    return (
        <>
            Neem dan snel
            <a
                href={href}
                onClick={() => trackevents({ category: "Column", action: "Click contactform in content id:138" })}
                style={{ color: "#70a4b7", fontWeight: "500", margin: "0px 4px" }}
            >
                contact
            </a>
            met ons op voor meer informatie of een kennismakingsgesprek.
        </>
    );
};

export default MailToLink;
