import React, { useState } from "react";

//Import components
import SettlingsTable from "./SettlingsTable";
import FullPageSpinner from "../../../../components/loading/FullPageSpinner";
import setTitle, { setTitleLoading } from "../../../../utils/setTitle";

//Import translation
import { useTranslation } from "react-i18next";

// GQL
import { useQuery } from "@apollo/client";
import {
    GetSettlingsDetails,
    GetSettlingsDetailsVariables,
    GetSettlingsDetails_tenderer_settlings,
} from "../../../../__generated__/GetSettlingsDetails";

//Material UI import
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import RenderPage from "../../../../components/loading/RenderPage";
import { QUERY_SETTLINGS } from "../../../../graphql/queryDefOrg";
import CardViewSettlings, { settlingDataProps } from "./CardViewSettlings";

interface Props {
    id: string;
}

const SettlingDetailTab: React.FC<Props> = ({ id }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("md"));

    /**
     * Original state -> will be used when query is updated
     */
    const [settlingData, setSettlingData] = useState<GetSettlingsDetails_tenderer_settlings | null>();

    /**
     * Copy of original state
     */
    const [copySet, setCopySet] = useState<GetSettlingsDetails_tenderer_settlings | null>();
    /**
     * Last settling number
     */
    const indexOfLastPost = page * rowsPerPage;
    /**
     * First settling number
     */
    const indexOfFirstPost = indexOfLastPost - rowsPerPage;

    const typeOrder = ["hoofdvestiging"];
    const getTypeIndex = (type: string) => {
        return typeOrder.indexOf(type);
    };

    /**
     * Sort array and slice part of array (first item, last item)
     */
    const currentPosts = copySet?.data
        .slice()
        // Remove rechtspersoon
        .filter((row) => row.type !== "rechtspersoon")
        // Sort array
        .sort((a: any, b: any) => getTypeIndex(b.type) - getTypeIndex(a.type) || b.wonBids?.length - a.wonBids?.length || b.active - a.active)
        .slice(indexOfFirstPost, indexOfLastPost);

    /**
     * Last pagenumber
     */
    const lastpage = settlingData?.data !== undefined ? settlingData?.data?.length / rowsPerPage : 0;

    const { loading, error, data } = useQuery<GetSettlingsDetails, GetSettlingsDetailsVariables>(QUERY_SETTLINGS, {
        variables: { id: id, settlingAmount: 1000, SettlingPage: 1 },
        onCompleted: (data) => {
            if (data && data.tenderer?.settlings) {
                setSettlingData(data.tenderer.settlings);
                setCopySet(data.tenderer.settlings);
            }
        },
    });

    if (!settlingData && loading) {
        setTitleLoading(t);
        return (
            <RenderPage>
                <FullPageSpinner />
            </RenderPage>
        );
    }

    // Errormessage. TODO: fancy error page
    if (error || !settlingData) {
        setTitle(t("error-occurred"));
        return (
            <RenderPage>
                <p>Fout tijdens laden van vestigingen</p>
            </RenderPage>
        );
    }

    // handle pagination change: -page change, -rows per page change
    const handlePaginationChange = (page: number, rows: number) => {
        setPage(page);
        setRowsPerPage(rows);
        if (rows !== rowsPerPage) {
            setPage(1);
        }
    };

    // handle table change: -sort column, -sort direction
    // const handleTableChange = (_: string, tableState: MUIDataTableState) => {
    //     if (tableState.sortOrder.direction) {
    //         setOrderDir(tableState.sortOrder.direction.toUpperCase() as SortOrder);
    //     }
    //     if (tableState.sortOrder.name) {
    //         setOrderField(tableState.sortOrder.name);
    //     }
    // };

    setTitle(data?.tenderer?.name || "Vestigingen");

    if (!noMobile) {
        return (
            <CardViewSettlings
                type={"mp"}
                data={
                    currentPosts?.map<settlingDataProps>((row) => {
                        const street = row.visit_address?.street || null;
                        const housenumber = row.visit_address?.housenumber || null;
                        const suffix = row.visit_address?.housenumber_suffix || null;
                        const postalcode = row.visit_address?.postal_code || null;

                        function capitlizeFirst(str: string | null) {
                            // checks for null, undefined and empty string
                            if (!str) return;
                            return str.match("^[a-z]") ? str.charAt(0).toUpperCase() + str.substring(1) : str;
                        }

                        // check if some values are null
                        const commaOrNot =
                            postalcode && housenumber && suffix
                                ? `${housenumber} ${suffix}, `
                                : postalcode && housenumber && suffix === null
                                ? `${housenumber}, `
                                : "";

                        const item = {
                            id: row.id,
                            name: row.name ?? "",
                            // adres: `${street ? street + " " : ""} ${commaOrNot}${postalcode ? postalcode : ""}` ?? "",
                            adres: `${street ? street + " " : ""}${commaOrNot}${postalcode ? postalcode : ""}`,
                            status: row.active,
                            statustext: "", //row.status,
                            country: row.visit_address?.country ?? "",
                            type: capitlizeFirst(row.type) ?? "",
                            place: row.visit_address?.city ?? "",
                            last_update: "",
                            wonBids: row.wonBids.length,
                        };
                        return item;
                    }) || []
                }
                loading={loading}
                paginatorInfo={
                    settlingData?.paginatorInfo
                        ? settlingData?.paginatorInfo
                        : {
                              count: 0,
                              currentPage: 0,
                              hasMorePages: false,
                              firstItem: 0,
                              lastItem: 0,
                              lastPage: 1,
                              perPage: 10,
                              total: 0,
                              __typename: "PaginatorInfo",
                          }
                }
                onChangePagination={handlePaginationChange}
            />
        );
    }

    return (
        <Grid container xs={12} style={{ padding: "24px 0px 0px" }}>
            {/* TODO: create map with settlings as lonlat data points v4.3 */}
            {/* <Grid item xs={12} md={5}>
                Kaart
            </Grid> */}
            <Grid item xs={12} md={12}>
                <SettlingsTable
                    loading={loading}
                    rows={currentPosts}
                    onChangePagination={handlePaginationChange}
                    /**
                     * Temporary paginator until query can be used
                     * (for now GUI paginate)
                     */
                    paginatorInfo={
                        {
                            count: rowsPerPage,
                            currentPage: page,
                            hasMorePages: indexOfLastPost !== settlingData.paginatorInfo.total,
                            firstItem: indexOfFirstPost === 0 ? 1 : indexOfFirstPost,
                            lastItem: indexOfLastPost,
                            lastPage: lastpage,
                            perPage: rowsPerPage,
                            total: settlingData.paginatorInfo.total,
                            __typename: "PaginatorInfo",
                        }
                        //    || settlingData.paginatorInfo || {
                        //         count: 0,
                        //         currentPage: 0,
                        //         hasMorePages: false,
                        //         firstItem: null,
                        //         lastItem: null,
                        //         lastPage: 1,
                        //         perPage: 10,
                        //         total: 0,
                        //         __typename: "PaginatorInfo",
                        //     }
                    }
                />
            </Grid>
        </Grid>
    );
};

export default SettlingDetailTab;
