import React from "react";
import SingleTask from "./SingleTask";
import {
    getTasksWithFilters_filterUserTasks_data,
    getTasksWithFilters_filterUserTasks_pagination,
    getTasksWithFiltersVariables,
} from "../../../__generated__/getTasksWithFilters";
import { CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import update from "immutability-helper";

interface Props {
    data: getTasksWithFilters_filterUserTasks_data[];
    pagination: getTasksWithFilters_filterUserTasks_pagination | undefined;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
    setData: React.Dispatch<React.SetStateAction<getTasksWithFilters_filterUserTasks_data[]>>;
    activeFilters: getTasksWithFiltersVariables;
    loading: boolean;
    refetch: () => void;
}

const TasksContainer: React.FC<Props> = ({ data, pagination, setActiveFilters, activeFilters, loading, refetch, setData }) => {
    return (
        <div style={{ marginBottom: "128px", height: `calc(100vh - 210px)`, overflow: "auto" }} id="taskscroll">
            <InfiniteScroll
                // height={"100%"}
                /**
                 * Scroll if 98% is scrolled
                 */
                scrollThreshold={0.9}
                /**
                 * id of menulist to target the menu as window instead of whole window
                 */
                scrollableTarget="taskscroll"
                /**
                 * This is important field to render the next data.
                 */
                dataLength={data.length} //This is important field to render the next data
                /**
                 * function to get next data
                 */
                next={() => setActiveFilters(update(activeFilters, { perPage: { $set: (activeFilters.perPage as number) + 20 } }))}
                /**
                 * boolean to check if more data is available
                 */
                hasMore={pagination?.currentPage !== pagination?.lastPage}
                /**
                 * loader component
                 */
                loader={
                    loading && (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress classes={{ root: "spinner" }} size={18} />
                        </div>
                    )
                }
                /**
                 * message when bottom is reached
                 */
                endMessage={<p style={{ textAlign: "center", height: "8px" }} />}
            >
                {/* Map fetched data */}
                {data.map((task) => {
                    return <SingleTask key={task.id} task={task} setData={setData} refetch={refetch} />;
                })}
            </InfiniteScroll>
            {/* Add button */}
            {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "4px" }}>
                {!task_completed && (
                    <Button onClick={() => showAddNewTask(true)} size="small">
                        taak toevoegen
                    </Button>
                )}
            </div> */}
        </div>
    );
};

export default TasksContainer;
