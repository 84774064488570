import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface Props {
    a?: number;
}

const ReferToEnrichmentPage: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isValidPath =
        pathname.match(/\/tenders\/\d+$/) || // Matches /tenders/[ID]
        pathname.match(/\/organizations\/\d+$/) || // Matches /organizations/admin/[ID]
        pathname.match(/\/contracting-authorities\/\d+$/);

    const handleNavigation = () => {
        if (isValidPath) {
            // Extract the ID from the pathname
            const id = pathname.split("/").pop();

            // Determine the new path to navigate to
            if (pathname.startsWith("/tenders/")) {
                navigate(`/tenders/admin/${id}`);
            } else if (pathname.startsWith("/organizations/")) {
                navigate(`/organizations/admin/${id}`);
            } else if (pathname.startsWith("/contracting-authorities/")) {
                navigate(`/organizations/admin/${id}`); // Redirect contracting authorities to organizations
            }
        }
    };

    if (!isValidPath) {
        return <></>;
    }

    return (
        <Tooltip
            title={"Bewerken"}
            placement="right"
            PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10], // Adjust the first value to move left (-) or right (+)
                        },
                    },
                ],
            }}
        >
            <IconButton disabled={!isValidPath} onClick={handleNavigation} sx={{ height: "48px", width: "48px" }}>
                <Edit htmlColor={isValidPath ? "#e6e6e6" : "#e6e6e65c"} />
            </IconButton>
        </Tooltip>
    );
};

export default ReferToEnrichmentPage;
