import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import Grid from "@mui/material/Grid";
import { IconButton, Typography, Menu, MenuItem, MenuProps, Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import CAboxOpportunity from "../../../components/boxes/CAboxOpportunity";
import WidgetBox from "../../../components/WidgetBox";

import OppDetailTopBar from "./OppDetailTopBar";
import OpportunityResultList, { ChosenPage } from "./OpportunityResultList";
import { GetOppDetailPageGeneralInfo_opportunityFile } from "../../../__generated__/GetOppDetailPageGeneralInfo";
import CreatedOppsSameCa from "./CreatedOppsSameCa";
import "../../../components/pdfViewer/Viewer.scss";
import newPDFLayerSinglePage from "../../../components/pdfViewer/api/newPDFLayerSinglePage";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import TooltipOnSelect from "../../../components/TooltipOnSelect";
import queryString from "query-string";
import { OpenOppContext } from "../../../components/contextProviders/CreateNewOpportunityContext";
import SearchWizardContainer from "../../profile/Dialog/SearchWizardContainer";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";

interface Props {
    opportunity: GetOppDetailPageGeneralInfo_opportunityFile;
}

export interface NoteItem {
    id: string;
    note: string;
    year: string;
    amount: string;
}

// |             OppDetailTopbar + title             |
// |-------------------------------------------------|
// | CAboxOpportunity        |    CreatedOppsSameCa  |
// |                                                 |
// | OpportunityResultList   |    PDFviewer          |
// |                                                 |

// TODO: connect modal for chat, iconbutton 'Message'

const StyledMenu = (props: MenuProps) => (
    <Menu
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        PaperProps={{
            style: {
                maxHeight: 200,
                width: "7ch",
            },
        }}
        {...props}
    />
);
const OpportunityDetailPage: React.FC<Props> = ({ opportunity }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [numPages, setNumPages] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopup, setOpenPopup] = useState<string | false>(false);
    const [page, setPage] = useState<ChosenPage>({ page: 1, id: opportunity.pages.length ? opportunity.pages[0].id : "noID" });
    const [newOpp, setNewOpp] = useState<boolean>(false);
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const url = window.location.search;

    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * handle pagechange
     * Get number of page
     * find id of clicked page
     * @param num clicked number
     */
    const handlePageChange = (num: number) => {
        const idOfPage = opportunity.pages.find((page) => page.page === num)?.id;
        if (idOfPage !== undefined) {
            setPage({ page: num, id: idOfPage });
        }
    };

    //searchterms
    const [chosenWords, setChosenWords] = useState<string[]>([]);

    /**
     * Close page menu
     */
    const handleClose = () => {
        setAnchorEl(null);
        setNewOpp(false);
    };

    /**
     * @param event Open page menu
     */
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Initialize state from url parameters
     */
    useEffect(() => {
        // Initial setup using query parameters
        if (url) {
            const query = queryString.parse(url);
            // check if url has query params for page
            // If so, set state accordingly.
            if (typeof query.page === "string") {
                handlePageChange(parseInt(query.page));
            }
        }
        setNewOpp(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opportunity.temporary_link, url]);

    /**
     * Set opportunity id to pdf loader/viewer
     */
    useEffect(() => {
        const unique_array_of_terms = [...new Set(chosenWords)];
        if (opportunity.temporary_link !== null)
            newPDFLayerSinglePage({
                a: opportunity.temporary_link,
                setDisabled: setDisabled,
                pageNumber: page.page,
                setNumPages: setNumPages,
                chosenWords: unique_array_of_terms.map((i) => i.replace(/[^a-zA-Z-\s]/g, "")),
            });
    }, [opportunity.temporary_link, chosenWords, page.page]);

    const showCreateOpportunity = () => {
        setNewOpp(true);
    };

    return (
        <OpenOppContext.Provider value={{ newOpp, setNewOpp }}>
            <TooltipOnSelect>
                <div>
                    <Grid container spacing={3}>
                        {/*
                         *  TOPBAR
                         */}
                        <OppDetailTopBar
                            opp_name={opportunity.name}
                            opp_id={parseInt(opportunity.id)}
                            download_name={opportunity.original_name}
                            searchRuleData={opportunity.personalResults}
                            download_link={opportunity.temporary_link}
                            setChosenWords={setChosenWords}
                            onOpenSearchRuleWizard={() => setOpenPopup("opportunity")}
                        />

                        {/*
                         *  START GRID WITH WIDGETS
                         *  1. name of document
                         *  2. Contracting authority widgetbox
                         *  3. Box with created opportunities (based on CA)
                         */}
                        <Grid item xs={12}>
                            <Typography variant="h1">{opportunity.name}</Typography>
                        </Grid>
                        {/*
                         *  Contracting authority box with amount of documents
                         */}
                        <Grid item md={5} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <CAboxOpportunity ca_info={opportunity.contractingAuthority} />
                        </Grid>

                        {/*
                         * Box with created opportunities (based on CA)
                         */}
                        <Grid item md={7}>
                            <CreatedOppsSameCa ca_id={opportunity.contractingAuthority.id} file_type_id={opportunity.type?.id} />
                        </Grid>

                        {/*
                         *  List with opportunity results (Left Grid)
                         */}
                        <Grid item md={5} xs={12} id="page-with-results">
                            {page.id !== "noID" && (
                                <OpportunityResultList
                                    year_from={opportunity.year_from}
                                    onOpenSearchRuleWizard={() => setOpenPopup("opportunity")}
                                    opp_id={parseInt(opportunity.id)}
                                    active={page.page ? page.page : false || false}
                                    setActive={handlePageChange}
                                    page_id={page.id}
                                    pdfUrl={opportunity.temporary_link}
                                    onClose={handleClose}
                                />
                            )}
                        </Grid>

                        {/*
                         *  Pageviewer (Right Grid)
                         */}
                        <Grid item md={7} xs={12}>
                            <WidgetBox
                                highlight="green"
                                header={t("Page_view")}
                                padding={0}
                                actions={
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Tooltip title="Kans toevoegen" placement="top" style={{ marginRight: 4 }}>
                                            <Button
                                                size="small"
                                                color="primary"
                                                style={{ minWidth: 90 }}
                                                onClick={() => {
                                                    showCreateOpportunity();
                                                    trackevents({ category: "Opportunity", action: "Click-add-opportunity" });
                                                }}
                                            >
                                                Voeg toe
                                            </Button>
                                        </Tooltip>
                                        {/* prev button */}
                                        <IconButton
                                            disabled={page.page === 1 || disabled}
                                            size="small"
                                            onClick={() => {
                                                if (page.page > 1) {
                                                    handlePageChange(page.page - 1);
                                                }
                                            }}
                                        >
                                            <NavigateBefore />
                                        </IconButton>
                                        {/*
                                         * div with current page
                                         */}
                                        <div id="pdf-current-page">
                                            <Button size="small" onClick={handleClick} variant="text">
                                                {page.page}
                                            </Button>
                                            {/*
                                             * Menu with all pagenumbers of document (pdf)
                                             */}
                                            <StyledMenu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={() => setAnchorEl(null)}
                                            >
                                                {opportunity.pages.map((page) => {
                                                    return (
                                                        <MenuItem
                                                            key={page.id}
                                                            onClick={() => {
                                                                handlePageChange(page.page as number);
                                                                setAnchorEl(null);
                                                            }}
                                                        >
                                                            {page.page}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </StyledMenu>
                                        </div>
                                        {/* nextbutton */}
                                        <IconButton
                                            disabled={page.page === numPages || disabled}
                                            size="small"
                                            onClick={() => {
                                                if (page.page < numPages) {
                                                    handlePageChange(page.page + 1);
                                                }
                                            }}
                                        >
                                            <NavigateNext />
                                        </IconButton>
                                    </div>
                                }
                            >
                                {/*
                                 * container contains divs to generate pdf file
                                 */}
                                {/* <PdfContainer /> */}
                                <div id="pdfViewerDiv" style={{ height: "94vh", overflow: "auto" }}>
                                    <div id="viewer" className="pdfViewer" />
                                </div>
                            </WidgetBox>
                        </Grid>
                    </Grid>
                </div>
                {noMobile && <SearchWizardContainer openPopup={openPopup} setOpenPopup={setOpenPopup} editRule={null} />}
            </TooltipOnSelect>
        </OpenOppContext.Provider>
    );
};

export default OpportunityDetailPage;
