import React, { useCallback, useRef, useState } from "react";
import { Divider, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { getTasksWithFiltersVariables } from "../../../../__generated__/getTasksWithFilters";
import update from "immutability-helper";
import { debounce } from "lodash";

interface Props {
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
}

const SearchBar: React.FC<Props> = ({ activeFilters, setActiveFilters }) => {
    const [query, setQuery] = useState<string>(activeFilters.search ?? "");

    // debounce function to prevent request if characters is < 3 except 0
    const debounced = useRef(
        debounce(
            (value) =>
                value.length === 0
                    ? setActiveFilters(update(activeFilters, { search: { $set: value } }))
                    : value.length >= 3 && setActiveFilters(update(activeFilters, { search: { $set: value } })),
            1100
        )
    );

    const updateUserValue = useCallback(({ target: { value } }) => {
        setQuery(value);
        debounced.current(value);
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Paper square component="form" sx={{ padding: "2px 4px", display: "flex", alignItems: "center", width: "438px" }}>
                {/* Searchicon */}
                <Search style={{ margin: "10px 0px 10px 6px", color: "#707070" }} />

                {/* Inputfield query */}
                <InputBase
                    sx={{ marginLeft: (theme) => theme.spacing(1), flex: 1 }}
                    placeholder="Zoeken taak of project..."
                    // autoFocus={onlyMobile}
                    value={query} // Controlled field
                    onChange={updateUserValue}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {/*
                 * RESET BUTTON
                 */}
                <Tooltip placement="right" title="Zoekopdracht verwijderen" disableInteractive>
                    <IconButton
                        disabled={activeFilters.search === ""}
                        color="primary"
                        size="small"
                        aria-label="directions"
                        onClick={(e) => {
                            e.stopPropagation();

                            setActiveFilters(update(activeFilters, { $unset: ["search"] }));
                        }}
                    >
                        <Clear sx={{ color: activeFilters.search === "" ? "#b3b3b3" : "error.main" }} />
                    </IconButton>
                </Tooltip>
            </Paper>
        </div>
    );
};

export default SearchBar;
