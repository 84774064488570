import React from "react";
import FilterDropdownStringVersion from "../../../../components/TopFilterBar/FilterDropdownStringVersion";
import { Priority as Prio } from "../../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import { getTasksWithFilters_filterUserTasks_filters, getTasksWithFiltersVariables } from "../../../../__generated__/getTasksWithFilters";
import update from "immutability-helper";

interface Props {
    filterData: getTasksWithFilters_filterUserTasks_filters | null | undefined;
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
}

const Priority: React.FC<Props> = ({ filterData, activeFilters, setActiveFilters }) => {
    const { t } = useTranslation();

    const priorityOptions = filterData?.task_priorities?.map((priority) => {
        let label = "";
        let identifier = "";

        // Map priority string to the correct label and identifier
        switch (priority) {
            case "high":
                label = t("HIGH"); // Use translation function t() for HIGH
                identifier = Prio.HIGH;
                break;
            case "medium":
                label = t("MEDIUM"); // Use translation function t() for MEDIUM
                identifier = Prio.MEDIUM;
                break;
            case "low":
                label = t("LOW"); // Use translation function t() for LOW
                identifier = Prio.LOW;
                break;
            default:
                break;
        }

        return { label, identifier };
    });

    return (
        <FilterDropdownStringVersion
            disabled={false}
            // allowSearch
            label="Prioriteit"
            values={(activeFilters.task_priorities as string[]) ?? []}
            onChange={(selectedValues) => {
                if (selectedValues.length === 0) {
                    setActiveFilters(update(activeFilters, { $unset: ["task_priorities"] }));
                } else {
                    setActiveFilters(update(activeFilters, { task_priorities: { $set: selectedValues } }));
                }
            }}
            options={
                priorityOptions?.map((priority) => ({
                    key: priority.identifier as string,
                    label: priority.label as string,
                    id: priority.identifier as string,
                })) || []
            }
            width={200}
        />
    );
};

export default Priority;
