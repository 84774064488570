import React from "react";
import { useQuery } from "@apollo/client";
import { single_project_cas, single_project_casVariables } from "../../../../__generated__/single_project_cas";
import { SINGLE_PROJECT_CAS } from "../../../../graphql/queryDefProjects";
import { LightTooltip } from "../../columnView/projectcard/ConnectedEntitities";
import FaviconBox from "../../../../components/FaviconBox";
import { AccountTree } from "@mui/icons-material";

interface Props {
    project_id: string;
}

const CasTooltip: React.FC<Props> = ({ project_id }) => {
    const { data: casData } = useQuery<single_project_cas, single_project_casVariables>(SINGLE_PROJECT_CAS, {
        variables: {
            id: project_id,
        },
    });

    if (!casData || !casData.project || !casData.project.contractingAuthorities || casData.project.contractingAuthorities.length === 0) {
        return <AccountTree sx={{ width: "12px", color: "#b3b3b3", marginRight: "8px", marginLeft: "16px" }} />;
    }

    return (
        <>
            {casData?.project?.contractingAuthorities && casData.project.contractingAuthorities.length > 0 && (
                <LightTooltip
                    arrow
                    placement="bottom-start"
                    title={
                        <div>
                            {casData.project.contractingAuthorities.map((i) => {
                                return (
                                    <div
                                        key={i?.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: casData.project?.contractingAuthorities?.length === 1 ? "0px" : "6px",
                                        }}
                                    >
                                        <FaviconBox favicon={null} id={i?.id} name={i?.name} />
                                        <span style={{ fontSize: "14px", lineHeight: 1.1 }}>
                                            {i?.name} <br />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    <AccountTree sx={{ width: "12px", marginRight: "8px", marginLeft: "16px", cursor: "pointer" }} />
                </LightTooltip>
            )}
        </>
    );
};

export default CasTooltip;
