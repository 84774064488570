/* eslint-disable no-loop-func */
import React, { useState } from "react";
import moment from "moment";
import "./calendar.scss";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { SavedTenderEvents_getCalendarEvents } from "../../__generated__/SavedTenderEvents";
import TenderCalendarEvent from "./TenderCalendarEvent";
import UserCalendarEvent from "./UserCalendarEvent";
import TenderDateEvent from "./TenderDateEvent";

interface Props {
    currentMonth: string;
    selectedDate: string;
    data: (SavedTenderEvents_getCalendarEvents | null)[] | undefined;
}

const WeekView: React.FC<Props> = ({ currentMonth, selectedDate, data }) => {
    const { trackEvent } = useMatomo();
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    const trackCalendarToTender = (event: MatomoEvent) => {
        trackEvent(event);
    };

    // Navigate to detailpage
    const setId = (id: string) => {
        trackCalendarToTender({ category: "Calendar", action: "Go-from-calendar-to-tender", value: parseInt(id) });
    };

    return (
        <div>
            <div className="calendar">
                <Days currentWeek={currentMonth} />
                <Cells
                    currentMonth={currentMonth}
                    selectedDate={selectedDate}
                    setId={setId}
                    data={data}
                    activeTooltip={activeTooltip}
                    setActiveTooltip={setActiveTooltip}
                />
            </div>
        </div>
    );
};

/**
 * @returns  Create small bar with daynames
 */
const Days = ({ currentWeek }: any) => {
    const weekStart = moment(currentWeek).startOf("week").format();
    return (
        <div className="days row">
            <div style={{ width: "4%" }} />
            {
                // eslint-disable-next-line prefer-spread
                Array.apply(null, Array(7)).map(function (_, i) {
                    const day = moment(weekStart).isoWeekday(i + 1);
                    const test = day.format();

                    return (
                        <div className="col col-center" key={i}>
                            <Typography
                                style={{
                                    fontSize: "100%",
                                    fontWeight: moment().isSame(test, "day") ? 600 : 400,
                                    color: moment().isSame(test, "day") ? "#04325e !important" : "#7d7d7d !important",
                                }}
                            >
                                {moment(i, "e")
                                    .startOf("week")
                                    .isoWeekday(i + 1)
                                    .format("dddd")}{" "}
                                {day.format("DD/MM")}
                            </Typography>
                            {/* )} */}
                        </div>
                    );
                })
            }
        </div>
    );
};

/**
 * @returns Create cell for each day in a week
 */
const Cells = ({ currentMonth, selectedDate, setId, data, setActiveTooltip, activeTooltip }: any) => {
    const monthStart = moment(currentMonth).startOf("week").format();
    const monthEnd = moment(monthStart).endOf("week").format();
    const startDate = moment(monthStart).startOf("week").format();
    const endDate = moment(monthEnd).endOf("week").format();
    const [openId, setOpenId] = useState<string>("");

    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            days.push(
                <div
                    className={`col cell ${
                        !moment(day).isSame(currentMonth, "week")
                            ? "disabled"
                            : moment(day).isSame(selectedDate)
                            ? "selected"
                            : moment().isSame(day, "day")
                            ? "selected"
                            : ""
                    }`}
                    key={day}
                >
                    <div id={day} style={{ width: "100%", height: "100%" }}>
                        <Time
                            setOpenId={setOpenId}
                            openId={openId}
                            currentMonth={day}
                            selectedDate={selectedDate}
                            setId={setId}
                            data={data}
                            setActiveTooltip={setActiveTooltip}
                            activeTooltip={setActiveTooltip}
                        />
                    </div>
                </div>
            );
            day = moment(day).clone().add(1, "d").format();
        }

        rows.push(<div className="row">{days}</div>);

        days = [];
    }
    return (
        <div style={{ display: "flex", height: "79vh", overflow: "auto" }}>
            <div style={{ width: "4%" }}>
                <div>
                    {/* TODO: Create component for single item */}
                    <div style={{ width: "100%", height: "100%", marginTop: -10, borderRight: "1px solid #eee" }}>
                        <Hours />
                    </div>
                </div>
            </div>

            <div className="body" style={{ width: "96%" }}>
                {rows}
            </div>
        </div>
    );
};

/**
 * @returns  show event in matching timebox
 * Create item for every hour in a day Option to get data in day then in timeframe instead of timeframe directly
 */
const Time = ({ currentMonth, setId, data, setActiveTooltip, activeTooltip, openId, setOpenId }: any) => {
    const startDay = moment(currentMonth).startOf("day").format();
    const hours = [];

    let day = startDay;
    let dateValue = "";

    for (let i = 0; i < 24; i++) {
        dateValue = moment(day).format();
        const formattedDate = moment(day).format("HH");

        hours.push(
            <Box
                component={"div"}
                sx={{
                    height: "75px",
                    width: "100%",
                    position: "relative",
                    borderBottom: "1px solid #eee",
                    "&:hover": {
                        backgroundColor: "#04325e14 !important",
                    },
                }}
                // key={i}
                key={day}
            >
                {moment().isSame(day, "hour") && <span className="bg">{formattedDate}</span>}
                {/* TODO: Create component for single item */}

                <div style={{ width: "100%", height: "100%", paddingTop: "16px", overflow: "auto" }}>
                    <div style={{ zIndex: 97, position: "relative" }}>
                        {data
                            // .filter((i: any) => i.timeSignificant === true)
                            .map((item: SavedTenderEvents_getCalendarEvents, index: number) => {
                                // TenderEvent component
                                if (item.__typename === "TenderEvent" && moment(item.planned).isSame(dateValue, "hour")) {
                                    return (
                                        <TenderCalendarEvent
                                            event={item}
                                            setOpenId={setOpenId}
                                            key={item.id}
                                            openId={openId}
                                            setActiveTooltip={setActiveTooltip}
                                            setId={setId}
                                            activeTooltip={activeTooltip}
                                        />
                                    );
                                }

                                /**
                                 * Tender date - deadline / start / end
                                 */
                                if (item.__typename === "TenderDate" && moment(item.planned).isSame(dateValue, "hour")) {
                                    return (
                                        <TenderDateEvent
                                            openId={openId}
                                            setOpenId={setOpenId}
                                            key={item.id}
                                            event={item}
                                            setActiveTooltip={setActiveTooltip}
                                            setId={setId}
                                            activeTooltip={activeTooltip}
                                        />
                                    );
                                }
                                // UserEvent component
                                if (item.__typename === "UserEvent" && moment(item.start).isSame(dateValue, "hour")) {
                                    const id = `${item.title}${index}`;
                                    return (
                                        <UserCalendarEvent
                                            key={id}
                                            id={id}
                                            event={item}
                                            setActiveTooltip={setActiveTooltip}
                                            setId={setId}
                                            activeTooltip={activeTooltip}
                                        />
                                    );
                                }

                                return <></>;
                            })}
                    </div>
                </div>
            </Box>
        );
        day = moment(day).clone().add(1, "h").format();
    }

    return <div className="body">{hours}</div>;
};

const Hours = ({ currentMonth }: any) => {
    const startDay = moment(currentMonth).startOf("day").format();

    const hours = [];
    let day = startDay;
    let formattedHours = "";

    for (let i = 0; i < 24; i++) {
        formattedHours = moment(day).format("LT");

        hours.push(
            <Box
                component={"div"}
                id={i.toString()}
                sx={{
                    height: "75px",
                    width: "100%",
                    position: "relative",
                }}
                key={day}
            >
                <Box
                    component={"span"}
                    sx={{
                        display: i === 0 ? "none" : "block",
                        fontSize: "82.5%",
                        fontWeight: 700,
                        marginLeft: "8px",
                        color: moment().isAfter(day, "hour") ? "rgb(216, 216, 216)" : moment().isSame(day, "hour") ? "#04325e" : "#000",
                    }}
                >
                    {formattedHours}
                </Box>
            </Box>
        );
        day = moment(day).clone().add(1, "h").format();
    }

    return <div className="body">{hours}</div>;
};
export default WeekView;
