import React, { useState } from "react";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Condition, ConditionGroup, LogicalOperator, useQueryBuilder } from "./QueryBuilderContext";
import RemoveCondition from "./RemoveCondition";
import NearBlock from "./NearBlock";

interface Props {
    condition: Condition;
    logicalOperators: LogicalOperator[];
    groupId: string;
    group: ConditionGroup;
    handleNearCondition: (groupId: string, conditionId: string) => void;
    updateConditionOperator: (groupId: string, conditionId: string, newOperator?: LogicalOperator, secondOperator?: LogicalOperator) => void;
    updateConditionKeyword: (groupId: string, conditionId: string, newKeyword: string) => void;
    removeCondition: (groupId: string, conditionId: string) => void;
    handleProximityChange: (groupId: string, conditionId: string, newProximity: number | undefined) => void;
    handleNearTermChange: (groupId: string, conditionId: string, newNearTerm: string) => void;
    handleRemoveNearTerm: (groupId: string, conditionId: string) => void;
    isLastConditionAndNotActive: boolean;
    highlightedConditionId: string | null;
    activeGroup: string;
    nextKeyword: Condition | undefined;
    isFirstCondition: boolean; // New prop
    themeColor: string;
}

const BuilderBlock: React.FC<Props> = ({
    condition,
    logicalOperators,
    groupId,
    group,
    themeColor,
    handleNearCondition,
    updateConditionOperator,
    removeCondition,
    activeGroup,
    nextKeyword,
    isFirstCondition,
    highlightedConditionId,
    isLastConditionAndNotActive,
    updateConditionKeyword,
    handleProximityChange,
    handleNearTermChange,
    handleRemoveNearTerm,
}) => {
    const { t } = useTranslation();
    const [anchorElupdate, setAnchorElupdate] = useState<null | HTMLElement>(null);
    const [firstOrSecond, setFirstORSecond] = useState<"first" | "second">("first");
    const openupdate = Boolean(anchorElupdate);
    // Check if "OR" is selected as an operator in any condition
    const isORSelected =
        group.conditions.length > 1 &&
        group.conditions.some((condition) => condition.operator?.text === "OR" || condition.secondOperator?.text === "OR");
    const { getOperatorIcon } = useQueryBuilder();

    // Check if "AND" or "NOT" is selected as an operator in any condition
    const isANDorNOTSelected =
        group.conditions.length > 1 &&
        group.conditions.some((condition) => {
            return (
                condition.operator?.text === "AND" ||
                condition.operator?.text === "NOT" ||
                condition.secondOperator?.text === "AND" ||
                condition.secondOperator?.text === "NOT"
            );
        });

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "0" }}>
            {/* Div contains the drag indicator and a chosen searchterm. */}
            {isFirstCondition && (
                <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 4px 4px 8px",
                            backgroundColor: "#ffffff",
                            borderRadius: "4px",
                            width: "fit-content",
                            height: "30px",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: condition.keyword?.includes("*") || condition.keyword?.includes("?") ? "#c0392b" : themeColor,
                            }}
                        >
                            {condition.keyword}
                        </Typography>
                        {/* BUTTON TO REMOVE CONDITION */}
                        <RemoveCondition onRemove={() => removeCondition(groupId, condition.id)} active={groupId === activeGroup} />
                    </div>
                    {condition.operator?.text === "NEAR" ? (
                        <NearBlock
                            themeColor={themeColor}
                            groupId={groupId}
                            condition={condition}
                            activeGroup={activeGroup}
                            highlightedConditionId={highlightedConditionId}
                            removeNearTerm={() => handleRemoveNearTerm(groupId, condition.id)}
                            handleNearCondition={handleNearCondition}
                            handleNearTermChange={handleNearTermChange}
                            handleProximityChange={handleProximityChange}
                            updateConditionKeyword={updateConditionKeyword}
                            updateConditionOperator={updateConditionOperator}
                            isORSelected={isORSelected}
                            isANDorNOTSelected={isANDorNOTSelected}
                        />
                    ) : null}
                </div>
            )}

            {/* Input for the first search term */}
            {!isLastConditionAndNotActive ? (
                <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {condition.operator?.text === "NEAR" ? (
                        <>
                            {(condition.nearTerm !== "" || nextKeyword) && (
                                <Button
                                    size="small"
                                    onClick={(e) => {
                                        setFirstORSecond("second");
                                        setAnchorElupdate(e.currentTarget);
                                    }}
                                    variant="contained"
                                    startIcon={getOperatorIcon(condition.secondOperator?.text as string)}
                                >
                                    {t(condition.secondOperator?.text as string)}
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button
                            size="small"
                            onClick={(e) => {
                                setFirstORSecond("first");
                                setAnchorElupdate(e.currentTarget);
                            }}
                            variant="contained"
                            startIcon={getOperatorIcon(condition.operator?.text as string)}
                        >
                            {t(condition.operator?.text as string)}
                        </Button>
                    )}
                    {nextKeyword ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "8px",
                                    padding: "4px 4px 4px 8px",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "4px",
                                    height: "30px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ color: nextKeyword.keyword?.includes("*") || nextKeyword.keyword?.includes("?") ? "#c0392b" : themeColor }}
                                >
                                    {nextKeyword.keyword}
                                </Typography>
                                {/* BUTTON TO REMOVE CONDITION */}
                                <RemoveCondition onRemove={() => removeCondition(groupId, nextKeyword.id)} active={groupId === activeGroup} />
                            </div>
                            {nextKeyword.operator?.text === "NEAR" ? (
                                <NearBlock
                                    themeColor={themeColor}
                                    groupId={groupId}
                                    condition={nextKeyword}
                                    activeGroup={activeGroup}
                                    highlightedConditionId={highlightedConditionId}
                                    removeNearTerm={() => handleRemoveNearTerm(groupId, nextKeyword.id)}
                                    handleNearCondition={handleNearCondition}
                                    handleNearTermChange={handleNearTermChange}
                                    handleProximityChange={handleProximityChange}
                                    updateConditionKeyword={updateConditionKeyword}
                                    updateConditionOperator={updateConditionOperator}
                                    isORSelected={isORSelected}
                                    isANDorNOTSelected={isANDorNOTSelected}
                                />
                            ) : null}
                        </>
                    ) : condition.operator?.text === "NEAR" && condition.nearTerm === "" ? (
                        <></>
                    ) : isLastConditionAndNotActive ? (
                        <></>
                    ) : (
                        <Typography
                            variant="button"
                            fontStyle="italic"
                            sx={{ marginLeft: "16px", marginTop: "2px", color: "#b8b8b8", fontSize: "0.7rem", borderBottom: "1px dashed #b8b8b8" }}
                        >
                            {t("Add_Searchterm")}
                        </Typography>
                    )}
                </div>
            ) : (
                <div style={{ height: "38.75px" }} />
            )}

            <Menu open={openupdate} onClose={() => setAnchorElupdate(null)} anchorEl={anchorElupdate}>
                {logicalOperators.map((operator, index) => {
                    // Determine whether the current operator should be disabled
                    const isDisabled = (operator.text === "AND" || operator.text === "NOT") && isORSelected;
                    const isOrDisabled = operator.text === "OR" && isANDorNOTSelected;

                    // Disable AND and NOT if the group operator is already NOT
                    const isAndOrNotDisabled = (operator.text === "AND" || operator.text === "NOT") && group.operator?.text === "NOT";

                    const isNearDisabled =
                        operator.text === "NEAR" &&
                        // condition.keyword?.includes("?") || condition.keyword?.includes("*") ||
                        condition.keyword?.includes(" ");

                    return (
                        <MenuItem
                            key={index}
                            value={operator.text}
                            disabled={isDisabled || isOrDisabled || isNearDisabled || isAndOrNotDisabled}
                            onClick={() => {
                                if (operator.text === "NEAR") {
                                    // If the operator is NEAR, handle it differently
                                    handleNearCondition(groupId, condition.id); // Call your NEAR handler
                                } else {
                                    if (firstOrSecond === "second") {
                                        updateConditionOperator(groupId, condition.id, undefined, operator as LogicalOperator);
                                    } else {
                                        // For other operators, just update the condition operator
                                        updateConditionOperator(groupId, condition.id, operator as LogicalOperator);
                                    }
                                }
                                setAnchorElupdate(null); // Close the menu
                            }}
                        >
                            <ListItemIcon>{operator.icon}</ListItemIcon>
                            <ListItemText>{t(operator.text)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default BuilderBlock;
