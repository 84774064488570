/* eslint-disable no-loop-func */
import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import "./calendar.scss";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../models/MatomoEvent";
import { SavedTenderEvents_getCalendarEvents } from "../../__generated__/SavedTenderEvents";
import TenderCalendarEvent from "./TenderCalendarEvent";
import UserCalendarEvent from "./UserCalendarEvent";
import TenderDateEvent from "./TenderDateEvent";

interface Props {
    currentMonth: string;
    selectedDate: string;
    data: (SavedTenderEvents_getCalendarEvents | null)[] | undefined;
}

const DayView: React.FC<Props> = ({ currentMonth, selectedDate, data }) => {
    const { trackEvent } = useMatomo();
    /**
     * @param event event to post to matomo
     */
    const trackCalendarToTender = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Option to add handlers to this function
     * @param id clicked tender in event
     */
    const setId = (id: string) => {
        trackCalendarToTender({ category: "Calendar", action: "Go-from-calendar-to-tender", value: parseInt(id) });
    };

    return (
        <div>
            <div className="calendar">
                <Days currentMonth={currentMonth} />
                <Cells currentMonth={currentMonth} selectedDate={selectedDate} setId={setId} data={data} />
            </div>
        </div>
    );
};

/**
 * @returns mapping of days as topdiv
 */
const Days = ({ currentMonth }: any) => {
    return (
        <div className="days rowDay">
            <div className="col col-center">{moment(currentMonth).format("dddd")}</div>
        </div>
    );
};

/**
 * Create div foreach hour per day
 * Mapping of event in cells to place event in matching hour box
 * timeless items will be placed underneath topbar
 */
const Cells = ({ currentMonth, setId, data }: any) => {
    const theme = useTheme();
    const [activeTooltip, setActiveTooltip] = useState<string | false>(false);
    const dayStart = moment(currentMonth).startOf("day").format();
    const dayEnd = moment(dayStart).endOf("day").format();
    const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [openId, setOpenId] = useState<string>("");

    const { t } = useTranslation();

    const hours = [];
    let day = dayStart;
    let formattedDate = "";
    let dateValue = "";

    while (day <= dayEnd) {
        for (let i = 0; i < 24; i++) {
            formattedDate = moment(day).format("HH");
            dateValue = moment(day).format();
            hours.push(
                <Box
                    component={"div"}
                    className="rowDay"
                    sx={{
                        backgroundColor: moment().isSame(day, "hour") ? "#f9f9f9" : "white",
                        "&:hover": {
                            backgroundColor: "#04325e14 !important",
                        },
                    }}
                    key={day}
                >
                    <div style={{ width: onlyMobile ? "10%" : "4%", borderRight: "1px solid #eee" }}>
                        <span
                            style={{
                                fontSize: "82.5%",
                                fontWeight: 700,
                                marginLeft: "8px",
                                color: moment().isAfter(day, "hour") ? "rgb(216, 216, 216)" : moment().isSame(day, "hour") ? "#04325e" : "#000",
                            }}
                        >
                            {formattedDate}
                            {t("Calendar.hour")}
                        </span>
                    </div>
                    <div style={{ height: "100%", marginBottom: "24px", marginTop: "4px" }}>
                        {/*
                         * Calendar events with timestamps
                         */}
                        <div style={{ zIndex: 96, position: "relative", cursor: "pointer" }}>
                            {data
                                // .filter((i: any) => i.timeSignificant === true)
                                .map((item: SavedTenderEvents_getCalendarEvents, index: number) => {
                                    // TenderEvent component
                                    if (item.__typename === "TenderEvent" && moment(item.planned).isSame(dateValue, "hour")) {
                                        return (
                                            <TenderCalendarEvent
                                                openId={openId}
                                                setOpenId={setOpenId}
                                                key={item.id}
                                                event={item}
                                                setActiveTooltip={setActiveTooltip}
                                                setId={setId}
                                                activeTooltip={activeTooltip}
                                            />
                                        );
                                    }

                                    /**
                                     * Tender date - deadline / start / end
                                     */
                                    if (item.__typename === "TenderDate" && moment(item.planned).isSame(dateValue, "hour")) {
                                        return (
                                            <TenderDateEvent
                                                openId={openId}
                                                setOpenId={setOpenId}
                                                key={item.id}
                                                event={item}
                                                setActiveTooltip={setActiveTooltip}
                                                setId={setId}
                                                activeTooltip={activeTooltip}
                                            />
                                        );
                                    }

                                    // UserEvent component
                                    if (item.__typename === "UserEvent" && moment(item.start).isSame(dateValue, "hour")) {
                                        const id = `${item.title}${index}`;
                                        return (
                                            <UserCalendarEvent
                                                key={id}
                                                id={id}
                                                event={item}
                                                setActiveTooltip={setActiveTooltip}
                                                setId={setId}
                                                activeTooltip={activeTooltip}
                                            />
                                        );
                                    }

                                    return <></>;
                                })}
                        </div>
                    </div>
                </Box>
            );
            day = moment(day).add(1, "h").format();
        }
    }

    return (
        <div className="body" style={{ maxHeight: "79vh", overflow: "auto" }}>
            {hours}
        </div>
    );
};
export default DayView;
