import React, { useEffect } from "react";
import { Autocomplete, Checkbox, CircularProgress, TextField, Tooltip } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TASK_LIBRARY } from "../../../../graphql/queryDefTasks";
import { taskLibrary } from "../../../../__generated__/taskLibrary";
import { LibraryAdd, LibraryAddCheck } from "@mui/icons-material";

interface Props {
    setTaskValue: React.Dispatch<React.SetStateAction<string>>;
    saveToLibrary: React.Dispatch<React.SetStateAction<boolean>>;
    addTaskToLibraryBln: boolean;
    newTask: string;
}

const TaskAutocompletion: React.FC<Props> = ({ setTaskValue, saveToLibrary, addTaskToLibraryBln, newTask }) => {
    /**
     * Define the GQL query
     */
    const { data, loading } = useQuery<taskLibrary>(GET_TASK_LIBRARY, {
        fetchPolicy: "network-only",
    });

    // Extract task descriptions from the data
    const taskDescriptions = data?.library?.map((task) => task.task_description) || [];
    const checkIfTaskExists = (task: string) => {
        return taskDescriptions.includes(task);
    };
    useEffect(() => {
        if (taskDescriptions.includes(newTask)) {
            saveToLibrary(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newTask]);

    return (
        <Autocomplete
            fullWidth
            size="small"
            freeSolo // Allows input beyond suggestions
            options={taskDescriptions}
            value={newTask}
            onInputChange={(event, newInputValue) => setTaskValue(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Wat moet er gedaan worden?"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        startAdornment: (
                            <>
                                {/* TOOLTIP TO ADD TASK TO LIBRARY WHEN CHECKED */}
                                <Tooltip title="Toevoegen aan Mijn taken (Sjabloon beheer)" placement="top-start">
                                    <Checkbox
                                        icon={<LibraryAdd />}
                                        checkedIcon={<LibraryAddCheck sx={{ color: "#508c79" }} />}
                                        disabled={newTask === "" || checkIfTaskExists(newTask)}
                                        checked={addTaskToLibraryBln}
                                        onChange={(e) => saveToLibrary(e.target.checked)}
                                        size="small"
                                        sx={{ padding: "0px 16px 0px 8px" }}
                                    />
                                </Tooltip>
                                {params.InputProps?.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => <li {...props}>{option}</li>}
        />
    );
};

export default TaskAutocompletion;
