import React, { useState } from "react";
import { Disable } from "react-disable";
import { UPDATE_TASK_USER } from "../../../../graphql/mutationDefProjects";
import { updateTaskUsers, updateTaskUsersVariables } from "../../../../__generated__/updateTaskUsers";
import { useMutation, useQuery } from "@apollo/client";
import { SINGLE_PROJECT_TASKS } from "../../../../graphql/queryDefProjects";
import { single_project_active_users, single_project_active_usersVariables } from "../../../../__generated__/single_project_active_users";
import { SINGLE_PROJECT_ACTIVE_USERS } from "../../../../graphql/queryDefProject";
import { Avatar, Box, Divider, IconButton, ListItemAvatar, ListItemButton, ListItemText, Popover, Radio, Tooltip, Typography } from "@mui/material";
import { PersonAddAltRounded } from "@mui/icons-material";
import truncate from "truncate";
import { getTasksWithFilters_filterUserTasks_data } from "../../../../__generated__/getTasksWithFilters";
import { GET_TASKS_WITH_FILTERS } from "../../../../graphql/queryDefTasks";

interface Props {
    task: getTasksWithFilters_filterUserTasks_data;
    refetch: () => void;
}

const TaskUser: React.FC<Props> = ({ task, refetch }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // Change description of task
    const [saveTaskUser] = useMutation<updateTaskUsers, updateTaskUsersVariables>(UPDATE_TASK_USER);
    const { data } = useQuery<single_project_active_users, single_project_active_usersVariables>(SINGLE_PROJECT_ACTIVE_USERS, {
        variables: {
            id: task.project?.id as string,
        },
    });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /**
     * Update user of single task
     * @param taskId id of task
     * @param boolean bolean to set task to
     */
    const onChangeUser = async (taskId: string, user_id: string) => {
        try {
            await saveTaskUser({
                variables: {
                    id: taskId,
                    user_ids: [user_id],
                },
                refetchQueries: [SINGLE_PROJECT_TASKS, GET_TASKS_WITH_FILTERS],
                onCompleted: () => {
                    handleClose();
                    refetch();
                },
            });
        } catch (e) {}
    };

    return (
        <Disable
            disabledOpacity={task.deleted_at ? 0.6 : data?.project?.activeUsers?.length === 1 ? 1 : 0.7}
            disabled={
                task.deleted_at || task.task_completed
                // || data?.project?.activeUsers?.length === 1
            }
        >
            {task.users === null || task.users.length === 0 ? (
                <Tooltip disableInteractive title="Gebruiker toewijzen" placement="right">
                    <IconButton style={{ padding: 0 }} size={"small"} onClick={(e) => handleClick(e)}>
                        <Avatar
                            sx={{
                                width: "28px",
                                height: "28px",
                                fontSize: "0.65rem",
                            }}
                        >
                            <PersonAddAltRounded sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(3) }} />
                        </Avatar>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip disableInteractive title="Gebruiker wijzigen" placement="right">
                    <IconButton style={{ padding: 0 }} size={"small"} onClick={(e) => handleClick(e)}>
                        {task.users?.map((user) => {
                            return (
                                <Avatar
                                    key={user.id}
                                    sx={{
                                        width: "28px",
                                        height: "28px",
                                    }}
                                >
                                    <Typography sx={{ fontSize: "0.8rem" }}>
                                        {user.employee.name?.slice(0, 1)}
                                        {user.employee.familyname?.slice(0, 1)}
                                    </Typography>
                                </Avatar>
                            );
                        })}
                    </IconButton>
                </Tooltip>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
            >
                <div style={{ maxHeight: "310px", overflow: "auto" }}>
                    <Box>
                        {data?.project?.activeUsers?.map((user) => {
                            /**
                             * UserListItem component for details of user with edit and delete option
                             */
                            return (
                                <>
                                    <ListItemButton key={user.id} onClick={() => onChangeUser(task.id, user.id)} dense disableRipple>
                                        {/* Listitem with userdetails. */}
                                        <ListItemAvatar sx={{ mt: 0, minWidth: 0, marginRight: "16px" }}>
                                            <Avatar sx={{ width: "28px", height: "28px" }}>
                                                <Typography sx={{ fontSize: "0.8rem" }}>
                                                    {user.employee.name?.slice(0, 1)}
                                                    {user.employee.familyname?.slice(0, 1)}
                                                </Typography>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            /**
                                             * Combination of user givenname and familyname. line through when user is inactive
                                             */
                                            primary={
                                                <>
                                                    <Typography>{truncate(user.employee.name ? user.employee.name : "", 40)}</Typography>
                                                </>
                                            }
                                        />

                                        <Radio
                                            checked={task.users !== null && task.users[0]?.id === user.id}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "A" }}
                                            size="small"
                                        />

                                        {/* <Checkbox
                                           size="small"
                                           edge="start"
                                           disableRipple
                                           checked={chosenUserId !== null && chosenUserId[0]?.id === user.id}
                                           inputProps={{ "aria-label": "primary checkbox" }}
                                       /> */}
                                    </ListItemButton>
                                    <Divider />
                                </>
                            );
                        })}
                    </Box>
                </div>
            </Popover>
        </Disable>
    );
};

export default TaskUser;
