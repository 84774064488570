import React, { useState } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useIncognito } from "./IncognitoContext";

interface Props {
    a?: number;
}

const IncognitoMode: React.FC<Props> = (props) => {
    const [disabled] = useState<boolean>(true);
    const { helpingUser, setHelpingUser } = useIncognito();

    return (
        <Tooltip
            title={"Incognito - nog niet beschikbaar"}
            placement="right"
            PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -18], // Adjust the first value to move left (-) or right (+)
                        },
                    },
                ],
            }}
        >
            <div>
                <IconButton
                    onClick={() => setHelpingUser(helpingUser ? null : "Gerrie Kepser")}
                    disabled={disabled}
                    sx={{ height: "48px", width: "48px" }}
                >
                    {helpingUser ? <Visibility htmlColor={"#e6e6e65c"} /> : <VisibilityOff htmlColor={"#e6e6e6"} />}
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default IncognitoMode;
