import React, { useEffect, useState } from "react";
import { List, ListItem, Paper, Skeleton, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TASK_LIBRARY } from "../../../../graphql/queryDefTasks";
import { taskLibrary } from "../../../../__generated__/taskLibrary";
// import DeleteTaskBtn from "./DeleteTaskBtn";
import EditableText from "./EditableText";
import { updateTaskInLibrary, updateTaskInLibraryVariables } from "../../../../__generated__/updateTaskInLibrary";
import { UPDATE_TASK_IN_LIBRARY } from "../../../../graphql/mutationDefTasks";
import Searchbar from "./Searchbar";
import { taskTemplates_templates } from "../../../../__generated__/taskTemplates";
import AddTaskToTemplate from "./AddTaskToTemplate";
import HardCodedTasks from "../HardCodedTasks";
import TaskSwitch from "../TaskSwitch";

interface Props {
    template: taskTemplates_templates | null;
    refetchTemplate: () => void;
}

const TaskContainer: React.FC<Props> = ({ template, refetchTemplate }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [showExamples, setShowExamples] = useState(false);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [saveTaskDescription, { loading: loadSave }] = useMutation<updateTaskInLibrary, updateTaskInLibraryVariables>(UPDATE_TASK_IN_LIBRARY);
    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangesaveTaskDescription = async (taskId: string, description: string) => {
        try {
            await saveTaskDescription({
                variables: {
                    id: taskId,
                    description: description,
                },
                onCompleted: (d) => {
                    if (d && d.updateTaskInLibrary) {
                        refetch();
                    }
                },
            });
        } catch (e) {}
    };

    /**
     * Define the GQL query
     */
    const {
        data,
        loading: loadData,
        refetch,
    } = useQuery<taskLibrary>(GET_TASK_LIBRARY, {
        fetchPolicy: "network-only",
    });

    const results = !searchQuery
        ? data?.library // Use directly from query
        : data?.library?.filter((item) => item.task_description.toLowerCase().includes(searchQuery.toLowerCase()));

    // Handle initial load
    useEffect(() => {
        if (!hasInitialized && data?.library?.length === 0) {
            setShowExamples(true); // Show examples on first load if library is empty
            setHasInitialized(true); // Mark as initialized
        }
    }, [data?.library?.length, hasInitialized, setShowExamples]);

    // Prevent switching tabs when examples are added
    useEffect(() => {
        if (data?.library?.length !== undefined && data?.library?.length > 0 && showExamples) {
            // Do nothing, keep showExamples true
        }
    }, [data?.library?.length, showExamples]);

    if (loadData) {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ height: "34px", display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    <Typography fontWeight={500} variant="h5">
                        Sjabloon beheer
                    </Typography>
                </div>

                {/* SEARCHBAR */}
                <Searchbar setSearchQuery={setSearchQuery} searchQuery={searchQuery} refetch={refetch} />
                <TaskSwitch myTasksLength={data?.library?.length ?? 0} setShowExamples={setShowExamples} showExamples={showExamples} loading={true} />

                {/* LIST */}
                <List dense sx={{ padding: 0 }}>
                    {[1, 2, 3, 4, 5].map((i) => {
                        return (
                            <Paper key={i} variant="outlined" sx={{ marginBottom: "4px", padding: "4px 8px" }}>
                                <ListItem sx={{ padding: 0, display: "flex", justifyContent: "space-between" }}>
                                    <Typography>
                                        <Skeleton width={250} />
                                    </Typography>
                                    {/* <Box>
                                        <Skeleton variant="circular" width={34} height={34} />
                                    </Box> */}
                                </ListItem>
                            </Paper>
                        );
                    })}
                </List>
            </div>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ height: "auto", flexShrink: 0 }}>
                <div style={{ height: "34px", display: "flex", alignItems: "center", marginBottom: "4px" }}>
                    <Typography fontWeight={500} variant="h5">
                        Sjabloon beheer
                    </Typography>
                </div>
                {/* SEARCHBAR */}
                <Searchbar setSearchQuery={setSearchQuery} searchQuery={searchQuery} refetch={refetch} />
                <TaskSwitch myTasksLength={data?.library?.length ?? 0} setShowExamples={setShowExamples} showExamples={showExamples} />
            </div>
            <div style={{ flex: 1, overflow: "auto", paddingRight: "16px" }}>
                {showExamples ? (
                    <HardCodedTasks existingTasks={data?.library ?? []} refetch={refetch} />
                ) : (
                    <List dense sx={{ padding: 0 }}>
                        {results?.map((task) => {
                            return (
                                <Paper key={task.id} variant="outlined" sx={{ marginBottom: "4px", padding: "4px 8px" }}>
                                    <ListItem key={task.id} sx={{ padding: 0, display: "flex", justifyContent: "space-between", height: "30px" }}>
                                        <EditableText
                                            tasktext={task.task_description}
                                            id={task.id}
                                            updateText={onChangesaveTaskDescription}
                                            loading={loadSave}
                                        />
                                        {template !== null && (
                                            <AddTaskToTemplate
                                                template_id={template?.id}
                                                template_name={template?.template_name}
                                                task_id={task.id}
                                                refetchTemplate={refetchTemplate}
                                            />
                                        )}
                                        {/* {template === null && <DeleteTaskBtn id={task.id} />} */}
                                    </ListItem>
                                </Paper>
                            );
                        })}
                    </List>
                )}
            </div>
        </div>
    );
};

export default TaskContainer;
