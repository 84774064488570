import { Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from "@mui/material";
import React from "react";
import EditableTaskField from "../EditableTaskField";
import { Clear } from "@mui/icons-material";
import { Disable } from "react-disable";
import TaskPriorityChip from "./TaskPriorityChip";
import UserForTask from "./UserForTask";
import TaskDatePicker from "./TaskDatePicker";
import moment from "moment";
import { single_project_tasks_project_tasks } from "../../../../__generated__/single_project_tasks";

interface Props {
    task: single_project_tasks_project_tasks;
}

const RemovedTask: React.FC<Props> = ({ task }) => {
    return (
        <React.Fragment>
            <ListItem disablePadding dense>
                {/*
                 * Icon to show
                 * - Disable state if task is not for current user
                 */}
                <ListItemIcon>
                    <Tooltip title={`Verwijderd op ${moment(task.deleted_at).format("L")}`} placement="right">
                        <div>
                            <Checkbox
                                disabled
                                icon={<Clear sx={{ color: "#c0392b" }} fontSize="small" />}
                                checkedIcon={<Clear sx={{ color: "#c0392b" }} fontSize="small" />}
                                edge="start"
                                size="small"
                                checked={task.task_completed}
                                disableRipple
                                sx={{ marginLeft: "4px" }}
                            />
                        </div>
                    </Tooltip>
                </ListItemIcon>
                {/* Tasks text */}
                <ListItemText
                    sx={{ textDecoration: "line-through" }}
                    primary={
                        <EditableTaskField
                            disabled
                            tasktext={task.task_description}
                            setTasktext={(txt) => {
                                console.log();
                            }}
                            taskID={task.id}
                            bossOfProject={false}
                        />
                    }
                />
                {/* DatePicker */}
                <Disable disabledOpacity={0.6} disabled>
                    <TaskDatePicker
                        date={task.task_deadline !== null ? moment(task.task_deadline) : null}
                        updateDate={() => {
                            console.log();
                        }}
                    />
                </Disable>{" "}
                <div style={{ width: "8px" }} />
                {/* Users linked to task */}
                <Disable disabledOpacity={0.6} disabled>
                    <UserForTask chosenUserId={task.users as any} task_id={task.id} />
                </Disable>{" "}
                <div style={{ width: "8px" }} />
                {/* Prioritychip  */}
                <Disable disabledOpacity={0.6} disabled>
                    <TaskPriorityChip dot={false} disabled priority={task.task_priority} />
                </Disable>
                {/* Delete task 
                // TODO add mutation to delete a task
                */}
                <ListItemSecondaryAction>
                    <div style={{ width: "30px" }} />{" "}
                    {/* <Tooltip placement="top" title="Verwijder taak" disableInteractive>
                        <IconButton sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }} size="small" edge="end" aria-label="delete">
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                </ListItemSecondaryAction>
            </ListItem>
        </React.Fragment>
    );
};

export default RemovedTask;
