import React, { useEffect, useState } from "react";
import {
    Avatar,
    AvatarGroup,
    Box,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    ListItemButton,
    ListItemText,
    Popover,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { PersonAddAltRounded, PriorityHigh, SafetyDivider, Search } from "@mui/icons-material";
import { User } from "../Avatars";
import truncate from "truncate";
import { Task } from "./Tasks";
import moment from "moment";

interface Props {
    users: User[];
    setTemporaryTasks: React.Dispatch<React.SetStateAction<Task[]>>;
    task: Task;
    updateUsers: (users: User[]) => void;
    highlight?: boolean;
}

const TemplateEditModeSingleTask: React.FC<Props> = ({ users, setTemporaryTasks, task, updateUsers, highlight }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [openPopper, setOpenPopper] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [arrayWithUsersOrTeamsToShare, setArrayWithUsersOrTeamsToShare] = useState<User[]>([]);

    const createTaskForEachUser = () => {
        // Generate new tasks for each user or team
        const newTasks = arrayWithUsersOrTeamsToShare.map((user: User) => ({
            id: `${task.id}-${user.id}`, // Generate a unique ID
            task_description: task.task_description,
            task_deadline: task.task_deadline === null ? null : moment(task.task_deadline).toDate(),
            task_completed: false,
            task_priority: task.task_priority,
            users: [{ id: user.id, name: user.name, initials: user.initials }], // Assign single user ID
        }));

        // Update the tasks array with the new tasks
        setTemporaryTasks((prevTasks) => {
            // Find the index of the original task
            const taskIndex = prevTasks.findIndex((t) => t.id === task.id);

            if (taskIndex === -1) {
                // Task not found; fallback to just appending new tasks
                return [...prevTasks, ...newTasks];
            }

            // Remove the original task and insert the new tasks at the same position
            const updatedTasks = [...prevTasks];
            updatedTasks.splice(taskIndex, 1, ...newTasks); // Replace the original task with the new ones

            return updatedTasks;
        });
    };

    useEffect(() => {
        if (users.length === 1) {
            setArrayWithUsersOrTeamsToShare(users);
        }
    }, [users]);

    /**
     * @param e event object
     * @param value clicked user/team
     * @param taskId the task to update
     * @returns updated tasks with the user/team toggle
     */
    const handleToggleAndUpdateTask = (e: React.MouseEvent, value: User, taskId: string) => {
        e.stopPropagation();

        setArrayWithUsersOrTeamsToShare((prevArray) => {
            const currentIndex = prevArray.findIndex((i) => i.id === value.id);

            let newArray: User[];
            if (currentIndex === -1) {
                newArray = [...prevArray, value]; // Add user/team
            } else {
                newArray = prevArray.filter((i) => i.id !== value.id); // Remove user/team
            }

            // Update the task's users based on the new array
            updateUsers(newArray);

            return newArray; // Return the updated array
        });
    };

    /**
     * handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */
    const handleSearchUser = (event: any) => {
        event.stopPropagation();
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    /**
     * Open submenu to share or delete searchline
     */
    const handleClickSub = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenPopper(true);
    };

    /**
     * Close submenu.
     */
    const handleCloseSub = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
        setOpenPopper(false);
    };

    const results = !searchTerm ? users : users.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    return (
        <div style={{ width: "110px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {task.users === null || task.users.length <= 1 ? (
                <div />
            ) : (
                <Tooltip title="Taak splitsen" placement="left" disableInteractive>
                    <div>
                        <IconButton size="small" disabled={task.users === null || task.users.length <= 1} onClick={createTaskForEachUser}>
                            <SafetyDivider fontSize="small" />
                        </IconButton>
                    </div>
                </Tooltip>
            )}
            <Tooltip title="Gebruikers" placement="left" disableInteractive>
                {users.length === 1 ? (
                    <AvatarGroup
                        onClick={(e) => handleClickSub(e as any)}
                        max={3}
                        sx={{
                            cursor: "pointer",
                            "& .MuiAvatar-root": {
                                border: "1px solid #ffffff",
                                width: (theme) => theme.spacing(3.5),
                                height: (theme) => theme.spacing(3.5),
                                fontSize: "0.8rem",
                            },
                        }}
                    >
                        {task.users?.map((user) => {
                            return <Avatar key={user.id}>{user.initials}</Avatar>;
                        })}
                    </AvatarGroup>
                ) : (
                    <AvatarGroup
                        onClick={(e) => handleClickSub(e as any)}
                        max={3}
                        sx={{
                            cursor: "pointer",
                            "& .MuiAvatar-root": {
                                border: "1px solid #ffffff",
                                width: (theme) => theme.spacing(3.5),
                                height: (theme) => theme.spacing(3.5),
                                fontSize: "0.8rem",
                            },
                        }}
                    >
                        {task.users === null || task.users?.length === 0 ? (
                            <Avatar
                                sx={{
                                    width: "28px",
                                    height: "28px",
                                    fontSize: "0.65rem",
                                    "@keyframes blinking": {
                                        "0%": { opacity: 1, color: "red" },
                                        "50%": { opacity: 0.3 },
                                        "100%": { opacity: 1, color: "red" },
                                    },
                                }}
                            >
                                {highlight ? (
                                    <PriorityHigh
                                        sx={{
                                            animation: highlight ? "blinking 1s infinite" : "none", // Apply blinking animation when highlight is true
                                            fontSize: "1rem",
                                        }}
                                    />
                                ) : (
                                    <PersonAddAltRounded sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(3) }} />
                                )}
                            </Avatar>
                        ) : (
                            task.users?.map((user) => {
                                return <Avatar key={user.id}>{user.initials}</Avatar>;
                            })
                        )}
                    </AvatarGroup>
                )}
            </Tooltip>

            {/*
             *   Popper with users of current project
             */}
            <Popover
                anchorEl={anchorEl}
                open={openPopper}
                onClose={handleCloseSub}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                /**
                 * Width and height for chat/knowledge base
                 */
                slotProps={{
                    paper: {
                        sx: {
                            width: "300px",
                        },
                    },
                }}
                // PaperProps={{
                //     style: { width: "300px" },
                // }}
            >
                <div>
                    {/*
                     * Searchbox
                     */}
                    <Box style={{ width: "100%", display: "flex" }}>
                        <TextField
                            onClick={(e) => e.stopPropagation()}
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                                e.stopPropagation();
                                handleSearchUser(e);
                            }}
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ maxHeight: "310px", overflow: "auto" }}>
                        {results.map((user) => {
                            /**
                             * UserListItem
                             */
                            return (
                                <React.Fragment key={user.id}>
                                    <ListItemButton
                                        onClick={(e) => handleToggleAndUpdateTask(e, user, task.id)}
                                        dense
                                        selected={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                        disableRipple
                                    >
                                        {/* Listitem with userdetails. */}
                                        <ListItemText
                                            /**
                                             * Combination of user givenname and familyname. line through when user is inactive
                                             */
                                            primary={
                                                <>
                                                    <Typography>{truncate(user.name ? user.name : "", 40)}</Typography>
                                                </>
                                            }
                                        />
                                        {/* Checkbox */}
                                        <Checkbox
                                            size="small"
                                            edge="start"
                                            checked={Boolean(arrayWithUsersOrTeamsToShare.find((usr) => usr.id === user.id))}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                        />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </div>
            </Popover>
        </div>
    );
};

export default TemplateEditModeSingleTask;
