import React, { useEffect, useState } from "react";
import {
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    styled,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Task } from "./Tasks";
import { Check, Close, LibraryAdd, Undo } from "@mui/icons-material";
import { Disable } from "react-disable";
import TaskPriorityChip from "./TaskPriorityChip";
import EditableTaskField from "../EditableTaskField";
import TaskDatePicker from "./TaskDatePicker";
import moment from "moment";
import { User } from "./UserForTask";
import TemplateEditModeSingleTask from "./TemplateEditModeSingleTask";
import { Priority } from "../../../../__generated__/globalTypes";
import { useMutation } from "@apollo/client";
import { createTaskInLibrary, createTaskInLibraryVariables } from "../../../../__generated__/createTaskInLibrary";
import { CREATE_NEW_TASK_IN_LIBRARY } from "../../../../graphql/mutationDefTasks";
import { toast } from "react-toastify";
import { GET_TASK_LIBRARY } from "../../../../graphql/queryDefTasks";
import { CREATE_NEW_TASK } from "../../../../graphql/mutationDefProjects";
import { createTask, createTaskVariables } from "../../../../__generated__/createTask";
import { LoadingButton } from "@mui/lab";
import { TransitionGroup } from "react-transition-group";

interface Props {
    temporarytasks: Task[];
    setTemporaryTasks: React.Dispatch<React.SetStateAction<Task[]>>;
    users: User[];
    project_id: string;
    refetch?: () => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize, // Match H5 font size
    fontWeight: theme.typography.body1.fontWeight, // Match H5 font weight
    lineHeight: theme.typography.body1.lineHeight, // Match H5 line height
    "& .MuiInputBase-input": {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 400,
        lineHeight: theme.typography.body1.lineHeight,
        fontStyle: "italic",
    },
}));

const TemplateEditMode: React.FC<Props> = ({ temporarytasks, setTemporaryTasks, users, project_id, refetch }) => {
    const [value, setValue] = useState<string>("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [addTaskToLibrary, { error: errorCreate }] = useMutation<createTaskInLibrary, createTaskInLibraryVariables>(CREATE_NEW_TASK_IN_LIBRARY);
    const [createNewTask, { loading }] = useMutation<createTask, createTaskVariables>(CREATE_NEW_TASK);

    /**
     * Add new task to array
     * Add task description to library as well
     */
    const addNewTask = async () => {
        if (errorCreate) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await addTaskToLibrary({
                variables: {
                    description: value,
                },
                refetchQueries: [GET_TASK_LIBRARY],
                onCompleted: () => {
                    handleAddTask();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     * Update a task in the temporary tasks array
     * @param id id of the task to update
     * @param updates updates to apply to the task
     */
    const handleUpdate = (id: string, updates: Partial<Task>) => {
        const updatedTasks = temporarytasks.map((task) => (task.id === id ? { ...task, ...updates } : task));
        setTemporaryTasks(updatedTasks);
    };

    /**
     * Add a new task to the temporary tasks array
     */
    const handleAddTask = () => {
        const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;

        const newTask: Task = {
            id: generateUniqueId(), // Generate a unique ID, e.g., using UUID or some other method
            task_description: value,
            task_completed: false,
            task_deadline: null, // Or set a default date
            task_priority: Priority.MEDIUM, // Set the default priority
            users: [], // Add assigned users, if any
        };

        setTemporaryTasks((prevTasks) => [...prevTasks, newTask]);
        setValue("");
    };

    /**
     * Save tasks to the database
     * @param arr Array of tasks to save
     */
    const saveTask = async (arr: Task[]) => {
        // Create an array of promises for the asynchronous operations
        const createTaskPromises = arr.map((task) => {
            return createNewTask({
                variables: {
                    project_id: project_id,
                    description: task.task_description,
                    deadline: task.task_deadline === null ? null : moment(task.task_deadline).format("YYYY-MM-DD"),
                    completed: false,
                    priority: task.task_priority,
                    user_ids: [task.users && task.users[0].id],
                },
            });
        });

        // Wait for all promises to resolve before calling refetch
        await Promise.all(createTaskPromises);

        setTemporaryTasks([]);

        // After all tasks are created, refetch the queries
        refetch && refetch(); // Uncomment if you need to refetch the data
    };

    const addTemplateToProject = () => {
        // Remove tasks where delete === true
        const tasksToAdd = temporarytasks.filter((task) => task.delete !== true);
        const tasksWithMultipleUsers = tasksToAdd.flatMap((task) => {
            // Ensure task.users is not null and is treated as an array of User[]
            if (task.users && task.users.length > 0) {
                // If there are multiple users assigned to the task, create separate tasks for each user
                return task.users.map((user: User) => ({
                    id: `${task.id}-${user.id}`, // Generate a unique ID for each user
                    task_description: task.task_description,
                    task_deadline: task.task_deadline ? moment(task.task_deadline).toDate() : null, // Format date or set null
                    task_completed: task.task_completed,
                    task_priority: task.task_priority,
                    users: [{ id: user.id, name: user.name, initials: user.initials }] as any, // Assign single user to the task
                }));
            }

            // If there is only one user, return the task as is
            return task;
        }) as Task[];

        // Now, tasksWithMultipleUsers will contain individual tasks for each user, as well as single-user tasks
        // Proceed with whatever operation is needed next, e.g., adding them to the project
        setTemporaryTasks(tasksWithMultipleUsers); // Or do something else with this list
        saveTask(tasksWithMultipleUsers);
    };

    // Function to check that tasks with delete !== true have at least one user
    const checkTasksForUsers = (): boolean => {
        // Filter out tasks that have delete === true
        const tasksToCheck = temporarytasks.filter((task) => task.delete !== true);

        // Check if each remaining task has at least one user
        return tasksToCheck.every((task) => task.users && task.users.length > 0);
    };

    const [isHovering, setIsHovering] = useState(false);

    // Function to check if a task should be highlighted
    const shouldHighlight = (task: Task) => {
        // Highlight tasks that don't have users assigned and delete is not true
        return !task.users?.length && task.delete !== true;
    };

    // Handle hover state changes
    const handleMouseEnter = () => {
        setIsHovering(true); // Set hover state to true when mouse enters the "Save" button
    };

    const handleMouseLeave = () => {
        setIsHovering(false); // Set hover state to false when mouse leaves the "Save" button
    };

    useEffect(() => {
        const updatedTasks = temporarytasks.map((task) => {
            if (users && users.length === 1) {
                return { ...task, users: users }; // Replace `users` with the updated array
            }
            return task;
        });

        setTemporaryTasks(updatedTasks); // Make sure to update the state
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    return (
        <>
            <Paper elevation={0} variant="outlined" sx={{ marginBottom: "4px", position: "relative" }}>
                <Typography
                    variant="button"
                    style={{ color: "#b8b8b8", position: "absolute", top: "-13px", left: "10px", backgroundColor: "#fff", padding: "0px 6px" }}
                >
                    Bewerken
                </Typography>
                <List>
                    <TransitionGroup>
                        {temporarytasks.map((task, index) => {
                            const highlightTask = shouldHighlight(task);

                            return (
                                <Collapse key={task.id}>
                                    <ListItem
                                        id={task.id}
                                        key={task.id}
                                        disablePadding
                                        dense
                                        sx={{
                                            paddingRight: "44px",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {/*
                                         * Icon to show
                                         * - Disable state if task is not for current user
                                         */}
                                        <ListItemIcon sx={{ paddingLeft: "12px" }}>
                                            {/* <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} /> */}
                                        </ListItemIcon>

                                        {/* Tasks text */}
                                        <ListItemText
                                            sx={{ textDecoration: task.task_completed ? "line-through" : "none" }}
                                            primary={
                                                <EditableTaskField
                                                    disabled={task.delete === true}
                                                    tasktext={task.task_description}
                                                    setTasktext={(txt) => {
                                                        handleUpdate(task.id, { task_description: txt });
                                                    }}
                                                    taskID={task.id}
                                                    bossOfProject={true}
                                                />
                                            }
                                        />

                                        {/* DatePicker */}
                                        <Disable disabled={task.delete === true}>
                                            <TaskDatePicker
                                                date={task.task_deadline !== null ? moment(task.task_deadline) : null}
                                                updateDate={(date) => {
                                                    if (date) {
                                                        handleUpdate(task.id, { task_deadline: moment(date) });
                                                    }
                                                }}
                                            />
                                        </Disable>

                                        <div style={{ minWidth: "8px" }} />

                                        {/* user of task */}
                                        <Disable disabled={task.delete === true}>
                                            <div>
                                                <TemplateEditModeSingleTask
                                                    users={users}
                                                    setTemporaryTasks={setTemporaryTasks}
                                                    task={task}
                                                    updateUsers={(users) => {
                                                        handleUpdate(task.id, { users: users });
                                                    }}
                                                    highlight={isHovering && highlightTask}
                                                />
                                            </div>
                                        </Disable>
                                        <div style={{ minWidth: "8px" }} />

                                        {/* Prioritychip  */}
                                        <Disable disabledOpacity={task.delete ? 0.6 : 0.8} disabled={task.delete === true}>
                                            <TaskPriorityChip
                                                dot={isMobile ? true : false}
                                                disabled={false}
                                                priority={task.task_priority}
                                                updatePrio={(prio) => {
                                                    handleUpdate(task.id, { task_priority: prio });
                                                }}
                                            />
                                        </Disable>

                                        {/* Delete task 
                            // TODO add mutation to delete a task
                            */}
                                        <ListItemSecondaryAction
                                            sx={{
                                                right: isMobile ? "8px" : "16px",
                                            }}
                                        >
                                            {task.delete === true ? (
                                                <Tooltip placement="left" title="Ongedaan maken" disableInteractive>
                                                    <IconButton
                                                        size="small"
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            handleUpdate(task.id, { delete: !task.delete });
                                                        }}
                                                    >
                                                        <Undo fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip placement="left" title="Verwijder taak" disableInteractive>
                                                    <IconButton
                                                        sx={{ "&:hover": { color: (theme) => theme.palette.error.main } }}
                                                        size="small"
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            handleUpdate(task.id, { delete: !task.delete });
                                                        }}
                                                    >
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Collapse>
                            );
                        })}
                    </TransitionGroup>

                    {/***
                     * FIELD TO ADD NEW TASK AND OPTION TO ADD TO LIBRARY
                     ***/}
                    <ListItem
                        disablePadding
                        dense
                        sx={{
                            paddingRight: "44px",
                            fontStyle: "italic",
                        }}
                    >
                        {/*
                         * Icon to show
                         * - Disable state if task is not for current user
                         */}
                        <ListItemIcon sx={{ paddingLeft: "12px" }}>
                            {/* <RadioButtonUnchecked fontSize="small" sx={{ color: "#cccccc" }} /> */}
                        </ListItemIcon>

                        {/* Tasks text */}
                        <ListItemText
                            primary={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <StyledTextField
                                        autoFocus
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                        placeholder="Wat moet er gedaan worden?"
                                        variant="standard"
                                        value={value}
                                        onChange={(e) => {
                                            setValue(e.target.value);
                                        }}
                                    />
                                    <Tooltip title="Voeg nieuwe taak toe aan project" placement="top" disableInteractive>
                                        <div>
                                            <IconButton disabled={value.length <= 3} size="small" onClick={handleAddTask}>
                                                <Check fontSize="small" sx={{ color: value.length <= 3 ? "lightgrey" : "#508c79" }} />
                                            </IconButton>
                                        </div>
                                    </Tooltip>

                                    <Tooltip title="Voeg nieuwe taak toe aan project en bibliotheek" placement="top" disableInteractive>
                                        <div>
                                            <IconButton disabled={value.length <= 3} size="small" onClick={addNewTask}>
                                                <LibraryAdd fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                        />

                        <div style={{ flex: 1 }} />
                    </ListItem>
                </List>
            </Paper>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                    sx={{ backgroundColor: "#fff" }}
                    loading={loading}
                    onClick={() => {
                        setTemporaryTasks([]);
                    }}
                    size="small"
                >
                    Annuleren
                </LoadingButton>
                <Tooltip title={!checkTasksForUsers() ? "Taken zijn niet valide" : "Opslaan"} placement="top" disableInteractive>
                    <div
                        style={{ cursor: "pointer" }}
                        onMouseEnter={handleMouseEnter} // Trigger highlight when hovering over the button
                        onMouseLeave={handleMouseLeave} // Remove highlight when hover leaves
                    >
                        <LoadingButton
                            disabled={!checkTasksForUsers()}
                            loading={loading}
                            onClick={addTemplateToProject}
                            size="small"
                            variant="contained"
                        >
                            Opslaan
                        </LoadingButton>
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default TemplateEditMode;
