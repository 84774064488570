import React, { createContext, useContext, useState, ReactNode } from "react";

interface IncognitoContextProps {
    helpingUser: string | null;
    setHelpingUser: (user: string | null) => void;
}

const IncognitoContext = createContext<IncognitoContextProps | undefined>(undefined);

export const IncognitoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [helpingUser, setHelpingUser] = useState<string | null>(null);

    return <IncognitoContext.Provider value={{ helpingUser, setHelpingUser }}>{children}</IncognitoContext.Provider>;
};

export const useIncognito = (): IncognitoContextProps => {
    const context = useContext(IncognitoContext);
    if (!context) {
        throw new Error("useIncognito must be used within an IncognitoProvider");
    }
    return context;
};
