import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { List, Grid, Paper, TextField, InputAdornment, Button, Divider } from "@mui/material";
import UserListItem from "./UserListItem";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NewUserModal, { Phonenumber } from "../Dialog/NewUserModal";
import { UserInTeam } from "./UsersAndTeams";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AddUserToOrganization, AddUserToOrganizationVariables } from "../../../__generated__/AddUserToOrganization";
import { QUERY_SUBSCRIPTION_AND_TEAMS, QUERY_SUBSCRIPTION_USERS } from "../../../graphql/queryDefUserAndTeams";
import { RemoveUser, RemoveUserVariables } from "../../../__generated__/RemoveUser";
import {
    GetScubscriptionAndTeams_organization_subscriptions_adminUsers,
    GetScubscriptionAndTeams_organization_subscriptions_allowedDomains,
} from "../../../__generated__/GetScubscriptionAndTeams";
import {
    ADD_USER_TO_ORGANIZATION,
    DELETE_USER,
    QUERY_ALL_USERS_IN_ORGANIZATION,
    SET_USER_AS_SUBSCRIPTION_ADMIN,
} from "../../../graphql/queryDefCurrentUser";
import { SetAsSubAdmin, SetAsSubAdminVariables } from "../../../__generated__/SetAsSubAdmin";

interface Props {
    users: UserInTeam[];
    loading: boolean;
    seats: number | null | undefined;
    totalSeats: number | null | undefined;
    subOwner: (GetScubscriptionAndTeams_organization_subscriptions_adminUsers | null)[] | undefined;
    allowedDomains: (GetScubscriptionAndTeams_organization_subscriptions_allowedDomains | null)[] | undefined;
    setReset: () => void;
    setUserList: (t: UserInTeam[]) => void;
}

interface UserToAdd {
    givenname: string;
    familyname: string;
    middlename: string;
    initials: string;
    email: string;
    password: string;
    password_confirmation: string;
    function: string;
}

const UserList: React.FC<Props> = ({ users: userList, loading, seats, subOwner, allowedDomains, setReset, setUserList, totalSeats }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [open, setOpen] = useState(false);
    // const [users, setUser] = useState(userList);
    const { t } = useTranslation();
    const [addNewUserUserToOrg, { loading: loadAdding }] = useMutation<AddUserToOrganization, AddUserToOrganizationVariables>(
        ADD_USER_TO_ORGANIZATION
    );
    const [deleteUser] = useMutation<RemoveUser, RemoveUserVariables>(DELETE_USER);
    const [setAsSubAdmin] = useMutation<SetAsSubAdmin, SetAsSubAdminVariables>(SET_USER_AS_SUBSCRIPTION_ADMIN);
    const [manualInput, setManualInput] = useState<Phonenumber>({ phonenumber: "", country: "nl" });
    const [manualMobileInput, setManualMobileInput] = useState<Phonenumber>({ phonenumber: "", country: "nl" });
    const [step, setStep] = useState<number>(1);

    /**
     * Array that gives users back that match with input in searchfield.
     * - match based on input and givenname
     */
    const results = !searchTerm ? userList : userList.filter((item) => item.employee.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    /**
     * Handle user input in searchbox to search a user on his givenname
     * @param event Value from textfield to search a user in the list.
     */

    const handleSearchUser = (event: any) => {
        const {
            target: { value },
        } = event;

        setSearchTerm(value);
    };

    /**
     * open modal to create a new user
     */
    const handleClickNewUser = () => {
        setOpen(true);
    };

    /**
     * Close modal and clear phone states
     */
    const handleCloseModal = (reset: boolean) => {
        if (reset) {
            setReset();
        }
        setStep(1);
        setOpen(false);
        setManualMobileInput({ phonenumber: "", country: "nl" });
        setManualInput({ phonenumber: "", country: "nl" });
    };

    const capitalizeFirstLetter = (input: string) => {
        return input
            .split(" ") // Split the string into an array of words
            .map((word) => {
                if (word.length === 0) return word; // Handle empty strings (e.g., multiple spaces)
                return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter, keep the rest intact
            })
            .join(" "); // Join the words back into a single string
    };

    /**
     * Add a user to to organization
     * @param data filled in data
     */
    const handleSubmit = (data: UserToAdd, hasSearches: boolean) => {
        // setReset();
        const name = capitalizeFirstLetter(data.givenname);
        const famName = capitalizeFirstLetter(data.familyname);

        addNewUserUserToOrg({
            variables: {
                givenname: name.replace(/\s\s+/g, " "),
                familyname: famName.replace(/\s\s+/g, " "),
                middlename: data.middlename,
                function: data.function,
                email: data.email.replace(/\s\s+/g, " "),
                cellphone: manualInput.phonenumber,
                phonenumber: manualMobileInput.phonenumber,
            },
            refetchQueries: [QUERY_SUBSCRIPTION_USERS, "GetSubscriptionUsers", QUERY_ALL_USERS_IN_ORGANIZATION, QUERY_SUBSCRIPTION_AND_TEAMS],
            awaitRefetchQueries: true,
            onError: (data) => {
                handleCloseModal(true);
                toast.error("Er ging iets mis met het toevoegen van de gebruiker", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
            onCompleted: (data) => {
                if (hasSearches) {
                    setStep(2);
                } else {
                    handleCloseModal(true);
                    toast.success("Gebruiker toegevoegd", {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },
        });

        setUserList([
            ...userList,
            {
                __typename: "User",
                id: "string1",
                verified: false,
                email: data.email.replace(/\s\s+/g, ""),
                employee: {
                    id: `uniqueID${userList.length + 3}`,
                    name: name.replace(/\s\s+/g, " "),
                    middlename: data.middlename,
                    familyname: famName.replace(/\s\s+/g, " "),
                },
                subscription_admin: false,
            },
        ]);
    };

    /**
     * Handler to upgrade user to admin
     * @param id id of user
     */
    const makeUserAdmin = (id: number) => {
        setAsSubAdmin({
            variables: {
                id: id,
            },
            refetchQueries: [QUERY_SUBSCRIPTION_USERS, "GetSubscriptionUsers", QUERY_ALL_USERS_IN_ORGANIZATION, QUERY_SUBSCRIPTION_AND_TEAMS],
            awaitRefetchQueries: true,
            onError: () => {
                setReset();
                toast.error("Er ging iets mis...", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
            onCompleted: () => {
                setReset();
                toast.success("Gebruiker is nu beheerder", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
        });
    };

    /**
     * Handler to delete a useraccount
     * @param id id of user to delete
     */
    const handleDelete = (id: string) => {
        deleteUser({
            variables: {
                id: id,
            },
            refetchQueries: [QUERY_SUBSCRIPTION_USERS, "GetSubscriptionUsers", QUERY_ALL_USERS_IN_ORGANIZATION, QUERY_SUBSCRIPTION_AND_TEAMS],
            awaitRefetchQueries: true,
            onError: (e) => {
                toast.error("Er ging iets mis...", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
            onCompleted: () => {
                setReset();
                toast.success("Gebruiker verwijderd", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            },
        });
    };

    /**
     * When data is loading => show a static version of userlist with a spinner in the middle of the section
     * section height is equal to the height of 5 userlistitems
     */
    if (loading) {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Paper square>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                            sx={{ margin: "10px", flexGrow: 1 }}
                            placeholder="Zoek een gebruiker..."
                            size="small"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* Button to open the dialog for creating a new user -> NewUserModal.tsx */}
                    </div>

                    <Divider />

                    {/* list component with all users as listitem */}
                    <List
                        sx={{
                            padding: 0,
                            // 1 userlistitem = 70px * 5 (5 = default contract)
                            // height: "350px",
                            height: { xs: "75svh", md: "65vh" },
                            backgroundColor: (theme) => theme.palette.background.paper,
                            maxHeight: { xs: "345px", md: "65vh" },
                            overflow: "auto",
                        }}
                    >
                        <FullPageSpinner />
                    </List>
                </Paper>
            </Grid>
        );
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Paper square>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        sx={{ margin: "10px", flexGrow: 1 }}
                        placeholder="Zoek een gebruiker..."
                        size="small"
                        variant="outlined"
                        onChange={handleSearchUser}
                        value={searchTerm}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* Button to open the dialog for creating a new user -> NewUserModal.tsx */}
                    {Boolean(subOwner?.find((u) => u?.id === localStorage.getItem("user_id"))) && (
                        <Button
                            disabled={seats === null || seats === undefined || seats <= 0}
                            variant="contained"
                            color="grey"
                            style={{ height: "38px", marginRight: "10px" }}
                            onClick={handleClickNewUser}
                        >
                            <AddIcon />
                        </Button>
                    )}
                </div>

                {/*
                 * Textual helper where user can see the number of remaining licenses.
                 * and a CTA when they reached the limit.
                 * Owners will see textual helper
                 */}
                {totalSeats !== null &&
                    totalSeats !== undefined &&
                    seats !== null &&
                    seats !== undefined &&
                    Boolean(subOwner?.find((u) => u?.id === localStorage.getItem("user_id"))) && (
                        <div style={{ padding: "0 6px", display: "flex", alignItems: "center" }}>
                            <div
                                style={{
                                    width: "62px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: "8px 0px",
                                    marginRight: "5px",
                                }}
                            >
                                <span style={{ fontSize: "1.7rem", fontWeight: 500 }}>{totalSeats - seats}</span>
                                <span>Actief</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {seats > 0 && (
                                    <span>
                                        Je kunt nog <span style={{ fontWeight: "bold" }}>{seats}</span>{" "}
                                        {seats === 1 ? t("UserAndTeams.User").toLocaleLowerCase() : t("UserAndTeams.Users").toLocaleLowerCase()}{" "}
                                        toevoegen.
                                    </span>
                                )}

                                {seats <= 0 && (
                                    <span>
                                        Er zijn geen vrije logins meer beschikbaar binnen je licentie. <br /> Voor uitbreiding, neem contact op met
                                        support@tender.guide
                                    </span>
                                )}
                                {/* */}
                            </div>
                        </div>
                    )}
                <Divider />

                {/* List component with all users as listitem */}
                <List
                    sx={{
                        padding: 0,
                        width: "100%",
                        backgroundColor: (theme) => theme.palette.background.paper,
                        maxHeight: { xs: "60svh", md: "65vh" },
                        overflow: "auto",
                    }}
                >
                    {results.map((user) => {
                        /**
                         * Render UserListItem component for details each user in organization
                         * Interacts with searchbar on top
                         */
                        // const subbie = subOwner?.find((u) => u?.id === user.id) ? true : false;
                        const cuIsOwner = Boolean(subOwner?.find((u) => u?.id === localStorage.getItem("user_id")));
                        return (
                            <UserListItem
                                key={user.id}
                                singleUser={user}
                                onDelete={handleDelete}
                                isAdmin={user.subscription_admin}
                                cuIsOwner={cuIsOwner}
                                onUpgrade={makeUserAdmin}
                            />
                        );
                    })}
                </List>
            </Paper>
            <NewUserModal
                open={open}
                step={step}
                loadAdding={loadAdding}
                // Close modal and clear phone inputfields
                onClose={handleCloseModal}
                onSubmit={handleSubmit}
                allowedDomains={allowedDomains ?? []}
                setManualMobileInput={setManualMobileInput}
                setManualInput={setManualInput}
                manualInput={manualInput}
                manualMobileInput={manualMobileInput}
                existingUsers={userList}
            />
        </Grid>
    );
};

export default UserList;
