import React, { useState } from "react";
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { MARK_INTRO_AS_SEEN, QUERY_INTRO_HAS_SEEN } from "../../../graphql/queryDefCurrentUser";
import { queryIntroSeen } from "../../../__generated__/queryIntroSeen";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCards } from "swiper/modules";

interface Props {
    a?: number;
}

const TaskViewFeatureDialog: React.FC<Props> = (props) => {
    const [showFeature, setShowFeature] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    /**
     * Fetch tender.events, deadline_inschrijving
     */
    const { refetch } = useQuery<queryIntroSeen>(QUERY_INTRO_HAS_SEEN, {
        onCompleted: (data) => {
            if (moment(data.currentUser.hasSeenIntro).isBefore(moment("03-01-2025", "DD-MM-YYYY"))) {
                setTimeout(() => {
                    setShowFeature(true);
                }, 1000); // Delay of 1 second
            }
        },
    });

    const [markIntro, { error }] = useMutation(MARK_INTRO_AS_SEEN);

    const markAsSeen = async () => {
        if (error) {
        }
        try {
            await markIntro({
                onCompleted: () => {
                    refetch();
                    setShowFeature(false);
                },
            });
        } catch (e) {}
    };

    return (
        <Dialog open={showFeature} maxWidth={"lg"} fullWidth>
            <DialogContent>
                <div>
                    <div style={{ paddingRight: "24px" }}>
                        <div style={{ height: "34px", display: "flex", alignItems: "center" }}>
                            <Typography fontWeight={500} variant="h5">
                                Nieuwste verbeteringen
                            </Typography>
                        </div>

                        <Swiper
                            style={{ margin: "32px 96px" }}
                            modules={[Pagination, Navigation, EffectCards]}
                            effect="cards"
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            cardsEffect={{ rotate: false, slideShadows: false, perSlideRotate: 0 }}
                            pagination={{ clickable: true }}
                            spaceBetween={30}
                            slidesPerView={1}
                            grabCursor
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            {itemsExample.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Card sx={{ margin: "32px", borderRadius: "16px", height: "50vh" }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h3" component="div" sx={{ padding: "0px 16px" }}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: "text.secondary", padding: "0px 16px" }}>
                                                {item.subtext}
                                            </Typography>
                                            <CardMedia
                                                component="img"
                                                height="340"
                                                image={item.image}
                                                alt={item.title}
                                                sx={{ marginTop: "32px", objectFit: "contain" }}
                                            />
                                        </CardContent>
                                    </Card>
                                </SwiperSlide>
                            ))}

                            {/* Navigation buttons with inline styles */}
                            <div
                                className="swiper-button-next"
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "24px",
                                    color: "#fff",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    padding: "10px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    visibility: activeIndex === itemsExample.length - 1 ? "hidden" : "visible",
                                }}
                            />

                            <div
                                className="swiper-button-prev"
                                style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "24px",
                                    color: "#fff",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    padding: "10px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    visibility: activeIndex === 0 ? "hidden" : "visible",
                                }}
                            />
                        </Swiper>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowFeature(false)} color="primary">
                    Toon later
                </Button>
                <Button onClick={markAsSeen} color="primary" variant="contained" sx={{ color: "#ffffff" }}>
                    Niet tonen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TaskViewFeatureDialog;

const itemsExample = [
    {
        image: "https://storage.googleapis.com/tenderguide-public/task_view_button.gif",
        title: "Takenoverzicht",
        subtext:
            "In het takenoverzicht zie je alle taken die horen bij de projecten waaraan je bent toegevoegd. Gebruik de filters om taken te filteren op project, status, prioriteit, deadline of toegewezen gebruiker.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/taak%20toevoegen.gif",
        title: "Voeg taken toe aan Mijn taken",
        subtext:
            "Kies taken uit de voorbeelden of maak eigen taken aan. In het tabblad 'Mijn taken' staat een overzicht van de door jou aangemaakte taken. In het tabblad voorbeelden zie je vaak voorkomende taken bij aanbestedingen.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/template_kiezen_1.gif",
        title: "Kies een sjabloon",
        subtext:
            "Kies het sjabloon waar je taken aan toe wilt voegen. Als je nog geen sjabloon hebt, voeg dan een eerste sjabloon toe met de button Maak een sjabloon.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/task_to_template.gif",
        title: "Voeg taken toe aan sjabloon",
        subtext:
            "Nadat je een sjabloon hebt gekozen, kun je taken toevoegen aan het sjabloon. Door op een taak aan de linkerzijde te klikken, wordt deze toegevoegd aan het sjabloon.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/template_in_project.gif",
        title: "Sjabloon toepassen in een project",
        subtext:
            "Aangemaakte sjablonen kun je toepassen in een project. Zo kun je vaak voorkomende taken sneller toewijzen aan andere gebruikers in het project.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/add_library_2.gif",
        title: "Taak toevoegen vanuit projectvenster",
        subtext:
            "Je kunt ook vanuit het projectvenster taken toevoegen. Zet een vinkje voor de taak en de taak wordt toegevoegd aan 'Mijn taken' en aan het project.",
    },
];
