import React from "react";
import { Disable } from "react-disable";
import TaskPriorityChip from "../../projectModal/task/TaskPriorityChip";
import { Priority } from "../../../../__generated__/globalTypes";
import { useMutation } from "@apollo/client";
import { updateTaskPriority, updateTaskPriorityVariables } from "../../../../__generated__/updateTaskPriority";
import { UPDATE_TASK_PRIORITY } from "../../../../graphql/mutationDefProjects";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../../models/MatomoEvent";
import { getTasksWithFilters_filterUserTasks_data } from "../../../../__generated__/getTasksWithFilters";
import { GET_TASKS_WITH_FILTERS } from "../../../../graphql/queryDefTasks";

interface Props {
    taskOfCu: boolean;
    task: getTasksWithFilters_filterUserTasks_data;
    onTaskChange: <K extends keyof getTasksWithFilters_filterUserTasks_data>(
        updatedTask: getTasksWithFilters_filterUserTasks_data,
        key: K,
        value: getTasksWithFilters_filterUserTasks_data[K]
    ) => void;
}

const TaskPriority: React.FC<Props> = ({ taskOfCu, task, onTaskChange }) => {
    // Change priority of task
    const [saveTaskPriority] = useMutation<updateTaskPriority, updateTaskPriorityVariables>(UPDATE_TASK_PRIORITY);
    const { trackEvent } = useMatomo();
    // Track event
    const track = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Update priority of task
     * @param taskId id of task
     * @param priority priority of task
     */
    const onChangePriority = async (taskId: string, priority: Priority) => {
        try {
            await saveTaskPriority({
                variables: {
                    id: taskId,
                    priority: priority,
                },
                refetchQueries: [GET_TASKS_WITH_FILTERS],
                onCompleted: () => {
                    track({ category: "Project", action: "Change-task-priority", name: priority.toString() });
                    const updatedTask = { ...task, task_priority: priority }; // Prepare the updated task
                    onTaskChange(updatedTask, "task_priority", priority);
                },
            });
        } catch (e) {}
    };
    return (
        <Disable disabledOpacity={task.deleted_at ? 0.6 : task.task_completed ? 0.6 : 0.8} disabled={task.deleted_at || task.task_completed}>
            <TaskPriorityChip
                dot={false}
                disabled={task.task_completed || !taskOfCu}
                priority={task.task_priority}
                updatePrio={(prio) => {
                    if (taskOfCu) {
                        onChangePriority(task.id, prio as Priority);
                    }
                }}
            />
        </Disable>
    );
};

export default TaskPriority;
