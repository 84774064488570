import { gql } from "@apollo/client";

//? Get all tenders in platform, sorted column: updated_at
export const QUERY_CALENDAR_DATA_ITEMS = gql`
    query FilterCalendarDataItems($page: Int, $count: Int, $orderField: String!, $order: SortOrder!, $search: TenderSearchInput) {
        filterTenders(page: $page, first: $count, search: $search, orderBy: [{ column: $orderField, order: $order }]) {
            results {
                paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                }
                data {
                    id
                    namegiventocontract
                    deadline_inschrijving
                    looptijd_start {
                        lots
                        value
                    }
                    next_looptijd_einde {
                        lots
                        value
                    }
                }
            }
        }
    }
`;

export const QUERY_USER_AND_TENDER_EVENTS = gql`
    query SavedTenderEvents($from: Date!, $till: Date!) {
        getCalendarEvents(from: $from, till: $till) {
            # ----------------------------------
            # TenderDate => tenderevents on like
            # ----------------------------------
            ... on TenderDate {
                id
                name
                planned
                tender {
                    id
                    namegiventocontract
                    status
                    clientA {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    published_opdrachtgever
                }
                timeSignificant
            }
            # -----------------------------
            # TenderEvents => planningitems
            # -----------------------------
            ... on TenderEvent {
                created_at
                id
                isReplacedBy {
                    name
                    planned
                    timeSignificant
                }
                replaces {
                    name
                    planned
                    timeSignificant
                }
                isStored
                name
                planned
                # source
                timeSignificant
                updated_at
                tender {
                    id
                    namegiventocontract
                    status
                    clientA {
                        id
                        name
                        favicon
                        favicon_resized
                        pivot {
                            category
                        }
                    }
                    published_opdrachtgever
                }
            }
            # -----------
            # User events
            # -----------
            ... on UserEvent {
                title
                description
                start
                end
            }
        }
    }
`;

//? Get all tenders in platform, sorted column: updated_at
export const QUERY_ICAL_LINK = gql`
    query GetIcalUser {
        currentUser {
            id
            ical_link
        }
    }
`;
