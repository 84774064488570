import React from "react";
import { getTasksWithFilters_filterUserTasks_filters, getTasksWithFiltersVariables } from "../../../../__generated__/getTasksWithFilters";
import FilterDropdownStringVersion from "../../../../components/TopFilterBar/FilterDropdownStringVersion";
import update from "immutability-helper";

interface Props {
    filterData: getTasksWithFilters_filterUserTasks_filters | null | undefined;
    activeFilters: getTasksWithFiltersVariables;
    setActiveFilters: React.Dispatch<React.SetStateAction<getTasksWithFiltersVariables>>;
}

const Person: React.FC<Props> = ({ filterData, activeFilters, setActiveFilters }) => {
    return (
        <FilterDropdownStringVersion
            disabled={false}
            // allowSearch
            label="Gebruiker"
            values={(activeFilters.user_ids as string[]) ?? []}
            onChange={(selectedValues) => {
                if (selectedValues.length === 0) {
                    setActiveFilters(update(activeFilters, { $unset: ["user_ids"] }));
                } else {
                    setActiveFilters(update(activeFilters, { user_ids: { $set: selectedValues } }));
                }
            }}
            options={
                filterData?.user_ids?.map((person) => ({
                    key: person?.id as string,
                    label: person?.name as string,
                    id: person?.id as string,
                })) || []
            }
            width={200}
        />
    );
};

export default Person;
