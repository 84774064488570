import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { CompareArrows, JoinInner, JoinLeft, Toll } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export type LogicalOperator = {
    icon: ReactNode; // Could also be a JSX element if you're directly using the icon
    text: "AND" | "OR" | "NOT" | "NEAR"; // Define the allowed logical operators
};

export const GroupLogicalOperators = [
    { icon: <Toll />, text: "OR" },
    { icon: <JoinInner />, text: "AND" },
    { icon: <JoinLeft />, text: "NOT" },
];

export const logicalOperators: LogicalOperator[] = [
    { icon: <Toll />, text: "OR" },
    { icon: <JoinInner />, text: "AND" },
    { icon: <JoinLeft />, text: "NOT" },
    { icon: <CompareArrows />, text: "NEAR" },
];

export interface Condition {
    id: string;
    keyword?: string;
    nearTerm?: string;
    proximity?: number;
    operator?: LogicalOperator;
    secondOperator?: LogicalOperator;
}

export interface ConditionGroup {
    id: string; // Unique identifier for each group
    operator?: LogicalOperator; // Operator for this group
    conditions: Condition[]; // Array of conditions in the group
}

// Define the context type
interface ConditionsContextType {
    groups: ConditionGroup[];
    setGroups: React.Dispatch<React.SetStateAction<ConditionGroup[]>>;
    addGroup: (operatorText: "AND" | "NOT" | "OR") => void;
    updateGroupOperator: (groupId: string, newOperator: "AND" | "OR" | "NOT") => void;
    addCondition: (keyword: string) => void;
    updateConditionOperator: (groupId: string, conditionId: string, newOperator?: LogicalOperator, newSecondOperator?: LogicalOperator) => void;
    handleNearCondition: (groupId: string, conditionId: string) => void;
    removeCondition: (groupId: string, conditionId: string) => void;
    updateConditionKeyword: (groupId: string, conditionId: string, newKeyword: string) => void;
    removeGroup: (groupId: string) => void;
    handleNearTermChange: (groupId: string, conditionId: string, newNearTerm: string) => void;
    handleProximityChange: (groupId: string, conditionId: string, newProximity: number | undefined) => void;
    activeGroup: string;
    setActiveGroup: (str: string) => void;
    highlightedConditionId: string | null;
    setHighlightedConditionId: (str: string | null) => void;
    resetNearTerm: (groupId: string, conditionId: string) => void;
    handleGenerateQuery: () => void;
    advancedQueryString: string;
    resetAdvancedQuery: () => void;
    setAdvancedQueryString: React.Dispatch<React.SetStateAction<string>>;
    containsOperators: (query: string) => boolean;
    getOperatorIcon: (operatorText: string) => React.ReactNode;
    areThereAnyWordsSelected: () => boolean;
    areThereNEARConditionsNotValid: () => boolean;
    searchWizard: boolean;
    setSearchWizard: (bln: boolean) => void;
    nextIsNearTerm: () => boolean;
    // containsOnlyOperator: (query: string, operator: string) => boolean;
    createKeywordwithConditions: (query: string, operator: string) => Condition[];
    detectSingleOperatorUsage: (query: string) => string | false;
    splitAndProcessQuery(query: string): ConditionGroup[] | false;
    hasMultipleWordsInParenthesesExcludingProximity: (query: string) => boolean;
    processQuery: (query: string, defaultOperator: LogicalOperator) => Condition[];
    // parseQueryToGroups: (query: string) => ConditionGroup[];
}

// Create the context
const ConditionsContext = createContext<ConditionsContextType | undefined>(undefined);

// Utility function to generate unique IDs
export const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 15); // Simple unique ID generator
};

// Create the ConditionsProvider
export const ConditionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const [highlightedConditionId, setHighlightedConditionId] = useState<string | null>(null);
    const [searchWizard, setSearchWizard] = useState<boolean>(false);
    /**
     * get defaultQuery based on location(url)
     */
    const defaultQueryKey =
        location.pathname === "/tenders"
            ? "atq"
            : location.pathname === "/tenders/reviews"
            ? "rtq"
            : location.pathname === "/tenders/worklist"
            ? "wtq"
            : location.pathname === "/opportunities"
            ? "aoq"
            : location.pathname === "/opportunities/reviews"
            ? "roq"
            : location.pathname === "/opportunities/worklist"
            ? "woq"
            : "";

    const defaultGroupsKey =
        location.pathname === "/tenders"
            ? "atqg"
            : location.pathname === "/tenders/reviews"
            ? "rtqg"
            : location.pathname === "/tenders/worklist"
            ? "wtqg"
            : location.pathname === "/opportunities"
            ? "aoqg"
            : location.pathname === "/opportunities/reviews"
            ? "roqg"
            : location.pathname === "/opportunities/worklist"
            ? "woqg"
            : "";

    // Check session storage for existing groups; fall back to default if none are found
    const savedGroups = defaultGroupsKey ? JSON.parse(sessionStorage.getItem(defaultGroupsKey) || "null") : null;

    const defaultGroups = [{ id: generateUniqueId(), conditions: [] }]; // Default structure
    const initialGroups = savedGroups || defaultGroups;

    const [groups, setGroups] = useState<ConditionGroup[]>(initialGroups);

    useEffect(() => {
        // Fetch groups from session storage if available
        const savedGroups = defaultGroupsKey ? JSON.parse(sessionStorage.getItem(defaultGroupsKey) || "null") : null;
        const defaultGroups = [{ id: generateUniqueId(), conditions: [] }];
        const initialGroups = savedGroups || defaultGroups;

        setGroups(initialGroups);

        // Generate the query string from the groups
        const query = generateQueryString(initialGroups);
        const gen = query === "()" ? "" : query;
        setAdvancedQueryString(gen);

        // Store the generated query string in session storage
        if (defaultGroupsKey) {
            sessionStorage.setItem(defaultGroupsKey, JSON.stringify(initialGroups));
            // const defaultQueryKey = defaultGroupsKey.replace("g", "q");
            sessionStorage.setItem(defaultQueryKey, JSON.stringify(gen));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const [advancedQueryString, setAdvancedQueryString] = useState<string>("");

    const [activeGroup, setActiveGroup] = useState<string>(groups.length > 0 ? groups[0].id : "");

    const resetAdvancedQuery = () => {
        setGroups(defaultGroups);
        setAdvancedQueryString("");
        sessionStorage.removeItem(defaultQueryKey);
        sessionStorage.removeItem(defaultGroupsKey);
    };

    // ####################################################################
    // ################################## GROUP HANDLERS
    // ####################################################################

    // Function to add a new group with a unique ID
    const addGroup = (operatorText: "AND" | "NOT" | "OR") => {
        const operator = logicalOperators.find((op) => op.text === operatorText);

        if (!operator) {
            console.error("Invalid operator provided");
            return;
        }

        // new group data
        const newGroup: ConditionGroup = {
            id: generateUniqueId(),
            operator,
            conditions: [],
        };

        // add new group
        setGroups((prev) => [...prev, newGroup]);

        // Set new group to active
        setActiveGroup(newGroup.id);
    };

    // Function to update the operator of a specific group
    const updateGroupOperator = (groupId: string, newOperator: "AND" | "OR" | "NOT") => {
        const operator = logicalOperators.find((op) => op.text === newOperator);
        setGroups((prevGroups) => prevGroups.map((group) => (group.id === groupId ? { ...group, operator } : group)));
    };

    // Function to remove a group by its ID
    const removeGroup = (groupId: string): void => {
        setGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));
    };

    // ####################################################################
    // ################################## CONDITION HANDLERS
    // ####################################################################

    // Function to get the icon based on the operator text
    const getOperatorIcon = (operatorText: string) => {
        const operator = logicalOperators.find((op) => op.text === operatorText);
        return operator ? operator.icon : null; // Return the icon or null if no match is found
    };

    const nextIsNearTerm = (): boolean => {
        const group = groups.find((g) => g.id === activeGroup); // Directly use `groups` and `activeGroup` from state
        if (!group) return false;

        return group.conditions.some((condition) => condition.operator?.text === "NEAR" && !condition.nearTerm);
    };

    // Helper function to add a new condition or update nearTerm with a unique ID to a specific group
    const addCondition = (keyword: string) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) => {
                if (group.id === activeGroup) {
                    const nearCondition = group.conditions.find((condition) => condition.operator?.text === "NEAR" && !condition.nearTerm);

                    if (nearCondition) {
                        // If a NEAR condition with an empty nearTerm is found, update it
                        fillNearTerm(group.id, nearCondition.id, keyword);

                        // Highlight this condition
                        setHighlightedConditionId(nearCondition.id);
                        setTimeout(() => setHighlightedConditionId(null), 1000); // Remove highlight after 1 second

                        return group; // Return early as we have filled the nearTerm
                    }

                    const hasConditions = group.conditions.length > 0;
                    let newOperator: LogicalOperator;

                    if (hasConditions) {
                        const firstCondition = group.conditions[0];

                        if (firstCondition.operator?.text === "AND") {
                            newOperator = { icon: <JoinInner />, text: "AND" };
                        } else if (firstCondition.operator?.text === "NEAR") {
                            newOperator =
                                firstCondition.secondOperator?.text === "AND" ? { icon: <JoinInner />, text: "AND" } : { icon: <Toll />, text: "OR" };
                        } else if (firstCondition.operator?.text === "NOT") {
                            // If the first operator is NOT, decide what to do
                            newOperator = { icon: <JoinLeft />, text: "NOT" }; // Choose a suitable icon for NOT
                        } else {
                            // Handle other cases (if there are any)
                            newOperator = { icon: <Toll />, text: "OR" }; // Default if no specific conditions are met
                        }
                    } else {
                        // Default operator when no conditions exist
                        newOperator = { icon: <Toll />, text: "OR" };
                    }

                    const newCondition = {
                        id: generateUniqueId(),
                        keyword: keyword,
                        nearTerm: "",
                        proximity: 5,
                        operator: newOperator,
                    };

                    // Add the new condition to the group
                    const updatedGroup = {
                        ...group,
                        conditions: [...group.conditions, newCondition],
                    };

                    // Highlight the new condition
                    setHighlightedConditionId(newCondition.id);
                    setTimeout(() => setHighlightedConditionId(null), 1000);

                    return updatedGroup;
                }
                return group;
            })
        );
    };

    // Function to update the operator of a specific condition
    const updateConditionOperator = (groupId: string, conditionId: string, newOperator?: LogicalOperator, newSecondOperator?: LogicalOperator) => {
        setGroups((prevGroups) => {
            return prevGroups.map((group) => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        conditions: group.conditions.map((condition) => {
                            if (condition.id === conditionId) {
                                const updatedCondition = {
                                    ...condition,
                                    operator: newOperator || condition.operator,
                                    secondOperator: newSecondOperator || undefined,
                                };
                                return updatedCondition;
                            }
                            return condition;
                        }),
                    };
                }
                return group;
            });
        });
    };

    // Function to remove a condition by ID
    const removeCondition = (groupId: string, conditionId: string) => {
        setGroups((prevGroups) => {
            return prevGroups.reduce((acc, group) => {
                if (group.id === groupId) {
                    const updatedConditions = group.conditions.filter((condition) => condition.id !== conditionId);

                    // If there are no conditions left in this group
                    if (updatedConditions.length === 0) {
                        // If this is the only group, reset its state but keep the same ID
                        if (prevGroups.length === 1) {
                            acc.push({
                                ...group, // Keep the same group ID and operator
                                conditions: [], // Reset conditions to empty
                            });
                        } else {
                            // If there are other groups, set activeGroup to the first available group
                            const newActiveGroup = prevGroups.find((g) => g.id !== groupId);
                            if (newActiveGroup) {
                                // remove group operator from first found group
                                delete newActiveGroup.operator;
                                setActiveGroup(newActiveGroup.id); // Set active group to another existing group
                            }
                            // If there are other groups, do not add this group to the accumulator
                            return acc; // Skip adding this group since it has no conditions left
                        }
                    } else {
                        // Add the updated group with remaining conditions
                        acc.push({ ...group, conditions: updatedConditions });
                    }
                } else {
                    // If this group is not the one we're modifying, just add it unchanged
                    acc.push(group);
                }
                return acc;
            }, [] as ConditionGroup[]); // Initialize accumulator as an empty array
        });
    };

    // Function to update a specific condition's keyword
    const updateConditionKeyword = (groupId: string, conditionId: string, newKeyword: string) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.id === groupId
                    ? {
                          ...group,
                          conditions: group.conditions.map((condition) =>
                              condition.id === conditionId ? { ...condition, keyword: newKeyword } : condition
                          ),
                      }
                    : group
            )
        );
    };

    // ####################################################################
    // ################################## NEAR FUNCTIONS
    // ####################################################################

    // Function to handle the addition of a NEAR condition
    const handleNearCondition = (groupId: string, conditionId: string) => {
        const nearOperator: LogicalOperator = { icon: <CompareArrows />, text: "NEAR" };
        updateConditionOperator(groupId, conditionId, nearOperator);
        setGroups((prevGroups) =>
            prevGroups.map((group) => {
                if (group.id === groupId) {
                    // Check for the presence of other operators in the group
                    const nonNearOperators = group.conditions.filter((cond) => cond.operator && cond.operator.text !== "NEAR");

                    // Determine the group's main operator (OR by default if only NEAR is present)
                    const chosenOperatorText = nonNearOperators.length > 0 ? nonNearOperators[0].operator?.text : "OR";

                    // Look up the correct operator from logicalOperators based on chosenOperatorText
                    const chosenOperator =
                        logicalOperators.find((op) => op.text === chosenOperatorText) || logicalOperators.find((op) => op.text === "OR");

                    return {
                        ...group,
                        conditions: group.conditions.map((condition) => {
                            if (condition.id === conditionId) {
                                return {
                                    ...condition,
                                    nearTerm: "",
                                    proximity: 5,
                                    secondOperator: chosenOperator,
                                };
                            }
                            return condition;
                        }),
                    };
                }
                return group;
            })
        );
    };

    // Function to handle the change of the nearTerm value
    const handleNearTermChange = (groupId: string, conditionId: string, newNearTerm: string) => {
        setGroups((prev) => {
            return prev.map((group) => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        conditions: group.conditions.map((cond) => {
                            if (cond.id === conditionId) {
                                return {
                                    ...cond,
                                    nearTerm: newNearTerm,
                                };
                            }
                            return cond;
                        }),
                    };
                }
                return group;
            });
        });
    };

    // Function to fill nearTerm for a condition with a NEAR operator
    const fillNearTerm = (groupId: string, conditionId: string, keyword: string) => {
        setGroups((prevGroups) =>
            prevGroups.map((group) => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        conditions: group.conditions.map((condition) => {
                            // Locate the target condition by ID and check if operator is NEAR and nearTerm is empty
                            if (condition.id === conditionId && condition.operator?.text === "NEAR" && !condition.nearTerm) {
                                return {
                                    ...condition,
                                    nearTerm: keyword,
                                };
                            }
                            return condition;
                        }),
                    };
                }
                return group; // Return the group as is if it does not match
            })
        );
    };

    const resetNearTerm = (groupId: string, conditionId: string) => {
        setGroups((prev) => {
            return prev.map((group) => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        conditions: group.conditions.map((cond) => {
                            if (cond.id === conditionId) {
                                return {
                                    ...cond,
                                    nearTerm: "", // Reset nearTerm to an empty string
                                };
                            }
                            return cond;
                        }),
                    };
                }
                return group;
            });
        });
    };

    // Function to handle the change of the proximity value
    const handleProximityChange = (groupId: string, conditionId: string, newProximity: number | undefined) => {
        setGroups((prev) => {
            return prev.map((group) => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        conditions: group.conditions.map((cond) => {
                            if (cond.id === conditionId) {
                                return {
                                    ...cond,
                                    proximity: newProximity,
                                };
                            }
                            return cond;
                        }),
                    };
                }
                return group;
            });
        });
    };

    // ####################################################################
    // ################################## GENERATE QUERY
    // ####################################################################

    // Function to generate the query string
    const generateQueryString = (groups: ConditionGroup[]): string => {
        const generateGroupString = (group: ConditionGroup, groupIndex: number): string => {
            const conditionStrs = group.conditions.map((condition: Condition, index: number): string => {
                const isLastCondition = index === group.conditions.length - 1;
                if (
                    condition.operator?.text === "NEAR" &&
                    condition.keyword &&
                    condition.nearTerm &&
                    condition.proximity !== undefined &&
                    condition.secondOperator
                ) {
                    // Place keyword first, then the operator for NEAR conditions, but avoid operator if it's the last condition
                    const operatorStr = !isLastCondition ? ` ${condition.secondOperator.text}` : "";
                    return `"${condition.keyword} ${condition.nearTerm}"~${condition.proximity}${operatorStr}`;
                } else if (condition.keyword?.includes("&") || condition.keyword?.includes("-") || condition.keyword?.includes(" ")) {
                    // Place keyword first, then the operator for other conditions, unless it's the last condition
                    const operatorStr = !isLastCondition && condition.operator ? ` ${condition.operator.text}` : "";
                    return `"${condition.keyword}"${operatorStr}`;
                } else if (condition.keyword) {
                    // Place keyword first, then the operator for other conditions, unless it's the last condition
                    const operatorStr = !isLastCondition && condition.operator ? ` ${condition.operator.text}` : "";
                    return `${condition.keyword}${operatorStr}`;
                } else {
                    return ""; // Skip if there's no keyword
                }
            });

            const groupString = `(${conditionStrs.filter(Boolean).join(" ")})`;

            return groupIndex > 0 && group.operator ? `${group.operator.text} ${groupString}` : groupString;
        };

        return groups.map(generateGroupString).join(" ");
    };

    // Function to handle button click
    const handleGenerateQuery = () => {
        // Store the conditions with only the text of the operator (no icon)
        const groupsWithOperatorText = groups.map((group) => ({
            ...group,
            conditions: group.conditions.map((condition) => ({
                ...condition,
                operator: condition.operator ? { text: condition.operator.text } : undefined,
                secondOperator: condition.secondOperator ? { text: condition.secondOperator.text } : undefined,
            })),
        }));

        // Generate the query string
        const query = generateQueryString(groups);
        const gen = query === "()" ? "" : query;

        setAdvancedQueryString(gen);

        if (defaultGroupsKey && !searchWizard) {
            sessionStorage.setItem(defaultGroupsKey, JSON.stringify(groupsWithOperatorText));
        }

        // Store the query string in session storage
        const defaultQueryKey = defaultGroupsKey?.replace("g", "");
        if (defaultQueryKey && !searchWizard) {
            sessionStorage.setItem(defaultQueryKey, JSON.stringify(gen));
        }
    };

    const containsOperators = (query: string) => {
        // Regular expression to check for the presence of '~', 'AND', 'OR', or 'NOT'
        const regex = /~|AND|OR|NOT/i;
        // Test the query against the regular expression
        return regex.test(query);
    };

    const areThereAnyWordsSelected = () => {
        return groups.some((group) => group.conditions.some((condition) => condition.keyword !== undefined));
    };

    const areThereNEARConditionsNotValid = () => {
        return groups.some((group) => group.conditions.some((condition) => condition.operator?.text === "NEAR" && condition.nearTerm === ""));
    };

    // ####################################################################
    // ################################## GENERATE GROUPS
    // ####################################################################
    // Function to check if a query uses only a single specified operator (e.g., "AND", "OR", "NOT", "~")
    const detectSingleOperatorUsage = (query: string): string | false => {
        // List of possible operators, including ~ for proximity search
        const operators = ["AND", "OR", "NOT", "~"];

        // Update regex to match:
        // - "AND", "OR", "NOT" as whole words
        // - "~" followed by a number (e.g., "~2", "~5")
        const regex = new RegExp(`\\b(${operators.join("|")})\\b|~\\d+`, "g");

        // Match all operators in the query
        const foundOperators = query.match(regex);

        // If no operator is found, return "OR"
        if (!foundOperators || foundOperators.length === 0) {
            return "OR";
        }

        // If exactly one operator is found, return it
        if (foundOperators.length === 1) {
            const operator = foundOperators[0];

            // If the operator is a '~' (proximity operator), return "NEAR"
            if (operator.includes("~")) {
                return "NEAR";
            }

            // Otherwise, return the detected operator in uppercase
            return operator.toUpperCase();
        }

        // If multiple operators are found, check for consistency
        const distinctOperators = [...new Set(foundOperators)];

        // If there is exactly one distinct operator (e.g., multiple "AND" but no "OR"), return it
        if (distinctOperators.length === 1) {
            return distinctOperators[0].toUpperCase();
        }

        // If different types of operators are used (e.g., "AND" and "OR"), return false
        return false;
    };

    const createKeywordwithConditions = (query: string, operator: string): Condition[] => {
        // If the operator is "NEAR", we need to handle it differently
        if (operator === "NEAR") {
            // Match the near-term structure like ("opbreken verhardingen"~5)
            const nearRegex = /\("([^"]+)\s([^"]+)"~(\d+)\)/;

            // Extract matches from the query
            const match = query.match(nearRegex);

            if (match) {
                const keyword = match[1].trim(); // First part of the keyword
                const nearTerm = match[2].trim(); // Second part of the near term
                const proximity = parseInt(match[3], 10); // Proximity value

                // Return an array with a single "NEAR" condition
                return [
                    {
                        id: generateUniqueId(),
                        keyword: keyword,
                        nearTerm: nearTerm,
                        proximity: proximity,
                        operator: { text: "NEAR", icon: getOperatorIcon("NEAR") },
                        secondOperator: { text: "OR", icon: getOperatorIcon("OR") },
                    },
                ];
            }
        } else {
            // Handle "OR" operator (or other operators like "AND", "NOT")
            return query
                .replace(/["']/g, "") // Remove quotes
                .replace(/[()]/g, "") // Remove parentheses
                .split(` ${operator} `) // Split by the operator (like " OR ")
                .map((keyword) => ({
                    id: generateUniqueId(), // Generate a unique ID for each object
                    keyword: keyword.trim(), // The keyword, trimmed of any extra spaces
                    nearTerm: "", // For OR, there's no near term
                    proximity: 5, // Default proximity value
                    operator: { text: operator as "AND" | "OR" | "NOT" | "NEAR", icon: getOperatorIcon(operator) },
                }));
        }

        // Default return if no valid operator or query format
        return [];
    };

    // Function to handle proximity searches and return the condition objects
    const handleProximitySearches = (query: string): Condition[] => {
        const proximityRegex = /"([^"]+)"~(\d+)/g;
        let proximityMatches: RegExpExecArray | null;
        const proximityConditions: Condition[] = [];

        // Match all proximity searches in the query (e.g., "places padelbaan"~5)
        while ((proximityMatches = proximityRegex.exec(query)) !== null) {
            // Split the matched query into keyword and nearTerm
            const fullMatch = proximityMatches[1].trim(); // The full phrase in quotes
            const proximity = parseInt(proximityMatches[2], 10); // Extract proximity value

            // Assuming that the keyword and nearTerm are separated by spaces in the full match
            const splitTerms = fullMatch.split(/\s+/); // Split by whitespace to get the keyword and nearTerm
            const keyword = splitTerms[0]; // First term is the keyword
            const nearTerm = splitTerms.slice(1).join(" "); // Join the rest as the nearTerm

            // Push the condition to the array
            proximityConditions.push({
                id: generateUniqueId(),
                keyword, // Corrected to the first part of the split
                nearTerm, // Corrected to the second part of the split
                proximity,
                operator: { text: "NEAR", icon: getOperatorIcon("NEAR") },
                secondOperator: { text: "OR", icon: getOperatorIcon("OR") },
            });
        }

        return proximityConditions;
    };

    const cleanQuery = (query: string): string => {
        // Step 1: Remove proximity searches ("term"~n)
        let cleanedQuery = query.replace(/"[^"]+"~\d+/g, "").trim();

        // Step 2: Normalize spaces around logical operators (OR, AND, NOT)
        cleanedQuery = cleanedQuery
            .replace(/\s*(OR|AND|NOT)\s*/g, " $1 ") // Ensure spacing around logical operators
            .replace(/\s{2,}/g, " "); // Reduce multiple spaces to a single space

        // Step 3: Remove parentheses
        cleanedQuery = cleanedQuery.replace(/[()]/g, "").trim();

        // Step 4: Clean up extra spaces and standalone operators
        const normalizedParts = cleanedQuery
            .split(/\s+/) // Split by whitespace
            .filter((part) => part !== "" && !["OR", "AND", "NOT"].includes(part.trim().toUpperCase())); // Remove empty parts and standalone operators

        // Rejoin valid parts with proper spacing
        return normalizedParts.join(" ");
    };

    const processQuery = (query: string, defaultOperator: LogicalOperator): Condition[] => {
        // Determine the second operator based on the default operator; for "NEAR", the second operator is "OR"
        const secondOperator = defaultOperator.text === "NEAR" ? "OR" : defaultOperator.text;

        // Initialize an array to store the conditions that will be generated
        const conditions: Condition[] = [];

        // Define the regex to capture proximity searches (terms inside quotes with a proximity distance)
        const proximityRegex = /"([^"]+)"~(\d+)\s*(OR|AND|NOT)?/g; // Modified regex to capture secondOperator (e.g., OR, AND, NOT)
        let match: RegExpExecArray | null; // Variable to store each regex match
        let queryWithoutProximity = query; // Keep track of the query after removing proximity searches

        // Step 1: Extract proximity searches and create conditions for each match
        while ((match = proximityRegex.exec(query)) !== null) {
            // Extract the full phrase (keyword + nearTerm) and the proximity value
            const fullMatch = match[1]; // The phrase inside the quotes
            const proximity = parseInt(match[2], 10); // The proximity value

            // Split the full phrase into keyword and nearTerm (remaining part of the phrase)
            const [keyword, ...nearTerms] = fullMatch.split(/\s+/); // Split into keyword and nearTerm
            const nearTerm = nearTerms.join(" "); // Join remaining terms as nearTerm

            // Extract the second logical operator (OR, AND, NOT) if provided after the proximity value
            const secondOperatorText = (match[3]?.trim() as "OR" | "AND" | "NOT" | "NEAR") || null; // The operator after ~n (e.g., OR, AND, NOT)

            // Push the condition for this proximity search into the conditions array
            conditions.push({
                id: generateUniqueId(),
                keyword,
                nearTerm,
                proximity,
                operator: { text: "NEAR", icon: getOperatorIcon("NEAR") },
                secondOperator: secondOperatorText
                    ? { text: secondOperatorText, icon: getOperatorIcon(secondOperatorText) }
                    : { text: "OR", icon: getOperatorIcon("OR") }, // Add secondOperator if present
            });

            // Remove the matched proximity search from the query
            queryWithoutProximity = queryWithoutProximity.replace(match[0], "").trim();
        }

        // Step 2: Clean up the remaining query using cleanQuery
        const cleanRemainingQuery = cleanQuery(queryWithoutProximity);

        // allow multiple words between "" as 1 word
        const wordsArray =
            cleanRemainingQuery
                .match(/"[^"]+"|\S+/g) // Match quoted phrases or standalone words
                ?.map((part) => part.replace(/"/g, "").trim()) // Remove quotes and trim whitespace
                .filter(Boolean) || []; // Ensure no null or empty strings in the array

        // Step 3: Create conditions for remaining parts
        wordsArray.forEach((part) => {
            if (part && !["OR", "AND", "NOT"].includes(part.toUpperCase())) {
                conditions.push({
                    id: generateUniqueId(),
                    keyword: part,
                    operator: defaultOperator,
                    secondOperator: { text: secondOperator, icon: getOperatorIcon(secondOperator) },
                });
            }
        });

        return conditions;
    };

    const splitAndProcessQuery = (query: string): ConditionGroup[] | false => {
        // Step 1: Try to match queries with TWO PARTS separated by AND, OR, or NOT
        const regex = /\(([^()]+)\)\s*(AND|OR|NOT)\s*\(([^()]+)\)/i;
        const match = query.match(regex);

        if (match) {
            // Split into two parts and detect the operator (AND/OR/NOT)
            const part1 = match[1].trim();
            const operatorText = match[2].trim();
            const operator = logicalOperators.find((op) => op.text === operatorText) as LogicalOperator;
            const part2 = match[3].trim();

            // Handle proximity searches for both parts
            const part1ProximityConditions = handleProximitySearches(part1);
            const part2ProximityConditions = handleProximitySearches(part2);

            // Generate conditions for each part
            const part1Operator = detectSingleOperatorUsage(part1) || operatorText;
            const part2Operator = detectSingleOperatorUsage(part2) || operatorText;

            const part1Conditions = createKeywordwithConditions(part1, part1Operator).concat(part1ProximityConditions);
            const part2Conditions = createKeywordwithConditions(part2, part2Operator).concat(part2ProximityConditions);

            const part1ID = generateUniqueId();
            const part2ID = generateUniqueId();
            setActiveGroup(part2ID);
            return [
                {
                    id: part1ID,
                    operator: operator as LogicalOperator,
                    conditions: part1Conditions,
                },
                {
                    id: part2ID,
                    operator: operator as LogicalOperator,
                    conditions: part2Conditions,
                },
            ];
        }

        // Step 2: If no logical operators found, handle as a single group
        const operatorText = detectSingleOperatorUsage(query) || "OR"; // Default to "OR"

        const operatorToUseGroup = operatorText === "NEAR" ? "OR" : operatorText;
        const operatorToUseForGroup = logicalOperators.find((op) => op.text === operatorToUseGroup) as LogicalOperator;

        const operator = logicalOperators.find((op) => op.text === operatorText) as LogicalOperator;

        return [
            {
                id: generateUniqueId(),
                operator: operatorToUseForGroup as LogicalOperator,
                conditions: processQuery(query, operator),
            },
        ];
    };

    const hasMultipleWordsInParenthesesExcludingProximity = (query: string): boolean => {
        // Step 1: Define regex to capture terms inside parentheses
        const parenthesesRegex = /\(([^)]+)\)/g; // Match content inside parentheses
        const proximityRegex = /"[^"]+"~\d+/g; // Match proximity searches, e.g., "term term"~5

        // Step 2: Find all matches for terms inside parentheses
        const matches = query.match(parenthesesRegex);

        if (!matches) {
            return false; // No parentheses found, return false
        }

        // Step 3: Check if any term inside parentheses has more than one word and isn't a proximity search
        return matches.some((match) => {
            // Remove the parentheses and trim spaces
            const termInsideParentheses = match.replace(/[()]/g, "").trim();

            // Step 4: If the term contains a proximity search, ignore it
            if (proximityRegex.test(termInsideParentheses)) {
                return false; // Skip if proximity search is found
            }

            // Step 5: Check if the term has more than one word
            const wordCount = termInsideParentheses.split(/\s+/).length;
            return wordCount > 1; // Return true if more than one word
        });
    };

    useEffect(() => {
        if (groups.length === 1) {
            // Set active group based on the 'id' or 'operator'
            setActiveGroup(groups[0].id);
        }
    }, [groups]);

    return (
        <ConditionsContext.Provider
            value={{
                processQuery,
                groups,
                activeGroup,
                highlightedConditionId,
                advancedQueryString,
                searchWizard,
                detectSingleOperatorUsage,
                // containsOnlyOperator,
                nextIsNearTerm,
                setSearchWizard,
                setHighlightedConditionId,
                setGroups,
                addGroup,
                updateGroupOperator,
                addCondition,
                updateConditionOperator,
                handleNearCondition,
                removeCondition,
                updateConditionKeyword,
                removeGroup,
                handleNearTermChange,
                handleProximityChange,
                setActiveGroup,
                resetNearTerm,
                handleGenerateQuery,
                resetAdvancedQuery,
                setAdvancedQueryString,
                containsOperators,
                getOperatorIcon,
                areThereAnyWordsSelected,
                areThereNEARConditionsNotValid,
                createKeywordwithConditions,
                splitAndProcessQuery,
                hasMultipleWordsInParenthesesExcludingProximity,
            }}
        >
            {children}
        </ConditionsContext.Provider>
    );
};

// Create a custom hook for easier access to the context
export const useQueryBuilder = () => {
    const context = useContext(ConditionsContext);
    if (!context) {
        throw new Error("useConditions must be used within a ConditionsProvider");
    }
    return context;
};
