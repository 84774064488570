import React from "react";
import { toast } from "react-toastify";
import { updateTemplate, updateTemplateVariables } from "../../../../__generated__/updateTemplate";
import { useMutation } from "@apollo/client";
import { UPDATE_TEMPLATE } from "../../../../graphql/mutationDefTasks";
import EditableText from "../library/EditableText";

interface Props {
    title: string;
    id: string;
    refetch: () => void;
}

const TemplateTitle: React.FC<Props> = ({ title, id, refetch }) => {
    // Explain mutation
    const [mutate, { loading, error }] = useMutation<updateTemplate, updateTemplateVariables>(UPDATE_TEMPLATE);

    // Explain mutation handler
    const updateName = async (id: string, name: string) => {
        if (error) {
            toast.error("Fout tijdens opslaan (error)");
        }
        try {
            await mutate({
                variables: {
                    id: id,
                    name: name,
                },
                onCompleted: (data) => {
                    refetch();
                },
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <EditableText tasktext={title} id={id} fontWeight={500} variant="h5" fontSize={"18px"} updateText={updateName} loading={loading} />
        </div>
    );
};

export default TemplateTitle;
