import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCards } from "swiper/modules";
import "swiper/css"; // Import Swiper styles
import { Button, Card, CardContent, CardMedia, Typography } from "@mui/material";
import CreateTemplate from "./CreateTemplate";

interface Props {
    refetch: () => void;
    showBtn?: boolean;
}

const items = [
    {
        image: "https://storage.googleapis.com/tenderguide-public/taak%20toevoegen.gif",
        title: "Stap 1 - Voeg taken toe aan Mijn taken",
        subtext:
            "Kies taken uit de voorbeelden of maak eigen taken aan. In het tabblad Mijn taken staat een overzicht van de door jou aangemaakte taken. In het tabblad voorbeelden zie je vaak voorkomende taken bij aanbestedingen.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/template_kiezen_1.gif",
        title: "Stap 2 - Kies een sjabloon",
        subtext:
            "Kies het sjabloon waar je taken aan toe wilt voegen. Als je nog geen sjabloon hebt, voeg dan een eerste sjabloon toe met de button Maak een sjabloon.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/task_to_template.gif",
        title: "Stap 3 - Voeg taken toe aan sjabloon",
        subtext:
            "Nadat je een sjabloon hebt gekozen, kun je taken toevoegen aan het sjabloon. Door op een taak aan de linkerzijde te klikken, wordt deze toegevoegd aan het sjabloon.",
    },
    {
        image: "https://storage.googleapis.com/tenderguide-public/template_in_project.gif",
        title: "Stap 4 - Sjabloon toepassen in een project",
        subtext:
            "Zodra je een sjabloon hebt, kun je deze toepassen in een project. Zo kun je vaak voorkomende taken sneller toewijzen aan andere gebruikers in het project.",
    },
];

const TemplateExample: React.FC<Props> = ({ refetch, showBtn = true }) => {
    // const items = ["create tasks", "choose template", "add tasks to template", "use template in project"];
    const [activeIndex, setActiveIndex] = useState(0);
    const [createFirst, setCreateFirst] = useState<boolean>(false);

    return (
        <div>
            <div style={{ paddingRight: "24px" }}>
                <div style={{ height: "34px", display: "flex", alignItems: "center" }}>
                    <Typography fontWeight={500} variant="h5">
                        Sjablonen
                    </Typography>
                </div>

                <Swiper
                    style={{ margin: "32px" }}
                    modules={[Pagination, Navigation, EffectCards]}
                    effect="cards"
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    cardsEffect={{ rotate: false, slideShadows: false, perSlideRotate: 0 }}
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    slidesPerView={1}
                    grabCursor
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                >
                    {items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Card sx={{ margin: "32px", borderRadius: "16px", height: "50vh" }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h3" component="div" sx={{ padding: "0px 16px" }}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "text.secondary", padding: "0px 16px" }}>
                                        {item.subtext}
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        height="340"
                                        image={item.image}
                                        alt={item.title}
                                        sx={{ marginTop: "32px", borderRadius: "16px", objectFit: "contain" }}
                                    />
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    ))}

                    {/* Navigation buttons with inline styles */}
                    <div
                        className="swiper-button-next"
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: "24px",
                            color: "#fff",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "10px",
                            borderRadius: "50%",
                            cursor: "pointer",
                            visibility: activeIndex === items.length - 1 ? "hidden" : "visible",
                        }}
                    />

                    <div
                        className="swiper-button-prev"
                        style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: "24px",
                            color: "#fff",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "10px",
                            borderRadius: "50%",
                            cursor: "pointer",
                            visibility: activeIndex === 0 ? "hidden" : "visible",
                        }}
                    />
                </Swiper>

                {showBtn && (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "32px" }}>
                        {createFirst ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CreateTemplate
                                    refetch={refetch}
                                    onCancel={() => {
                                        setCreateFirst(false);
                                    }}
                                />
                            </div>
                        ) : (
                            <Button variant="contained" onClick={() => setCreateFirst(true)}>
                                Maak een sjabloon
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TemplateExample;
