import { gql } from "@apollo/client";

//?  Query for tasks with filters
export const GET_TASKS_WITH_FILTERS = gql`
    query getTasksWithFilters(
        $user_ids: [ID]
        $project_ids: [ID]
        $task_priorities: [String]
        $task_completed: String
        $deadline: DateRange
        $search: String
        $page: Int
        $perPage: Int
    ) {
        filterUserTasks(
            user_ids: $user_ids
            project_ids: $project_ids
            task_priorities: $task_priorities
            task_completed: $task_completed
            deadline: $deadline
            search: $search
            page: $page
            perPage: $perPage
        ) {
            filters {
                project_ids {
                    id
                    name
                }
                project_states {
                    id
                    name
                }
                task_completed
                task_priorities
                user_ids {
                    id
                    name
                }
            }
            data {
                id
                task_description
                task_completed
                task_deadline
                task_priority
                created_at
                task_completed_at
                deleted_at
                project {
                    id
                    title
                    state {
                        id
                        name
                        order_number
                    }
                }
                users {
                    id
                    employee {
                        id
                        name
                        familyname
                    }
                }
            }

            pagination {
                currentPage
                from
                lastPage
                perPage
                to
                total
            }
        }
    }
`;

export const GET_TASK_LIBRARY = gql`
    query taskLibrary {
        library {
            id
            task_description
            created_by {
                id
                employee {
                    name
                }
            }
            created_at
        }
    }
`;

export const GET_TASK_TEMPLATES = gql`
    query taskTemplates {
        templates {
            id
            template_name
            tasks {
                id
                taskInfo {
                    id
                    task_description
                }
                task_deadline
                task_priority
            }
            created_by {
                id
                employee {
                    id
                    name
                }
            }
            created_at
            updated_at
            deleted_at
        }
    }
`;
